import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import { useCookies } from "react-cookie";
import { COOKIE_CONSENT_NAME } from ".";
export var SEGMENT_DESTINATION_NAMES;
(function(SEGMENT_DESTINATION_NAMES) {
    SEGMENT_DESTINATION_NAMES["googleAnalytics"] = "Google Analytics 4 Web";
    SEGMENT_DESTINATION_NAMES["googleAds"] = "Google AdsGoogle Ads (Gtag)";
})(SEGMENT_DESTINATION_NAMES || (SEGMENT_DESTINATION_NAMES = {}));
// Hook to return if user consented to cookies
/**
 * @return `undefined` if not yet answered, otherwise a boolean
 * @param {string=}  destination - A string to specify the destination (see segment.io)
 **/ export var useCookieConsent = function(destination) {
    var ref = _sliced_to_array(useCookies([
        COOKIE_CONSENT_NAME
    ]), 1), cookies = ref[0];
    // Check if user opted-in for tracking
    var consentCookie = cookies[COOKIE_CONSENT_NAME];
    if (!(consentCookie === null || consentCookie === void 0 ? void 0 : consentCookie.destinations)) return;
    var consent = destination ? consentCookie.destinations[destination] : Object.values(consentCookie.destinations)[0];
    return consent;
};
