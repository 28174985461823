import {
  useGetCompletionPaymentsByPersonIdQuery,
  useGetLiabilityCompletionsQuery,
  useGetLiabilityTotalCompletionsQuery,
  useGetPledgesByPersonIdQuery,
  useGetPledgesByApprovedMotivationQuery,
  useGetPledgeRanksByPersonIdQuery,
  useGetPledgedMemberPublicsLatestRankQuery,
  useGetRecurringReportedDonationsByPersonIdQuery,
  useGetLiabilitiesByPersonIdQuery,
  useGetPledgeByPersonOrTemporaryPersonQuery,
  useGetTotalPledgeSignupsQuery,
  GetPledgeByPersonOrTemporaryPersonQuery,
  useGetPledgeClubMembersByPersonIdQuery,
} from '../graphql'
import { flattenGQLArrayResult, makeQueryWithReducer } from '../utils'
import { useWithPerson } from '../utils/useWithPerson'
import { TPledgeMetadata } from './types'

export * from './types'

export const useGetPledgesByPersonIdQueryWithReducer = makeQueryWithReducer(
  useGetPledgesByPersonIdQuery,
  (result) => flattenGQLArrayResult(result.data?.Pledges),
  useWithPerson,
)

export const useGetPledgesByApprovedMotivationQueryWithReducer =
  makeQueryWithReducer(useGetPledgesByApprovedMotivationQuery, (result) =>
    flattenGQLArrayResult(result.data?.Pledges),
  )

export const useGetPledgeByPersonOrTemporaryPersonQueryWithReducer =
  makeQueryWithReducer(
    useGetPledgeByPersonOrTemporaryPersonQuery,
    (result) =>
      result.data
        ?.Pledge as GetPledgeByPersonOrTemporaryPersonQuery['Pledge'] & {
        metadata: TPledgeMetadata
      },
  )

/**
 * Allows to retrieve the latest Pledge Rank by Pledge Type (defaults to GIVING_WHAT_WE_CAN)
 */
export const useGetPledgedMemberPublicsLatestRankQueryWithReducer =
  makeQueryWithReducer(useGetPledgedMemberPublicsLatestRankQuery, (result) =>
    result.data?.PledgedMembers?.length
      ? result.data.PledgedMembers[0].rankByPledgeType
      : undefined,
  )

/**
 * Allows to retrieve the Pledge rank of the logged in person
 */
export const useGetPledgeRanksByPersonIdQueryWithReducer = makeQueryWithReducer(
  useGetPledgeRanksByPersonIdQuery,
  (result) => flattenGQLArrayResult(result.data?.PledgeRanks),
  useWithPerson,
)

/**
 * Retrieve all of the current user's reported recurring donations
 */
export const useGetRecurringReportedDonationsByPersonIdQueryWithReducer =
  makeQueryWithReducer(
    useGetRecurringReportedDonationsByPersonIdQuery,
    (result) => flattenGQLArrayResult(result.data?.RecurringReportedDonations),
    useWithPerson,
  )

export const useGetCompletionPaymentsByPersonIdQueryWithReducer =
  makeQueryWithReducer(
    useGetCompletionPaymentsByPersonIdQuery,
    (result) => flattenGQLArrayResult(result.data?.CompletionPayments),
    useWithPerson,
  )

export const useGetLiabilityTotalCompletionsQueryWithReducer =
  makeQueryWithReducer(useGetLiabilityTotalCompletionsQuery, (result) => {
    const r = flattenGQLArrayResult(result.data?.LiabilityTotalCompletions)
    r?.sort(
      (a, b) =>
        Number(a.currencySortOrder || 0) - Number(b.currencySortOrder || 0),
    )
    return r
  })

export const useGetLiabilityCompletionsQueryWithReducer = makeQueryWithReducer(
  useGetLiabilityCompletionsQuery,
  (result) => flattenGQLArrayResult(result.data?.LiabilityCompletions),
)

export const useGetLiabilitiesQueryWithReducer = makeQueryWithReducer(
  useGetLiabilitiesByPersonIdQuery,
  (result) => flattenGQLArrayResult(result.data?.Liabilities),
  useWithPerson,
)

export const useGetTotalPledgeSignupsQueryWithReducer = makeQueryWithReducer(
  useGetTotalPledgeSignupsQuery,
  (result) => result.data?.TotalPledgeSignups,
)

export const useGetPledgeClubMembersByPersonIdQueryWithReducer =
  makeQueryWithReducer(
    useGetPledgeClubMembersByPersonIdQuery,
    (result) => flattenGQLArrayResult(result.data?.PledgeClubMembers),
    useWithPerson,
  )
