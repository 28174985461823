import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigFloat: string;
  BigInt: string;
  Cursor: string;
  Date: string;
  Datetime: string;
  JSON: unknown;
  UUID: string;
};

export enum AccountingSystem {
  Netsuite = 'NETSUITE',
  Xero = 'XERO',
  XeroCash = 'XERO_CASH'
}

export type ActiveRecipientProgram = {
  __typename?: 'ActiveRecipientProgram';
  organizationSlug: Scalars['String'];
  recipientSlug: Scalars['String'];
};

/** A connection to a list of `ActiveRecipientProgram` values. */
export type ActiveRecipientProgramsConnection = {
  __typename?: 'ActiveRecipientProgramsConnection';
  /** A list of edges which contains the `ActiveRecipientProgram` and cursor to aid in pagination. */
  edges: Array<ActiveRecipientProgramsEdge>;
  /** A list of `ActiveRecipientProgram` objects. */
  nodes: Array<ActiveRecipientProgram>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ActiveRecipientProgram` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ActiveRecipientProgram` edge in the connection. */
export type ActiveRecipientProgramsEdge = {
  __typename?: 'ActiveRecipientProgramsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ActiveRecipientProgram` at the end of the edge. */
  node: ActiveRecipientProgram;
};

/** All input for the `addReminder` mutation. */
export type AddReminderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  newsletterSignup?: InputMaybe<Scalars['Boolean']>;
  reminderDate?: InputMaybe<Scalars['Date']>;
  reminderSourcePath?: InputMaybe<Scalars['String']>;
};

/** The output of our `addReminder` mutation. */
export type AddReminderPayload = {
  __typename?: 'AddReminderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  reminder?: Maybe<Reminder>;
};

export type Address = Node & {
  __typename?: 'Address';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  addressType?: Maybe<AddressType>;
  city?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `ContactPreference`. */
  contactPreferencesByCurrentAddressId: ContactPreferencesConnection;
  /** Reads and enables pagination through a set of `ContactPreference`. */
  contactPreferencesByCurrentAddressIdList: Array<ContactPreference>;
  /** Reads and enables pagination through a set of `ContactPreference`. */
  contactPreferencesByPersonIdAndCurrentAddressId: ContactPreferencesConnection;
  /** Reads and enables pagination through a set of `ContactPreference`. */
  contactPreferencesByPersonIdAndCurrentAddressIdList: Array<ContactPreference>;
  coordinates?: Maybe<Scalars['String']>;
  /** Reads a single `Country` that is related to this `Address`. */
  countryByCountryCode?: Maybe<Country>;
  countryCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  firstName?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `GiftAidClaim`. */
  giftAidClaimsByAddressIdAndPersonId: GiftAidClaimsConnection;
  /** Reads and enables pagination through a set of `GiftAidClaim`. */
  giftAidClaimsByAddressIdAndPersonIdList: Array<GiftAidClaim>;
  googlePlaceId?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  id: Scalars['BigInt'];
  lastName?: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  organizationName?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Payment`. */
  paymentsByBillingAddressId: PaymentsConnection;
  /** Reads and enables pagination through a set of `Payment`. */
  paymentsByBillingAddressIdList: Array<Payment>;
  /** Reads a single `Person` that is related to this `Address`. */
  personByPersonId?: Maybe<Person>;
  personId: Scalars['BigInt'];
  postalCode?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `RecurringPayment`. */
  recurringPaymentsByBillingAddressId: RecurringPaymentsConnection;
  /** Reads and enables pagination through a set of `RecurringPayment`. */
  recurringPaymentsByBillingAddressIdList: Array<RecurringPayment>;
  region?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Datetime'];
};


export type AddressContactPreferencesByCurrentAddressIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type AddressContactPreferencesByCurrentAddressIdListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type AddressContactPreferencesByPersonIdAndCurrentAddressIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type AddressContactPreferencesByPersonIdAndCurrentAddressIdListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type AddressGiftAidClaimsByAddressIdAndPersonIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type AddressGiftAidClaimsByAddressIdAndPersonIdListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type AddressPaymentsByBillingAddressIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type AddressPaymentsByBillingAddressIdListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type AddressRecurringPaymentsByBillingAddressIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type AddressRecurringPaymentsByBillingAddressIdListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export enum AddressType {
  Billing = 'BILLING'
}

/** A connection to a list of `Address` values. */
export type AddressesConnection = {
  __typename?: 'AddressesConnection';
  /** A list of edges which contains the `Address` and cursor to aid in pagination. */
  edges: Array<AddressesEdge>;
  /** A list of `Address` objects. */
  nodes: Array<Address>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Address` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Address` edge in the connection. */
export type AddressesEdge = {
  __typename?: 'AddressesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Address` at the end of the edge. */
  node: Address;
};

/** All input for the `cancelRecurringPayment` mutation. */
export type CancelRecurringPaymentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  recurringPaymentId: Scalars['BigInt'];
};

/** The output of our `cancelRecurringPayment` mutation. */
export type CancelRecurringPaymentPayload = {
  __typename?: 'CancelRecurringPaymentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `cancelRecurringReportedDonation` mutation. */
export type CancelRecurringReportedDonationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  recurringReportedDonationId?: InputMaybe<Scalars['BigInt']>;
};

/** The output of our `cancelRecurringReportedDonation` mutation. */
export type CancelRecurringReportedDonationPayload = {
  __typename?: 'CancelRecurringReportedDonationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Currency` that is related to this `RecurringReportedDonation`. */
  currencyByCurrencyCode?: Maybe<Currency>;
  /** Reads a single `ExternalOrganization` that is related to this `RecurringReportedDonation`. */
  externalOrganizationByOrganizationId?: Maybe<ExternalOrganization>;
  /** Reads a single `Person` that is related to this `RecurringReportedDonation`. */
  personByPersonId?: Maybe<Person>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  recurringReportedDonation?: Maybe<RecurringReportedDonation>;
  /** An edge for our `RecurringReportedDonation`. May be used by Relay 1. */
  recurringReportedDonationEdge?: Maybe<RecurringReportedDonationsEdge>;
};

export type CompletionPayment = {
  __typename?: 'CompletionPayment';
  allocation?: Maybe<Scalars['JSON']>;
  amount: Scalars['BigFloat'];
  amountNormalized?: Maybe<Scalars['BigFloat']>;
  currencyCode: Scalars['String'];
  donationDate: Scalars['Date'];
  /** Reads a single `ExternalOrganization` that is related to this `CompletionPayment`. */
  externalOrganizationByOrganizationId?: Maybe<ExternalOrganization>;
  /** Reads a single `FinancialPeriod` that is related to this `CompletionPayment`. */
  financialPeriodByFinancialPeriodId?: Maybe<FinancialPeriod>;
  financialPeriodId?: Maybe<Scalars['BigInt']>;
  /** Reads a single `Liability` that is related to this `CompletionPayment`. */
  liabilityByPledgeIdAndFinancialPeriodId?: Maybe<Liability>;
  objectId: Scalars['BigInt'];
  objectType: CompletionPaymentObjectType;
  organizationId?: Maybe<Scalars['BigInt']>;
  /** Reads a single `Payment` that is related to this `CompletionPayment`. */
  paymentByPaymentId?: Maybe<Payment>;
  paymentId?: Maybe<Scalars['BigInt']>;
  personId?: Maybe<Scalars['BigInt']>;
  pledgeId?: Maybe<Scalars['BigInt']>;
  reportedDonationId?: Maybe<Scalars['BigInt']>;
};

/**
 * A condition to be used against `CompletionPayment` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CompletionPaymentCondition = {
  /** Checks for equality with the object’s `allocation` field. */
  allocation?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `amount` field. */
  amount?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `amountNormalized` field. */
  amountNormalized?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `currencyCode` field. */
  currencyCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `donationDate` field. */
  donationDate?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `financialPeriodId` field. */
  financialPeriodId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `objectId` field. */
  objectId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `objectType` field. */
  objectType?: InputMaybe<CompletionPaymentObjectType>;
  /** Checks for equality with the object’s `organizationId` field. */
  organizationId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `paymentId` field. */
  paymentId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `personId` field. */
  personId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `pledgeId` field. */
  pledgeId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `reportedDonationId` field. */
  reportedDonationId?: InputMaybe<Scalars['BigInt']>;
};

export enum CompletionPaymentObjectType {
  Payment = 'PAYMENT',
  ReportedDonation = 'REPORTED_DONATION'
}

/** A connection to a list of `CompletionPayment` values. */
export type CompletionPaymentsConnection = {
  __typename?: 'CompletionPaymentsConnection';
  /** A list of edges which contains the `CompletionPayment` and cursor to aid in pagination. */
  edges: Array<CompletionPaymentsEdge>;
  /** A list of `CompletionPayment` objects. */
  nodes: Array<CompletionPayment>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompletionPayment` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `CompletionPayment` edge in the connection. */
export type CompletionPaymentsEdge = {
  __typename?: 'CompletionPaymentsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompletionPayment` at the end of the edge. */
  node: CompletionPayment;
};

/** Methods to use when ordering `CompletionPayment`. */
export enum CompletionPaymentsOrderBy {
  AllocationAsc = 'ALLOCATION_ASC',
  AllocationDesc = 'ALLOCATION_DESC',
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  AmountNormalizedAsc = 'AMOUNT_NORMALIZED_ASC',
  AmountNormalizedDesc = 'AMOUNT_NORMALIZED_DESC',
  CurrencyCodeAsc = 'CURRENCY_CODE_ASC',
  CurrencyCodeDesc = 'CURRENCY_CODE_DESC',
  DonationDateAsc = 'DONATION_DATE_ASC',
  DonationDateDesc = 'DONATION_DATE_DESC',
  FinancialPeriodIdAsc = 'FINANCIAL_PERIOD_ID_ASC',
  FinancialPeriodIdDesc = 'FINANCIAL_PERIOD_ID_DESC',
  Natural = 'NATURAL',
  ObjectIdAsc = 'OBJECT_ID_ASC',
  ObjectIdDesc = 'OBJECT_ID_DESC',
  ObjectTypeAsc = 'OBJECT_TYPE_ASC',
  ObjectTypeDesc = 'OBJECT_TYPE_DESC',
  OrganizationIdAsc = 'ORGANIZATION_ID_ASC',
  OrganizationIdDesc = 'ORGANIZATION_ID_DESC',
  PaymentIdAsc = 'PAYMENT_ID_ASC',
  PaymentIdDesc = 'PAYMENT_ID_DESC',
  PersonIdAsc = 'PERSON_ID_ASC',
  PersonIdDesc = 'PERSON_ID_DESC',
  PledgeIdAsc = 'PLEDGE_ID_ASC',
  PledgeIdDesc = 'PLEDGE_ID_DESC',
  ReportedDonationIdAsc = 'REPORTED_DONATION_ID_ASC',
  ReportedDonationIdDesc = 'REPORTED_DONATION_ID_DESC'
}

export type ContactPreference = Node & {
  __typename?: 'ContactPreference';
  createdAt: Scalars['Datetime'];
  currentAddressId?: Maybe<Scalars['BigInt']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Person` that is related to this `ContactPreference`. */
  personByPersonId?: Maybe<Person>;
  personId: Scalars['BigInt'];
  updatedAt: Scalars['Datetime'];
};

/** A connection to a list of `ContactPreference` values. */
export type ContactPreferencesConnection = {
  __typename?: 'ContactPreferencesConnection';
  /** A list of edges which contains the `ContactPreference` and cursor to aid in pagination. */
  edges: Array<ContactPreferencesEdge>;
  /** A list of `ContactPreference` objects. */
  nodes: Array<ContactPreference>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ContactPreference` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ContactPreference` edge in the connection. */
export type ContactPreferencesEdge = {
  __typename?: 'ContactPreferencesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ContactPreference` at the end of the edge. */
  node: ContactPreference;
};

export enum ContactType {
  Individual = 'INDIVIDUAL',
  Organization = 'ORGANIZATION'
}

/** A connection to a list of `Country` values. */
export type CountriesConnection = {
  __typename?: 'CountriesConnection';
  /** A list of edges which contains the `Country` and cursor to aid in pagination. */
  edges: Array<CountriesEdge>;
  /** A list of `Country` objects. */
  nodes: Array<Country>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Country` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Country` edge in the connection. */
export type CountriesEdge = {
  __typename?: 'CountriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Country` at the end of the edge. */
  node: Country;
};

/** Methods to use when ordering `Country`. */
export enum CountriesOrderBy {
  IsoAlpha_2Asc = 'ISO_ALPHA_2_ASC',
  IsoAlpha_2Desc = 'ISO_ALPHA_2_DESC',
  IsoAlpha_3Asc = 'ISO_ALPHA_3_ASC',
  IsoAlpha_3Desc = 'ISO_ALPHA_3_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type Country = Node & {
  __typename?: 'Country';
  /** Reads and enables pagination through a set of `CurrencyByCountry`. */
  currencyByCountriesByCountryCode: CurrencyByCountriesConnection;
  /** Reads and enables pagination through a set of `CurrencyByCountry`. */
  currencyByCountriesByCountryCodeList: Array<CurrencyByCountry>;
  isoAlpha2: Scalars['String'];
  isoAlpha3: Scalars['String'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};


export type CountryCurrencyByCountriesByCountryCodeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyByCountriesOrderBy>>;
};


export type CountryCurrencyByCountriesByCountryCodeListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyByCountriesOrderBy>>;
};

/** All input for the `createAddressByPersonOrTemporaryPerson` mutation. */
export type CreateAddressByPersonOrTemporaryPersonInput = {
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  addressType?: InputMaybe<AddressType>;
  city?: InputMaybe<Scalars['String']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  googlePlaceId?: InputMaybe<Scalars['String']>;
  houseNumber?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  organizationName?: InputMaybe<Scalars['String']>;
  personId?: InputMaybe<Scalars['BigInt']>;
  postalCode?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
};

/** The output of our `createAddressByPersonOrTemporaryPerson` mutation. */
export type CreateAddressByPersonOrTemporaryPersonPayload = {
  __typename?: 'CreateAddressByPersonOrTemporaryPersonPayload';
  address?: Maybe<Address>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Country` that is related to this `Address`. */
  countryByCountryCode?: Maybe<Country>;
  /** Reads a single `Person` that is related to this `Address`. */
  personByPersonId?: Maybe<Person>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `createExternalOrganization` mutation. */
export type CreateExternalOrganizationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  keywords?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  organizationSlug?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

/** The output of our `createExternalOrganization` mutation. */
export type CreateExternalOrganizationPayload = {
  __typename?: 'CreateExternalOrganizationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  externalOrganization?: Maybe<ExternalOrganization>;
  /** An edge for our `ExternalOrganization`. May be used by Relay 1. */
  externalOrganizationEdge?: Maybe<ExternalOrganizationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `createFinancialPeriod` mutation. */
export type CreateFinancialPeriodInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  donationsTaxDeductible?: InputMaybe<Scalars['Boolean']>;
  employmentStatus?: InputMaybe<IncomeEmploymentStatus>;
  incomes?: InputMaybe<Array<InputMaybe<IncomeInput>>>;
  isSigningPledge?: InputMaybe<Scalars['Boolean']>;
  period?: InputMaybe<DateRangeInput>;
  personId?: InputMaybe<Scalars['BigInt']>;
  wealthAmount?: InputMaybe<Scalars['BigFloat']>;
};

/** The output of our `createFinancialPeriod` mutation. */
export type CreateFinancialPeriodPayload = {
  __typename?: 'CreateFinancialPeriodPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  financialPeriod?: Maybe<FinancialPeriod>;
  /** An edge for our `FinancialPeriod`. May be used by Relay 1. */
  financialPeriodEdge?: Maybe<FinancialPeriodsEdge>;
  /** Reads a single `Person` that is related to this `FinancialPeriod`. */
  personByPersonId?: Maybe<Person>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `createGiftAidClaimFromPayment` mutation. */
export type CreateGiftAidClaimFromPaymentInput = {
  addressId?: InputMaybe<Scalars['BigInt']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  paymentId?: InputMaybe<Scalars['BigInt']>;
};

/** The output of our `createGiftAidClaimFromPayment` mutation. */
export type CreateGiftAidClaimFromPaymentPayload = {
  __typename?: 'CreateGiftAidClaimFromPaymentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  giftAidClaim?: Maybe<GiftAidClaim>;
  /** An edge for our `GiftAidClaim`. May be used by Relay 1. */
  giftAidClaimEdge?: Maybe<GiftAidClaimsEdge>;
  /** Reads a single `Payment` that is related to this `GiftAidClaim`. */
  paymentByGiftAidPaymentId?: Maybe<Payment>;
  /** Reads a single `Payment` that is related to this `GiftAidClaim`. */
  paymentByPaymentIdAndPersonId?: Maybe<Payment>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `createGocardlessRedirectFlowByPersonOrTemporaryPerson` mutation. */
export type CreateGocardlessRedirectFlowByPersonOrTemporaryPersonInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  personId?: InputMaybe<Scalars['BigInt']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
};

/** The output of our `createGocardlessRedirectFlowByPersonOrTemporaryPerson` mutation. */
export type CreateGocardlessRedirectFlowByPersonOrTemporaryPersonPayload = {
  __typename?: 'CreateGocardlessRedirectFlowByPersonOrTemporaryPersonPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  gocardlessRedirectFlow?: Maybe<GocardlessRedirectFlow>;
  /** Reads a single `Person` that is related to this `GocardlessRedirectFlow`. */
  personByPersonId?: Maybe<Person>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `createPaymentByPersonId` mutation. */
export type CreatePaymentByPersonIdInput = {
  allocation?: InputMaybe<Scalars['JSON']>;
  amount?: InputMaybe<Scalars['BigFloat']>;
  billingAddressId?: InputMaybe<Scalars['BigInt']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  gateway?: InputMaybe<PaymentGateway>;
  gatewayData?: InputMaybe<Scalars['JSON']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  personId?: InputMaybe<Scalars['BigInt']>;
  recipient?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<PaymentStatus>;
};

/** The output of our `createPaymentByPersonId` mutation. */
export type CreatePaymentByPersonIdPayload = {
  __typename?: 'CreatePaymentByPersonIdPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Currency` that is related to this `Payment`. */
  currencyByChargeCurrencyCode?: Maybe<Currency>;
  /** Reads a single `Currency` that is related to this `Payment`. */
  currencyByCurrencyCode?: Maybe<Currency>;
  payment?: Maybe<Payment>;
  /** An edge for our `Payment`. May be used by Relay 1. */
  paymentEdge?: Maybe<PaymentsEdge>;
  /** Reads a single `Person` that is related to this `Payment`. */
  personByPersonId?: Maybe<Person>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `createPaymentByTemporaryPerson` mutation. */
export type CreatePaymentByTemporaryPersonInput = {
  allocation?: InputMaybe<Scalars['JSON']>;
  amount?: InputMaybe<Scalars['BigFloat']>;
  billingAddressId?: InputMaybe<Scalars['BigInt']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  gateway?: InputMaybe<PaymentGateway>;
  gatewayData?: InputMaybe<Scalars['JSON']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  recipient?: InputMaybe<Scalars['String']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
};

/** The output of our `createPaymentByTemporaryPerson` mutation. */
export type CreatePaymentByTemporaryPersonPayload = {
  __typename?: 'CreatePaymentByTemporaryPersonPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Currency` that is related to this `Payment`. */
  currencyByChargeCurrencyCode?: Maybe<Currency>;
  /** Reads a single `Currency` that is related to this `Payment`. */
  currencyByCurrencyCode?: Maybe<Currency>;
  payment?: Maybe<Payment>;
  /** An edge for our `Payment`. May be used by Relay 1. */
  paymentEdge?: Maybe<PaymentsEdge>;
  /** Reads a single `Person` that is related to this `Payment`. */
  personByPersonId?: Maybe<Person>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `createPollOptionVoteByPersonOrTemporaryPerson` mutation. */
export type CreatePollOptionVoteByPersonOrTemporaryPersonInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  context?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  pledgeId?: InputMaybe<Scalars['BigInt']>;
  pollOptionId?: InputMaybe<Scalars['BigInt']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
};

/** The output of our `createPollOptionVoteByPersonOrTemporaryPerson` mutation. */
export type CreatePollOptionVoteByPersonOrTemporaryPersonPayload = {
  __typename?: 'CreatePollOptionVoteByPersonOrTemporaryPersonPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Pledge` that is related to this `PollOptionVote`. */
  pledgeByPledgeId?: Maybe<Pledge>;
  /** Reads a single `PollOptionPublic` that is related to this `PollOptionVote`. */
  pollOptionPublicByPollOptionId?: Maybe<PollOptionPublic>;
  pollOptionVote?: Maybe<PollOptionVote>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `createRecurringPaymentByTemporaryPerson` mutation. */
export type CreateRecurringPaymentByTemporaryPersonInput = {
  allocation?: InputMaybe<Scalars['JSON']>;
  amount?: InputMaybe<Scalars['BigFloat']>;
  billingAddressId?: InputMaybe<Scalars['BigInt']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  gateway?: InputMaybe<PaymentGateway>;
  gatewayData?: InputMaybe<Scalars['JSON']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  period?: InputMaybe<RecurrencePeriod>;
  periodIndex?: InputMaybe<Scalars['Int']>;
  recipient?: InputMaybe<Scalars['String']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
};

/** The output of our `createRecurringPaymentByTemporaryPerson` mutation. */
export type CreateRecurringPaymentByTemporaryPersonPayload = {
  __typename?: 'CreateRecurringPaymentByTemporaryPersonPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Currency` that is related to this `RecurringPayment`. */
  currencyByCurrencyCode?: Maybe<Currency>;
  /** Reads a single `Person` that is related to this `RecurringPayment`. */
  personByPersonId?: Maybe<Person>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  recurringPayment?: Maybe<RecurringPayment>;
  /** An edge for our `RecurringPayment`. May be used by Relay 1. */
  recurringPaymentEdge?: Maybe<RecurringPaymentsEdge>;
};

/** All input for the `createRecurringPayment` mutation. */
export type CreateRecurringPaymentInput = {
  allocation?: InputMaybe<Scalars['JSON']>;
  amount?: InputMaybe<Scalars['BigFloat']>;
  billingAddressId?: InputMaybe<Scalars['BigInt']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  gateway?: InputMaybe<PaymentGateway>;
  gatewayData?: InputMaybe<Scalars['JSON']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  period?: InputMaybe<RecurrencePeriod>;
  periodIndex?: InputMaybe<Scalars['Int']>;
  personId?: InputMaybe<Scalars['BigInt']>;
  recipient?: InputMaybe<Scalars['String']>;
};

/** The output of our `createRecurringPayment` mutation. */
export type CreateRecurringPaymentPayload = {
  __typename?: 'CreateRecurringPaymentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Currency` that is related to this `RecurringPayment`. */
  currencyByCurrencyCode?: Maybe<Currency>;
  /** Reads a single `Person` that is related to this `RecurringPayment`. */
  personByPersonId?: Maybe<Person>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  recurringPayment?: Maybe<RecurringPayment>;
  /** An edge for our `RecurringPayment`. May be used by Relay 1. */
  recurringPaymentEdge?: Maybe<RecurringPaymentsEdge>;
};

/** All input for the `createRecurringReportedDonation` mutation. */
export type CreateRecurringReportedDonationInput = {
  amount?: InputMaybe<Scalars['BigFloat']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['BigInt']>;
  personId?: InputMaybe<Scalars['BigInt']>;
  recurrenceInterval?: InputMaybe<IntervalInput>;
  startDate?: InputMaybe<Scalars['Date']>;
};

/** The output of our `createRecurringReportedDonation` mutation. */
export type CreateRecurringReportedDonationPayload = {
  __typename?: 'CreateRecurringReportedDonationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Currency` that is related to this `RecurringReportedDonation`. */
  currencyByCurrencyCode?: Maybe<Currency>;
  /** Reads a single `ExternalOrganization` that is related to this `RecurringReportedDonation`. */
  externalOrganizationByOrganizationId?: Maybe<ExternalOrganization>;
  /** Reads a single `Person` that is related to this `RecurringReportedDonation`. */
  personByPersonId?: Maybe<Person>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  recurringReportedDonation?: Maybe<RecurringReportedDonation>;
  /** An edge for our `RecurringReportedDonation`. May be used by Relay 1. */
  recurringReportedDonationEdge?: Maybe<RecurringReportedDonationsEdge>;
};

/** All input for the `createReportedDonationByPersonId` mutation. */
export type CreateReportedDonationByPersonIdInput = {
  amount?: InputMaybe<Scalars['BigFloat']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  donationDate?: InputMaybe<Scalars['Date']>;
  financialPeriodId?: InputMaybe<Scalars['BigInt']>;
  organizationId?: InputMaybe<Scalars['BigInt']>;
  personId?: InputMaybe<Scalars['BigInt']>;
  pledgeId?: InputMaybe<Scalars['BigInt']>;
};

/** The output of our `createReportedDonationByPersonId` mutation. */
export type CreateReportedDonationByPersonIdPayload = {
  __typename?: 'CreateReportedDonationByPersonIdPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Currency` that is related to this `ReportedDonation`. */
  currencyByCurrencyCode?: Maybe<Currency>;
  /** Reads a single `ExternalOrganization` that is related to this `ReportedDonation`. */
  externalOrganizationByOrganizationId?: Maybe<ExternalOrganization>;
  /** Reads a single `Person` that is related to this `ReportedDonation`. */
  personByPersonId?: Maybe<Person>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  reportedDonation?: Maybe<ReportedDonation>;
};

/** All input for the `createTemporaryPersonByEmail` mutation. */
export type CreateTemporaryPersonByEmailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  isLongLasting?: InputMaybe<Scalars['Boolean']>;
  recaptchaToken: Scalars['String'];
};

/** The output of our `createTemporaryPersonByEmail` mutation. */
export type CreateTemporaryPersonByEmailPayload = {
  __typename?: 'CreateTemporaryPersonByEmailPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  temporaryPerson?: Maybe<TemporaryPerson>;
};

/** A connection to a list of `Currency` values. */
export type CurrenciesConnection = {
  __typename?: 'CurrenciesConnection';
  /** A list of edges which contains the `Currency` and cursor to aid in pagination. */
  edges: Array<CurrenciesEdge>;
  /** A list of `Currency` objects. */
  nodes: Array<Currency>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Currency` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Currency` edge in the connection. */
export type CurrenciesEdge = {
  __typename?: 'CurrenciesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Currency` at the end of the edge. */
  node: Currency;
};

/** Methods to use when ordering `Currency`. */
export enum CurrenciesOrderBy {
  CodeAsc = 'CODE_ASC',
  CodeDesc = 'CODE_DESC',
  CurrencyTypeAsc = 'CURRENCY_TYPE_ASC',
  CurrencyTypeDesc = 'CURRENCY_TYPE_DESC',
  DecimalsAsc = 'DECIMALS_ASC',
  DecimalsDesc = 'DECIMALS_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SymbolAsc = 'SYMBOL_ASC',
  SymbolDesc = 'SYMBOL_DESC'
}

export type Currency = Node & {
  __typename?: 'Currency';
  code: Scalars['String'];
  /** Reads and enables pagination through a set of `CurrencyByCountry`. */
  currencyByCountriesByCurrencyCode: CurrencyByCountriesConnection;
  /** Reads and enables pagination through a set of `CurrencyByCountry`. */
  currencyByCountriesByCurrencyCodeList: Array<CurrencyByCountry>;
  currencyType: CurrencyType;
  decimals: Scalars['Int'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `Payment`. */
  paymentsByChargeCurrencyCode: PaymentsConnection;
  /** Reads and enables pagination through a set of `Payment`. */
  paymentsByChargeCurrencyCodeList: Array<Payment>;
  /** Reads and enables pagination through a set of `Payment`. */
  paymentsByCurrencyCode: PaymentsConnection;
  /** Reads and enables pagination through a set of `Payment`. */
  paymentsByCurrencyCodeList: Array<Payment>;
  /** Reads and enables pagination through a set of `RecurringPayment`. */
  recurringPaymentsByCurrencyCode: RecurringPaymentsConnection;
  /** Reads and enables pagination through a set of `RecurringPayment`. */
  recurringPaymentsByCurrencyCodeList: Array<RecurringPayment>;
  /** Reads and enables pagination through a set of `RecurringReportedDonation`. */
  recurringReportedDonationsByCurrencyCode: RecurringReportedDonationsConnection;
  /** Reads and enables pagination through a set of `RecurringReportedDonation`. */
  recurringReportedDonationsByCurrencyCodeList: Array<RecurringReportedDonation>;
  symbol?: Maybe<Scalars['String']>;
};


export type CurrencyCurrencyByCountriesByCurrencyCodeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyByCountriesOrderBy>>;
};


export type CurrencyCurrencyByCountriesByCurrencyCodeListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyByCountriesOrderBy>>;
};


export type CurrencyPaymentsByChargeCurrencyCodeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type CurrencyPaymentsByChargeCurrencyCodeListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type CurrencyPaymentsByCurrencyCodeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type CurrencyPaymentsByCurrencyCodeListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type CurrencyRecurringPaymentsByCurrencyCodeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type CurrencyRecurringPaymentsByCurrencyCodeListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type CurrencyRecurringReportedDonationsByCurrencyCodeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type CurrencyRecurringReportedDonationsByCurrencyCodeListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `CurrencyByCountry` values. */
export type CurrencyByCountriesConnection = {
  __typename?: 'CurrencyByCountriesConnection';
  /** A list of edges which contains the `CurrencyByCountry` and cursor to aid in pagination. */
  edges: Array<CurrencyByCountriesEdge>;
  /** A list of `CurrencyByCountry` objects. */
  nodes: Array<CurrencyByCountry>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CurrencyByCountry` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `CurrencyByCountry` edge in the connection. */
export type CurrencyByCountriesEdge = {
  __typename?: 'CurrencyByCountriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CurrencyByCountry` at the end of the edge. */
  node: CurrencyByCountry;
};

/** Methods to use when ordering `CurrencyByCountry`. */
export enum CurrencyByCountriesOrderBy {
  CountryCodeAsc = 'COUNTRY_CODE_ASC',
  CountryCodeDesc = 'COUNTRY_CODE_DESC',
  CurrencyCodeAsc = 'CURRENCY_CODE_ASC',
  CurrencyCodeDesc = 'CURRENCY_CODE_DESC',
  Natural = 'NATURAL',
  PrecedenceAsc = 'PRECEDENCE_ASC',
  PrecedenceDesc = 'PRECEDENCE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type CurrencyByCountry = Node & {
  __typename?: 'CurrencyByCountry';
  /** Reads a single `Country` that is related to this `CurrencyByCountry`. */
  countryByCountryCode?: Maybe<Country>;
  countryCode: Scalars['String'];
  /** Reads a single `Currency` that is related to this `CurrencyByCountry`. */
  currencyByCurrencyCode?: Maybe<Currency>;
  currencyCode: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  precedence: Scalars['Int'];
};

/**
 * A condition to be used against `Currency` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type CurrencyCondition = {
  /** Checks for equality with the object’s `code` field. */
  code?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `currencyType` field. */
  currencyType?: InputMaybe<CurrencyType>;
  /** Checks for equality with the object’s `decimals` field. */
  decimals?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `symbol` field. */
  symbol?: InputMaybe<Scalars['String']>;
};

export enum CurrencyType {
  Crypto = 'CRYPTO',
  Fiat = 'FIAT'
}

/** A range of `Date`. */
export type DateRange = {
  __typename?: 'DateRange';
  /** The ending bound of our range. */
  end?: Maybe<DateRangeBound>;
  /** The starting bound of our range. */
  start?: Maybe<DateRangeBound>;
};

/** The value at one end of a range. A range can either include this value, or not. */
export type DateRangeBound = {
  __typename?: 'DateRangeBound';
  /** Whether or not the value of this bound is included in the range. */
  inclusive: Scalars['Boolean'];
  /** The value at one end of our range. */
  value: Scalars['Date'];
};

/** The value at one end of a range. A range can either include this value, or not. */
export type DateRangeBoundInput = {
  /** Whether or not the value of this bound is included in the range. */
  inclusive: Scalars['Boolean'];
  /** The value at one end of our range. */
  value: Scalars['Date'];
};

/** A range of `Date`. */
export type DateRangeInput = {
  /** The ending bound of our range. */
  end?: InputMaybe<DateRangeBoundInput>;
  /** The starting bound of our range. */
  start?: InputMaybe<DateRangeBoundInput>;
};

/** All input for the `deleteFinancialPeriod` mutation. */
export type DeleteFinancialPeriodInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
};

/** The output of our `deleteFinancialPeriod` mutation. */
export type DeleteFinancialPeriodPayload = {
  __typename?: 'DeleteFinancialPeriodPayload';
  bigInt?: Maybe<Scalars['BigInt']>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteGiftAidClaimFromPayment` mutation. */
export type DeleteGiftAidClaimFromPaymentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  paymentId?: InputMaybe<Scalars['BigInt']>;
};

/** The output of our `deleteGiftAidClaimFromPayment` mutation. */
export type DeleteGiftAidClaimFromPaymentPayload = {
  __typename?: 'DeleteGiftAidClaimFromPaymentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  giftAidClaim?: Maybe<GiftAidClaim>;
  /** An edge for our `GiftAidClaim`. May be used by Relay 1. */
  giftAidClaimEdge?: Maybe<GiftAidClaimsEdge>;
  /** Reads a single `Payment` that is related to this `GiftAidClaim`. */
  paymentByGiftAidPaymentId?: Maybe<Payment>;
  /** Reads a single `Payment` that is related to this `GiftAidClaim`. */
  paymentByPaymentIdAndPersonId?: Maybe<Payment>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteReportedDonation` mutation. */
export type DeleteReportedDonationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
};

/** The output of our `deleteReportedDonation` mutation. */
export type DeleteReportedDonationPayload = {
  __typename?: 'DeleteReportedDonationPayload';
  bigInt?: Maybe<Scalars['BigInt']>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type ExchangeRate = Node & {
  __typename?: 'ExchangeRate';
  /** Reads a single `Currency` that is related to this `ExchangeRate`. */
  currencyByCurrencyCode?: Maybe<Currency>;
  currencyCode: Scalars['String'];
  date: Scalars['Date'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  rate?: Maybe<Scalars['BigFloat']>;
};

export type ExtendedLottery = {
  __typename?: 'ExtendedLottery';
  blockSize?: Maybe<Scalars['BigFloat']>;
  closeTimestamp?: Maybe<Scalars['Datetime']>;
  drawEpoch?: Maybe<Scalars['Int']>;
  drawTimestamp?: Maybe<Scalars['Datetime']>;
  id?: Maybe<Scalars['BigInt']>;
  lockTimestamp?: Maybe<Scalars['Datetime']>;
  status?: Maybe<Scalars['String']>;
  winningNumber?: Maybe<Scalars['BigInt']>;
};

export type ExternalOrganization = Node & {
  __typename?: 'ExternalOrganization';
  /** Reads and enables pagination through a set of `CompletionPayment`. */
  completionPaymentsByOrganizationId: CompletionPaymentsConnection;
  /** Reads and enables pagination through a set of `CompletionPayment`. */
  completionPaymentsByOrganizationIdList: Array<CompletionPayment>;
  createdAt: Scalars['Datetime'];
  id: Scalars['BigInt'];
  keywords?: Maybe<Array<Maybe<Scalars['String']>>>;
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  organizationSlug?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `RecurringReportedDonation`. */
  recurringReportedDonationsByOrganizationId: RecurringReportedDonationsConnection;
  /** Reads and enables pagination through a set of `RecurringReportedDonation`. */
  recurringReportedDonationsByOrganizationIdList: Array<RecurringReportedDonation>;
  updatedAt: Scalars['Datetime'];
  url?: Maybe<Scalars['String']>;
};


export type ExternalOrganizationCompletionPaymentsByOrganizationIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CompletionPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CompletionPaymentsOrderBy>>;
};


export type ExternalOrganizationCompletionPaymentsByOrganizationIdListArgs = {
  condition?: InputMaybe<CompletionPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CompletionPaymentsOrderBy>>;
};


export type ExternalOrganizationRecurringReportedDonationsByOrganizationIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type ExternalOrganizationRecurringReportedDonationsByOrganizationIdListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `ExternalOrganization` values. */
export type ExternalOrganizationsConnection = {
  __typename?: 'ExternalOrganizationsConnection';
  /** A list of edges which contains the `ExternalOrganization` and cursor to aid in pagination. */
  edges: Array<ExternalOrganizationsEdge>;
  /** A list of `ExternalOrganization` objects. */
  nodes: Array<ExternalOrganization>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ExternalOrganization` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ExternalOrganization` edge in the connection. */
export type ExternalOrganizationsEdge = {
  __typename?: 'ExternalOrganizationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ExternalOrganization` at the end of the edge. */
  node: ExternalOrganization;
};

export type FinancialPeriod = Node & {
  __typename?: 'FinancialPeriod';
  /** Reads and enables pagination through a set of `CompletionPayment`. */
  completionPaymentsByFinancialPeriodId: CompletionPaymentsConnection;
  /** Reads and enables pagination through a set of `CompletionPayment`. */
  completionPaymentsByFinancialPeriodIdList: Array<CompletionPayment>;
  createdAt: Scalars['Datetime'];
  currencyCode: Scalars['String'];
  donationsTaxDeductible?: Maybe<Scalars['Boolean']>;
  employmentStatus?: Maybe<IncomeEmploymentStatus>;
  id: Scalars['BigInt'];
  /** Reads and enables pagination through a set of `Income`. */
  incomesByFinancialPeriodId: IncomesConnection;
  /** Reads and enables pagination through a set of `Income`. */
  incomesByFinancialPeriodIdList: Array<Income>;
  /** Reads and enables pagination through a set of `Liability`. */
  liabilitiesByFinancialPeriodId: LiabilitiesConnection;
  /** Reads and enables pagination through a set of `Liability`. */
  liabilitiesByFinancialPeriodIdList: Array<Liability>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  period: DateRange;
  /** Reads a single `Person` that is related to this `FinancialPeriod`. */
  personByPersonId?: Maybe<Person>;
  personId: Scalars['BigInt'];
  updatedAt: Scalars['Datetime'];
  wealthAmount?: Maybe<Scalars['BigFloat']>;
};


export type FinancialPeriodCompletionPaymentsByFinancialPeriodIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CompletionPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CompletionPaymentsOrderBy>>;
};


export type FinancialPeriodCompletionPaymentsByFinancialPeriodIdListArgs = {
  condition?: InputMaybe<CompletionPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CompletionPaymentsOrderBy>>;
};


export type FinancialPeriodIncomesByFinancialPeriodIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type FinancialPeriodIncomesByFinancialPeriodIdListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type FinancialPeriodLiabilitiesByFinancialPeriodIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LiabilityCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type FinancialPeriodLiabilitiesByFinancialPeriodIdListArgs = {
  condition?: InputMaybe<LiabilityCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `FinancialPeriod` values. */
export type FinancialPeriodsConnection = {
  __typename?: 'FinancialPeriodsConnection';
  /** A list of edges which contains the `FinancialPeriod` and cursor to aid in pagination. */
  edges: Array<FinancialPeriodsEdge>;
  /** A list of `FinancialPeriod` objects. */
  nodes: Array<FinancialPeriod>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FinancialPeriod` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `FinancialPeriod` edge in the connection. */
export type FinancialPeriodsEdge = {
  __typename?: 'FinancialPeriodsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `FinancialPeriod` at the end of the edge. */
  node: FinancialPeriod;
};

export type FundraiserStat = {
  __typename?: 'FundraiserStat';
  amountRaisedNormalized?: Maybe<Scalars['BigFloat']>;
  fundraiserId?: Maybe<Scalars['String']>;
  numDonors?: Maybe<Scalars['BigInt']>;
};

/** A connection to a list of `FundraiserStat` values. */
export type FundraiserStatsConnection = {
  __typename?: 'FundraiserStatsConnection';
  /** A list of edges which contains the `FundraiserStat` and cursor to aid in pagination. */
  edges: Array<FundraiserStatsEdge>;
  /** A list of `FundraiserStat` objects. */
  nodes: Array<FundraiserStat>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FundraiserStat` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `FundraiserStat` edge in the connection. */
export type FundraiserStatsEdge = {
  __typename?: 'FundraiserStatsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `FundraiserStat` at the end of the edge. */
  node: FundraiserStat;
};

export type GetPledgeByPersonOrTemporaryPersonT = {
  __typename?: 'GetPledgeByPersonOrTemporaryPersonT';
  anonymous: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  email?: Maybe<Scalars['String']>;
  emailVerifiedAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['BigInt'];
  incomePercentage: Scalars['BigFloat'];
  locale: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
  motivation?: Maybe<Scalars['String']>;
  period: DateRange;
  personId: Scalars['BigInt'];
  pledgeType: PledgeType;
  updatedAt: Scalars['Datetime'];
  wealthPercentage?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `GetPledgesByApprovedMotivationRecord` values. */
export type GetPledgesByApprovedMotivationConnection = {
  __typename?: 'GetPledgesByApprovedMotivationConnection';
  /** A list of edges which contains the `GetPledgesByApprovedMotivationRecord` and cursor to aid in pagination. */
  edges: Array<GetPledgesByApprovedMotivationEdge>;
  /** A list of `GetPledgesByApprovedMotivationRecord` objects. */
  nodes: Array<GetPledgesByApprovedMotivationRecord>;
  /** The count of *all* `GetPledgesByApprovedMotivationRecord` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `GetPledgesByApprovedMotivationRecord` edge in the connection. */
export type GetPledgesByApprovedMotivationEdge = {
  __typename?: 'GetPledgesByApprovedMotivationEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `GetPledgesByApprovedMotivationRecord` at the end of the edge. */
  node: GetPledgesByApprovedMotivationRecord;
};

/** The return type of our `getPledgesByApprovedMotivation` query. */
export type GetPledgesByApprovedMotivationRecord = {
  __typename?: 'GetPledgesByApprovedMotivationRecord';
  countryOfResidence?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  motivation?: Maybe<Scalars['String']>;
  personFullName?: Maybe<Scalars['String']>;
};

export type GiftAidClaim = Node & {
  __typename?: 'GiftAidClaim';
  addressId: Scalars['BigInt'];
  createdAt: Scalars['Datetime'];
  giftAidPaymentId?: Maybe<Scalars['BigInt']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Payment` that is related to this `GiftAidClaim`. */
  paymentByGiftAidPaymentId?: Maybe<Payment>;
  /** Reads a single `Payment` that is related to this `GiftAidClaim`. */
  paymentByPaymentIdAndPersonId?: Maybe<Payment>;
  paymentId: Scalars['BigInt'];
  personId: Scalars['BigInt'];
  reportId?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['Datetime'];
};

/** A connection to a list of `GiftAidClaim` values. */
export type GiftAidClaimsConnection = {
  __typename?: 'GiftAidClaimsConnection';
  /** A list of edges which contains the `GiftAidClaim` and cursor to aid in pagination. */
  edges: Array<GiftAidClaimsEdge>;
  /** A list of `GiftAidClaim` objects. */
  nodes: Array<GiftAidClaim>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GiftAidClaim` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `GiftAidClaim` edge in the connection. */
export type GiftAidClaimsEdge = {
  __typename?: 'GiftAidClaimsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `GiftAidClaim` at the end of the edge. */
  node: GiftAidClaim;
};

export type GlobalStat = {
  __typename?: 'GlobalStat';
  activeTryGivingPledgesTotal?: Maybe<Scalars['Int']>;
  gwwcDonationsTotal?: Maybe<Scalars['BigFloat']>;
  gwwcPledgesTotal?: Maybe<Scalars['Int']>;
  pledgersCountriesTotal?: Maybe<Scalars['Int']>;
  pledgesDonationsTotal?: Maybe<Scalars['BigFloat']>;
  tryGivingCompletedTotal?: Maybe<Scalars['Int']>;
};

/** A connection to a list of `GlobalStat` values. */
export type GlobalStatsConnection = {
  __typename?: 'GlobalStatsConnection';
  /** A list of edges which contains the `GlobalStat` and cursor to aid in pagination. */
  edges: Array<GlobalStatsEdge>;
  /** A list of `GlobalStat` objects. */
  nodes: Array<GlobalStat>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GlobalStat` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `GlobalStat` edge in the connection. */
export type GlobalStatsEdge = {
  __typename?: 'GlobalStatsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `GlobalStat` at the end of the edge. */
  node: GlobalStat;
};

export type GocardlessMandate = {
  __typename?: 'GocardlessMandate';
  createdAt: Scalars['Datetime'];
  gocardlessBankAccountBankName?: Maybe<Scalars['String']>;
  gocardlessBankAccountHolderName?: Maybe<Scalars['String']>;
  gocardlessBankAccountId?: Maybe<Scalars['String']>;
  gocardlessBankAccountNumberEnding?: Maybe<Scalars['Int']>;
  gocardlessCustomerId: Scalars['String'];
  gocardlessMandateId: Scalars['String'];
  /** Reads a single `Person` that is related to this `GocardlessMandate`. */
  personByPersonId?: Maybe<Person>;
  personId: Scalars['BigInt'];
  status: GocardlessMandateStatus;
  updatedAt: Scalars['Datetime'];
};

export enum GocardlessMandateStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  PendingCustomerApproval = 'PENDING_CUSTOMER_APPROVAL',
  PendingSubmission = 'PENDING_SUBMISSION',
  Submitted = 'SUBMITTED'
}

/** A connection to a list of `GocardlessMandate` values. */
export type GocardlessMandatesConnection = {
  __typename?: 'GocardlessMandatesConnection';
  /** A list of edges which contains the `GocardlessMandate` and cursor to aid in pagination. */
  edges: Array<GocardlessMandatesEdge>;
  /** A list of `GocardlessMandate` objects. */
  nodes: Array<GocardlessMandate>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GocardlessMandate` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `GocardlessMandate` edge in the connection. */
export type GocardlessMandatesEdge = {
  __typename?: 'GocardlessMandatesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `GocardlessMandate` at the end of the edge. */
  node: GocardlessMandate;
};

export type GocardlessRedirectFlow = Node & {
  __typename?: 'GocardlessRedirectFlow';
  createdAt: Scalars['Datetime'];
  expires: Scalars['Datetime'];
  gocardlessRedirectFlowId?: Maybe<Scalars['String']>;
  gocardlessRedirectFlowUrl?: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Person` that is related to this `GocardlessRedirectFlow`. */
  personByPersonId?: Maybe<Person>;
  personId: Scalars['BigInt'];
  sessionToken: Scalars['UUID'];
  updatedAt: Scalars['Datetime'];
};

export type Income = Node & {
  __typename?: 'Income';
  amount: Scalars['BigFloat'];
  createdAt: Scalars['Datetime'];
  /** Reads a single `FinancialPeriod` that is related to this `Income`. */
  financialPeriodByFinancialPeriodId?: Maybe<FinancialPeriod>;
  financialPeriodId: Scalars['BigInt'];
  id: Scalars['BigInt'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Person` that is related to this `Income`. */
  personByPersonId?: Maybe<Person>;
  personId: Scalars['BigInt'];
  updatedAt: Scalars['Datetime'];
};

export enum IncomeEmploymentStatus {
  Employed = 'EMPLOYED',
  Retired = 'RETIRED',
  Student = 'STUDENT',
  Unemployed = 'UNEMPLOYED'
}

/** An input for mutations affecting `Income` */
export type IncomeInput = {
  amount: Scalars['BigFloat'];
  createdAt?: InputMaybe<Scalars['Datetime']>;
  financialPeriodId: Scalars['BigInt'];
  id?: InputMaybe<Scalars['BigInt']>;
  personId: Scalars['BigInt'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A connection to a list of `Income` values. */
export type IncomesConnection = {
  __typename?: 'IncomesConnection';
  /** A list of edges which contains the `Income` and cursor to aid in pagination. */
  edges: Array<IncomesEdge>;
  /** A list of `Income` objects. */
  nodes: Array<Income>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Income` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Income` edge in the connection. */
export type IncomesEdge = {
  __typename?: 'IncomesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Income` at the end of the edge. */
  node: Income;
};

/** An interval of time that has passed where the smallest distinct unit is a second. */
export type Interval = {
  __typename?: 'Interval';
  /** A quantity of days. */
  days?: Maybe<Scalars['Int']>;
  /** A quantity of hours. */
  hours?: Maybe<Scalars['Int']>;
  /** A quantity of minutes. */
  minutes?: Maybe<Scalars['Int']>;
  /** A quantity of months. */
  months?: Maybe<Scalars['Int']>;
  /**
   * A quantity of seconds. This is the only non-integer field, as all the other
   * fields will dump their overflow into a smaller unit of time. Intervals don’t
   * have a smaller unit than seconds.
   */
  seconds?: Maybe<Scalars['Float']>;
  /** A quantity of years. */
  years?: Maybe<Scalars['Int']>;
};

/** An interval of time that has passed where the smallest distinct unit is a second. */
export type IntervalInput = {
  /** A quantity of days. */
  days?: InputMaybe<Scalars['Int']>;
  /** A quantity of hours. */
  hours?: InputMaybe<Scalars['Int']>;
  /** A quantity of minutes. */
  minutes?: InputMaybe<Scalars['Int']>;
  /** A quantity of months. */
  months?: InputMaybe<Scalars['Int']>;
  /**
   * A quantity of seconds. This is the only non-integer field, as all the other
   * fields will dump their overflow into a smaller unit of time. Intervals don’t
   * have a smaller unit than seconds.
   */
  seconds?: InputMaybe<Scalars['Float']>;
  /** A quantity of years. */
  years?: InputMaybe<Scalars['Int']>;
};

/** All input for the `joinPledgeClub` mutation. */
export type JoinPledgeClubInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  personId?: InputMaybe<Scalars['BigInt']>;
  pledgeClubSlug?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
};

/** The output of our `joinPledgeClub` mutation. */
export type JoinPledgeClubPayload = {
  __typename?: 'JoinPledgeClubPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Person` that is related to this `PledgeClubMember`. */
  personByPersonId?: Maybe<Person>;
  /** Reads a single `PledgeClub` that is related to this `PledgeClubMember`. */
  pledgeClubByPledgeClubSlug?: Maybe<PledgeClub>;
  pledgeClubMember?: Maybe<PledgeClubMember>;
  /** An edge for our `PledgeClubMember`. May be used by Relay 1. */
  pledgeClubMemberEdge?: Maybe<PledgeClubMembersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our `joinPledgeClub` mutation. */
export type JoinPledgeClubPayloadPledgeClubMemberEdgeArgs = {
  orderBy?: InputMaybe<Array<PledgeClubMembersOrderBy>>;
};

/** All input for the `leavePledgeClub` mutation. */
export type LeavePledgeClubInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  personId?: InputMaybe<Scalars['BigInt']>;
  pledgeClubSlug?: InputMaybe<Scalars['String']>;
};

/** The output of our `leavePledgeClub` mutation. */
export type LeavePledgeClubPayload = {
  __typename?: 'LeavePledgeClubPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Person` that is related to this `PledgeClubMember`. */
  personByPersonId?: Maybe<Person>;
  /** Reads a single `PledgeClub` that is related to this `PledgeClubMember`. */
  pledgeClubByPledgeClubSlug?: Maybe<PledgeClub>;
  pledgeClubMember?: Maybe<PledgeClubMember>;
  /** An edge for our `PledgeClubMember`. May be used by Relay 1. */
  pledgeClubMemberEdge?: Maybe<PledgeClubMembersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our `leavePledgeClub` mutation. */
export type LeavePledgeClubPayloadPledgeClubMemberEdgeArgs = {
  orderBy?: InputMaybe<Array<PledgeClubMembersOrderBy>>;
};

/** A connection to a list of `Liability` values. */
export type LiabilitiesConnection = {
  __typename?: 'LiabilitiesConnection';
  /** A list of edges which contains the `Liability` and cursor to aid in pagination. */
  edges: Array<LiabilitiesEdge>;
  /** A list of `Liability` objects. */
  nodes: Array<Liability>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Liability` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Liability` edge in the connection. */
export type LiabilitiesEdge = {
  __typename?: 'LiabilitiesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Liability` at the end of the edge. */
  node: Liability;
};

export type Liability = Node & {
  __typename?: 'Liability';
  /** Reads and enables pagination through a set of `CompletionPayment`. */
  completionPaymentsByPledgeIdAndFinancialPeriodId: CompletionPaymentsConnection;
  /** Reads and enables pagination through a set of `CompletionPayment`. */
  completionPaymentsByPledgeIdAndFinancialPeriodIdList: Array<CompletionPayment>;
  createdAt: Scalars['Datetime'];
  currencyCode: Scalars['String'];
  /** Reads a single `FinancialPeriod` that is related to this `Liability`. */
  financialPeriodByFinancialPeriodId?: Maybe<FinancialPeriod>;
  financialPeriodId: Scalars['BigInt'];
  incomeLiabilityAmount?: Maybe<Scalars['BigFloat']>;
  liabilityAmount: Scalars['BigFloat'];
  liabilityNormalized: Scalars['BigFloat'];
  liabilityPeriod: DateRange;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Person` that is related to this `Liability`. */
  personByPersonId?: Maybe<Person>;
  personId: Scalars['BigInt'];
  /** Reads a single `Pledge` that is related to this `Liability`. */
  pledgeByPledgeId?: Maybe<Pledge>;
  pledgeId: Scalars['BigInt'];
  updatedAt: Scalars['Datetime'];
  wealthLiabilityAmount?: Maybe<Scalars['BigFloat']>;
};


export type LiabilityCompletionPaymentsByPledgeIdAndFinancialPeriodIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CompletionPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CompletionPaymentsOrderBy>>;
};


export type LiabilityCompletionPaymentsByPledgeIdAndFinancialPeriodIdListArgs = {
  condition?: InputMaybe<CompletionPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CompletionPaymentsOrderBy>>;
};

export type LiabilityCompletionT = {
  __typename?: 'LiabilityCompletionT';
  completionAmount?: Maybe<Scalars['BigFloat']>;
  completionAmountNormalized?: Maybe<Scalars['BigFloat']>;
  completionPercentage?: Maybe<Scalars['BigFloat']>;
  currencyCode?: Maybe<Scalars['String']>;
  financialPeriodId?: Maybe<Scalars['BigInt']>;
  financialPeriodWealth?: Maybe<Scalars['BigFloat']>;
  incomeLiabilityAmount?: Maybe<Scalars['BigFloat']>;
  liabilityAmount?: Maybe<Scalars['BigFloat']>;
  period?: Maybe<DateRange>;
  personId?: Maybe<Scalars['BigInt']>;
  pledgeId?: Maybe<Scalars['BigInt']>;
  wealthLiabilityAmount?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `LiabilityCompletionT` values. */
export type LiabilityCompletionTsConnection = {
  __typename?: 'LiabilityCompletionTsConnection';
  /** A list of edges which contains the `LiabilityCompletionT` and cursor to aid in pagination. */
  edges: Array<LiabilityCompletionTsEdge>;
  /** A list of `LiabilityCompletionT` objects. */
  nodes: Array<LiabilityCompletionT>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LiabilityCompletionT` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LiabilityCompletionT` edge in the connection. */
export type LiabilityCompletionTsEdge = {
  __typename?: 'LiabilityCompletionTsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LiabilityCompletionT` at the end of the edge. */
  node: LiabilityCompletionT;
};

/**
 * A condition to be used against `Liability` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type LiabilityCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `currencyCode` field. */
  currencyCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `financialPeriodId` field. */
  financialPeriodId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `incomeLiabilityAmount` field. */
  incomeLiabilityAmount?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `liabilityAmount` field. */
  liabilityAmount?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `liabilityNormalized` field. */
  liabilityNormalized?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `liabilityPeriod` field. */
  liabilityPeriod?: InputMaybe<DateRangeInput>;
  /** Checks for equality with the object’s `personId` field. */
  personId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `pledgeId` field. */
  pledgeId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `wealthLiabilityAmount` field. */
  wealthLiabilityAmount?: InputMaybe<Scalars['BigFloat']>;
};

export type LiabilityTotalCompletionT = {
  __typename?: 'LiabilityTotalCompletionT';
  completionAmount?: Maybe<Scalars['BigFloat']>;
  completionAmountNormalized?: Maybe<Scalars['BigFloat']>;
  completionPercentage?: Maybe<Scalars['BigFloat']>;
  currencyCode?: Maybe<Scalars['String']>;
  currencySortOrder?: Maybe<Scalars['BigInt']>;
  liabilityAmount?: Maybe<Scalars['BigFloat']>;
  liabilityAmountNormalized?: Maybe<Scalars['BigFloat']>;
  pledgeId?: Maybe<Scalars['BigInt']>;
};

/** A connection to a list of `LiabilityTotalCompletionT` values. */
export type LiabilityTotalCompletionTsConnection = {
  __typename?: 'LiabilityTotalCompletionTsConnection';
  /** A list of edges which contains the `LiabilityTotalCompletionT` and cursor to aid in pagination. */
  edges: Array<LiabilityTotalCompletionTsEdge>;
  /** A list of `LiabilityTotalCompletionT` objects. */
  nodes: Array<LiabilityTotalCompletionT>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LiabilityTotalCompletionT` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LiabilityTotalCompletionT` edge in the connection. */
export type LiabilityTotalCompletionTsEdge = {
  __typename?: 'LiabilityTotalCompletionTsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LiabilityTotalCompletionT` at the end of the edge. */
  node: LiabilityTotalCompletionT;
};

export type LotteryEntryComplete = {
  __typename?: 'LotteryEntryComplete';
  amountNormalized?: Maybe<Scalars['BigFloat']>;
  anonymous?: Maybe<Scalars['Boolean']>;
  benefactor?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  displayName?: Maybe<Scalars['String']>;
  lotteryId?: Maybe<Scalars['BigInt']>;
  paymentReference?: Maybe<Scalars['String']>;
  tickets?: Maybe<Scalars['JSON']>;
};

/** A connection to a list of `LotteryEntryComplete` values. */
export type LotteryEntryCompletesConnection = {
  __typename?: 'LotteryEntryCompletesConnection';
  /** A list of edges which contains the `LotteryEntryComplete` and cursor to aid in pagination. */
  edges: Array<LotteryEntryCompletesEdge>;
  /** A list of `LotteryEntryComplete` objects. */
  nodes: Array<LotteryEntryComplete>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LotteryEntryComplete` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LotteryEntryComplete` edge in the connection. */
export type LotteryEntryCompletesEdge = {
  __typename?: 'LotteryEntryCompletesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LotteryEntryComplete` at the end of the edge. */
  node: LotteryEntryComplete;
};

export type LotteryEntryPublic = {
  __typename?: 'LotteryEntryPublic';
  amount?: Maybe<Scalars['BigFloat']>;
  amountNormalized?: Maybe<Scalars['BigFloat']>;
  amountNormalizedNetFee?: Maybe<Scalars['BigFloat']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  currencyCode?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  feeNormalized?: Maybe<Scalars['BigFloat']>;
  giftAid?: Maybe<Scalars['BigFloat']>;
  lotteryId?: Maybe<Scalars['BigInt']>;
  paymentReference?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `LotteryEntryPublic` values. */
export type LotteryEntryPublicsConnection = {
  __typename?: 'LotteryEntryPublicsConnection';
  /** A list of edges which contains the `LotteryEntryPublic` and cursor to aid in pagination. */
  edges: Array<LotteryEntryPublicsEdge>;
  /** A list of `LotteryEntryPublic` objects. */
  nodes: Array<LotteryEntryPublic>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LotteryEntryPublic` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LotteryEntryPublic` edge in the connection. */
export type LotteryEntryPublicsEdge = {
  __typename?: 'LotteryEntryPublicsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LotteryEntryPublic` at the end of the edge. */
  node: LotteryEntryPublic;
};

/** A connection to a list of `LotterySummary` values. */
export type LotterySummariesConnection = {
  __typename?: 'LotterySummariesConnection';
  /** A list of edges which contains the `LotterySummary` and cursor to aid in pagination. */
  edges: Array<LotterySummariesEdge>;
  /** A list of `LotterySummary` objects. */
  nodes: Array<LotterySummary>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LotterySummary` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LotterySummary` edge in the connection. */
export type LotterySummariesEdge = {
  __typename?: 'LotterySummariesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LotterySummary` at the end of the edge. */
  node: LotterySummary;
};

/** Methods to use when ordering `LotterySummary`. */
export enum LotterySummariesOrderBy {
  BenefactorLiabilityAsc = 'BENEFACTOR_LIABILITY_ASC',
  BenefactorLiabilityDesc = 'BENEFACTOR_LIABILITY_DESC',
  BenefactorStartingTicketNumberAsc = 'BENEFACTOR_STARTING_TICKET_NUMBER_ASC',
  BenefactorStartingTicketNumberDesc = 'BENEFACTOR_STARTING_TICKET_NUMBER_DESC',
  BenefactorWinsLastBlockAsc = 'BENEFACTOR_WINS_LAST_BLOCK_ASC',
  BenefactorWinsLastBlockDesc = 'BENEFACTOR_WINS_LAST_BLOCK_DESC',
  BlockSizeAsc = 'BLOCK_SIZE_ASC',
  BlockSizeDesc = 'BLOCK_SIZE_DESC',
  CloseTimestampAsc = 'CLOSE_TIMESTAMP_ASC',
  CloseTimestampDesc = 'CLOSE_TIMESTAMP_DESC',
  DrawEpochAsc = 'DRAW_EPOCH_ASC',
  DrawEpochDesc = 'DRAW_EPOCH_DESC',
  DrawTimestampAsc = 'DRAW_TIMESTAMP_ASC',
  DrawTimestampDesc = 'DRAW_TIMESTAMP_DESC',
  EntriesTotalNormalizedAsc = 'ENTRIES_TOTAL_NORMALIZED_ASC',
  EntriesTotalNormalizedDesc = 'ENTRIES_TOTAL_NORMALIZED_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LockTimestampAsc = 'LOCK_TIMESTAMP_ASC',
  LockTimestampDesc = 'LOCK_TIMESTAMP_DESC',
  MaxBlockIdAsc = 'MAX_BLOCK_ID_ASC',
  MaxBlockIdDesc = 'MAX_BLOCK_ID_DESC',
  Natural = 'NATURAL',
  NumEntriesAsc = 'NUM_ENTRIES_ASC',
  NumEntriesDesc = 'NUM_ENTRIES_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  TicketsAssignedAsc = 'TICKETS_ASSIGNED_ASC',
  TicketsAssignedDesc = 'TICKETS_ASSIGNED_DESC',
  WinningNumberAsc = 'WINNING_NUMBER_ASC',
  WinningNumberDesc = 'WINNING_NUMBER_DESC',
  WinningNumberHexAsc = 'WINNING_NUMBER_HEX_ASC',
  WinningNumberHexDesc = 'WINNING_NUMBER_HEX_DESC',
  WinningTicketsAsc = 'WINNING_TICKETS_ASC',
  WinningTicketsDesc = 'WINNING_TICKETS_DESC'
}

export type LotterySummary = {
  __typename?: 'LotterySummary';
  benefactorLiability?: Maybe<Scalars['BigFloat']>;
  benefactorStartingTicketNumber?: Maybe<Scalars['BigInt']>;
  benefactorWinsLastBlock?: Maybe<Scalars['Boolean']>;
  blockSize?: Maybe<Scalars['BigFloat']>;
  closeTimestamp?: Maybe<Scalars['Datetime']>;
  drawEpoch?: Maybe<Scalars['BigInt']>;
  drawTimestamp?: Maybe<Scalars['Datetime']>;
  entriesTotalNormalized?: Maybe<Scalars['BigFloat']>;
  id?: Maybe<Scalars['BigInt']>;
  lockTimestamp?: Maybe<Scalars['Datetime']>;
  maxBlockId?: Maybe<Scalars['Int']>;
  numEntries?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  ticketsAssigned?: Maybe<Scalars['Boolean']>;
  winningNumber?: Maybe<Scalars['BigInt']>;
  winningNumberHex?: Maybe<Scalars['String']>;
  winningTickets?: Maybe<Scalars['JSON']>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  __typename?: 'Mutation';
  addReminder?: Maybe<AddReminderPayload>;
  cancelRecurringPayment?: Maybe<CancelRecurringPaymentPayload>;
  cancelRecurringReportedDonation?: Maybe<CancelRecurringReportedDonationPayload>;
  createAddressByPersonOrTemporaryPerson?: Maybe<CreateAddressByPersonOrTemporaryPersonPayload>;
  createExternalOrganization?: Maybe<CreateExternalOrganizationPayload>;
  createFinancialPeriod?: Maybe<CreateFinancialPeriodPayload>;
  createGiftAidClaimFromPayment?: Maybe<CreateGiftAidClaimFromPaymentPayload>;
  createGocardlessRedirectFlowByPersonOrTemporaryPerson?: Maybe<CreateGocardlessRedirectFlowByPersonOrTemporaryPersonPayload>;
  createPaymentByPersonId?: Maybe<CreatePaymentByPersonIdPayload>;
  createPaymentByTemporaryPerson?: Maybe<CreatePaymentByTemporaryPersonPayload>;
  createPollOptionVoteByPersonOrTemporaryPerson?: Maybe<CreatePollOptionVoteByPersonOrTemporaryPersonPayload>;
  createRecurringPayment?: Maybe<CreateRecurringPaymentPayload>;
  createRecurringPaymentByTemporaryPerson?: Maybe<CreateRecurringPaymentByTemporaryPersonPayload>;
  createRecurringReportedDonation?: Maybe<CreateRecurringReportedDonationPayload>;
  createReportedDonationByPersonId?: Maybe<CreateReportedDonationByPersonIdPayload>;
  createTemporaryPersonByEmail?: Maybe<CreateTemporaryPersonByEmailPayload>;
  deleteFinancialPeriod?: Maybe<DeleteFinancialPeriodPayload>;
  deleteGiftAidClaimFromPayment?: Maybe<DeleteGiftAidClaimFromPaymentPayload>;
  deleteReportedDonation?: Maybe<DeleteReportedDonationPayload>;
  joinPledgeClub?: Maybe<JoinPledgeClubPayload>;
  leavePledgeClub?: Maybe<LeavePledgeClubPayload>;
  recordSurveyResponses?: Maybe<RecordSurveyResponsesPayload>;
  recordSurveyResponsesByTemporaryPerson?: Maybe<RecordSurveyResponsesByTemporaryPersonPayload>;
  signPledge?: Maybe<SignPledgePayload>;
  signPledgeByTemporaryPerson?: Maybe<SignPledgeByTemporaryPersonPayload>;
  syncAuth0User?: Maybe<SyncAuth0UserPayload>;
  updateContactPreferenceByPersonId?: Maybe<UpdateContactPreferenceByPersonIdPayload>;
  updateFinancialPeriod?: Maybe<UpdateFinancialPeriodPayload>;
  updatePersonByPersonId?: Maybe<UpdatePersonByPersonIdPayload>;
  updatePersonEmailAuth0?: Maybe<UpdatePersonEmailAuth0Payload>;
  updatePledgeByPersonOrTemporaryPerson?: Maybe<UpdatePledgeByPersonOrTemporaryPersonPayload>;
  updatePledgeDetails?: Maybe<UpdatePledgeDetailsPayload>;
  updatePledgeDetailsByTemporaryPerson?: Maybe<UpdatePledgeDetailsByTemporaryPersonPayload>;
  updateRecurringPaymentAmount?: Maybe<UpdateRecurringPaymentAmountPayload>;
  updateReportedDonation?: Maybe<UpdateReportedDonationPayload>;
  updateReportedPayment?: Maybe<UpdateReportedPaymentPayload>;
  verifyEmail?: Maybe<VerifyEmailPayload>;
  voidPayment?: Maybe<VoidPaymentPayload>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddReminderArgs = {
  input: AddReminderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCancelRecurringPaymentArgs = {
  input: CancelRecurringPaymentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCancelRecurringReportedDonationArgs = {
  input: CancelRecurringReportedDonationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAddressByPersonOrTemporaryPersonArgs = {
  input: CreateAddressByPersonOrTemporaryPersonInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateExternalOrganizationArgs = {
  input: CreateExternalOrganizationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFinancialPeriodArgs = {
  input: CreateFinancialPeriodInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateGiftAidClaimFromPaymentArgs = {
  input: CreateGiftAidClaimFromPaymentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateGocardlessRedirectFlowByPersonOrTemporaryPersonArgs = {
  input: CreateGocardlessRedirectFlowByPersonOrTemporaryPersonInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePaymentByPersonIdArgs = {
  input: CreatePaymentByPersonIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePaymentByTemporaryPersonArgs = {
  input: CreatePaymentByTemporaryPersonInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePollOptionVoteByPersonOrTemporaryPersonArgs = {
  input: CreatePollOptionVoteByPersonOrTemporaryPersonInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateRecurringPaymentArgs = {
  input: CreateRecurringPaymentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateRecurringPaymentByTemporaryPersonArgs = {
  input: CreateRecurringPaymentByTemporaryPersonInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateRecurringReportedDonationArgs = {
  input: CreateRecurringReportedDonationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateReportedDonationByPersonIdArgs = {
  input: CreateReportedDonationByPersonIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTemporaryPersonByEmailArgs = {
  input: CreateTemporaryPersonByEmailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFinancialPeriodArgs = {
  input: DeleteFinancialPeriodInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGiftAidClaimFromPaymentArgs = {
  input: DeleteGiftAidClaimFromPaymentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteReportedDonationArgs = {
  input: DeleteReportedDonationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationJoinPledgeClubArgs = {
  input: JoinPledgeClubInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationLeavePledgeClubArgs = {
  input: LeavePledgeClubInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRecordSurveyResponsesArgs = {
  input: RecordSurveyResponsesInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRecordSurveyResponsesByTemporaryPersonArgs = {
  input: RecordSurveyResponsesByTemporaryPersonInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSignPledgeArgs = {
  input: SignPledgeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSignPledgeByTemporaryPersonArgs = {
  input: SignPledgeByTemporaryPersonInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSyncAuth0UserArgs = {
  input: SyncAuth0UserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateContactPreferenceByPersonIdArgs = {
  input: UpdateContactPreferenceByPersonIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFinancialPeriodArgs = {
  input: UpdateFinancialPeriodInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePersonByPersonIdArgs = {
  input: UpdatePersonByPersonIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePersonEmailAuth0Args = {
  input: UpdatePersonEmailAuth0Input;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePledgeByPersonOrTemporaryPersonArgs = {
  input: UpdatePledgeByPersonOrTemporaryPersonInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePledgeDetailsArgs = {
  input: UpdatePledgeDetailsInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePledgeDetailsByTemporaryPersonArgs = {
  input: UpdatePledgeDetailsByTemporaryPersonInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateRecurringPaymentAmountArgs = {
  input: UpdateRecurringPaymentAmountInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateReportedDonationArgs = {
  input: UpdateReportedDonationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateReportedPaymentArgs = {
  input: UpdateReportedPaymentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationVerifyEmailArgs = {
  input: VerifyEmailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationVoidPaymentArgs = {
  input: VoidPaymentInput;
};

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']>;
};

export type Payment = Node & {
  __typename?: 'Payment';
  accountingSystem: AccountingSystem;
  allocation: Scalars['JSON'];
  /** The amount that was charged by the gateway after currency conversion. denominated in `currency_code` */
  amount?: Maybe<Scalars['BigFloat']>;
  amountNormalized?: Maybe<Scalars['BigFloat']>;
  billingAddressId?: Maybe<Scalars['BigInt']>;
  /** The amount that the user requested to be charged in their preferred currency, denominated in `charge_currency_code` */
  chargeAmount: Scalars['BigFloat'];
  chargeCurrencyCode: Scalars['String'];
  /** Reads and enables pagination through a set of `CompletionPayment`. */
  completionPaymentsByPaymentId: CompletionPaymentsConnection;
  /** Reads and enables pagination through a set of `CompletionPayment`. */
  completionPaymentsByPaymentIdList: Array<CompletionPayment>;
  createdAt: Scalars['Datetime'];
  /** Reads a single `Currency` that is related to this `Payment`. */
  currencyByChargeCurrencyCode?: Maybe<Currency>;
  /** Reads a single `Currency` that is related to this `Payment`. */
  currencyByCurrencyCode?: Maybe<Currency>;
  currencyCode?: Maybe<Scalars['String']>;
  donationDate?: Maybe<Scalars['Date']>;
  fee?: Maybe<Scalars['BigFloat']>;
  feeNormalized?: Maybe<Scalars['BigFloat']>;
  gateway: PaymentGateway;
  gatewayData?: Maybe<Scalars['JSON']>;
  gatewayTransactionId?: Maybe<Scalars['String']>;
  /** Reads a single `GiftAidClaim` that is related to this `Payment`. */
  giftAidClaimByGiftAidPaymentId?: Maybe<GiftAidClaim>;
  /** Reads and enables pagination through a set of `GiftAidClaim`. */
  giftAidClaimsByPaymentIdAndPersonId: GiftAidClaimsConnection;
  /** Reads and enables pagination through a set of `GiftAidClaim`. */
  giftAidClaimsByPaymentIdAndPersonIdList: Array<GiftAidClaim>;
  id: Scalars['BigInt'];
  lastReceiptSentAt?: Maybe<Scalars['Datetime']>;
  metadata?: Maybe<Scalars['JSON']>;
  net?: Maybe<Scalars['BigFloat']>;
  netNormalized?: Maybe<Scalars['BigFloat']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  paymentMethod?: Maybe<PaymentMethod>;
  paymentType?: Maybe<PaymentType>;
  /** Reads a single `Person` that is related to this `Payment`. */
  personByPersonId?: Maybe<Person>;
  personId: Scalars['BigInt'];
  receivedAt?: Maybe<Scalars['Datetime']>;
  recipient: Scalars['String'];
  recurrence: PaymentRecurrence;
  reference: Scalars['String'];
  status: PaymentStatus;
  statusData?: Maybe<Scalars['JSON']>;
  updatedAt: Scalars['Datetime'];
};


export type PaymentCompletionPaymentsByPaymentIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CompletionPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CompletionPaymentsOrderBy>>;
};


export type PaymentCompletionPaymentsByPaymentIdListArgs = {
  condition?: InputMaybe<CompletionPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CompletionPaymentsOrderBy>>;
};


export type PaymentGiftAidClaimsByPaymentIdAndPersonIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type PaymentGiftAidClaimsByPaymentIdAndPersonIdListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export enum PaymentGateway {
  Chariot = 'CHARIOT',
  Crypto = 'CRYPTO',
  GiftAid = 'GIFT_AID',
  Gocardless = 'GOCARDLESS',
  Offline = 'OFFLINE',
  Paypal = 'PAYPAL',
  PayrollGiving = 'PAYROLL_GIVING',
  Stripe = 'STRIPE'
}

export enum PaymentMethod {
  Acss = 'ACSS',
  BacsDirectDebit = 'BACS_DIRECT_DEBIT',
  BankTransfer = 'BANK_TRANSFER',
  Cheque = 'CHEQUE',
  CreditCard = 'CREDIT_CARD',
  Crypto = 'CRYPTO',
  DonorAdvisedFund = 'DONOR_ADVISED_FUND',
  PayrollGiving = 'PAYROLL_GIVING',
  Stock = 'STOCK'
}

export enum PaymentRecurrence {
  OneOff = 'ONE_OFF',
  Recurring = 'RECURRING'
}

export type PaymentStatsAggregate = {
  __typename?: 'PaymentStatsAggregate';
  numDonations?: Maybe<Scalars['BigInt']>;
  numDonationsLastYear?: Maybe<Scalars['BigInt']>;
  numDonors?: Maybe<Scalars['BigInt']>;
  numDonorsLastYear?: Maybe<Scalars['BigInt']>;
  totalDonations?: Maybe<Scalars['BigFloat']>;
  totalDonationsLastYear?: Maybe<Scalars['BigFloat']>;
};

export enum PaymentStatus {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Refunded = 'REFUNDED',
  Succeeded = 'SUCCEEDED',
  Unpaid = 'UNPAID',
  Voided = 'VOIDED'
}

export enum PaymentType {
  Donation = 'DONATION',
  EmployerMatch = 'EMPLOYER_MATCH',
  Grant = 'GRANT',
  Transfer = 'TRANSFER'
}

/** A connection to a list of `Payment` values. */
export type PaymentsConnection = {
  __typename?: 'PaymentsConnection';
  /** A list of edges which contains the `Payment` and cursor to aid in pagination. */
  edges: Array<PaymentsEdge>;
  /** A list of `Payment` objects. */
  nodes: Array<Payment>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Payment` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Payment` edge in the connection. */
export type PaymentsEdge = {
  __typename?: 'PaymentsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Payment` at the end of the edge. */
  node: Payment;
};

/** A connection to a list of `Person` values. */
export type PeopleConnection = {
  __typename?: 'PeopleConnection';
  /** A list of edges which contains the `Person` and cursor to aid in pagination. */
  edges: Array<PeopleEdge>;
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection. */
export type PeopleEdge = {
  __typename?: 'PeopleEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
};

export type Person = Node & {
  __typename?: 'Person';
  auth0UserId?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['Date']>;
  /** Reads a single `ContactPreference` that is related to this `Person`. */
  contactPreferenceByPersonId?: Maybe<ContactPreference>;
  contactType?: Maybe<ContactType>;
  createdAt: Scalars['Datetime'];
  email?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `FinancialPeriod`. */
  financialPeriodsByPersonId: FinancialPeriodsConnection;
  /** Reads and enables pagination through a set of `FinancialPeriod`. */
  financialPeriodsByPersonIdList: Array<FinancialPeriod>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['BigInt'];
  /** Reads and enables pagination through a set of `Income`. */
  incomesByPersonId: IncomesConnection;
  /** Reads and enables pagination through a set of `Income`. */
  incomesByPersonIdList: Array<Income>;
  isAnonymized: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Liability`. */
  liabilitiesByPersonId: LiabilitiesConnection;
  /** Reads and enables pagination through a set of `Liability`. */
  liabilitiesByPersonIdList: Array<Liability>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  organizationName?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Payment`. */
  paymentsByPersonId: PaymentsConnection;
  /** Reads and enables pagination through a set of `Payment`. */
  paymentsByPersonIdList: Array<Payment>;
  /** Reads and enables pagination through a set of `PledgeClubMember`. */
  pledgeClubMembersByPersonId: PledgeClubMembersConnection;
  /** Reads and enables pagination through a set of `PledgeClubMember`. */
  pledgeClubMembersByPersonIdList: Array<PledgeClubMember>;
  /** Reads and enables pagination through a set of `Pledge`. */
  pledgesByPersonId: PledgesConnection;
  /** Reads and enables pagination through a set of `Pledge`. */
  pledgesByPersonIdList: Array<Pledge>;
  preferredLocale?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `RecurringPayment`. */
  recurringPaymentsByPersonId: RecurringPaymentsConnection;
  /** Reads and enables pagination through a set of `RecurringPayment`. */
  recurringPaymentsByPersonIdList: Array<RecurringPayment>;
  /** Reads and enables pagination through a set of `RecurringReportedDonation`. */
  recurringReportedDonationsByPersonId: RecurringReportedDonationsConnection;
  /** Reads and enables pagination through a set of `RecurringReportedDonation`. */
  recurringReportedDonationsByPersonIdList: Array<RecurringReportedDonation>;
  timezone?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Datetime'];
};


export type PersonFinancialPeriodsByPersonIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type PersonFinancialPeriodsByPersonIdListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type PersonIncomesByPersonIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type PersonIncomesByPersonIdListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type PersonLiabilitiesByPersonIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LiabilityCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type PersonLiabilitiesByPersonIdListArgs = {
  condition?: InputMaybe<LiabilityCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type PersonPaymentsByPersonIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type PersonPaymentsByPersonIdListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type PersonPledgeClubMembersByPersonIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PledgeClubMemberCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PledgeClubMembersOrderBy>>;
};


export type PersonPledgeClubMembersByPersonIdListArgs = {
  condition?: InputMaybe<PledgeClubMemberCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PledgeClubMembersOrderBy>>;
};


export type PersonPledgesByPersonIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type PersonPledgesByPersonIdListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type PersonRecurringPaymentsByPersonIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type PersonRecurringPaymentsByPersonIdListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type PersonRecurringReportedDonationsByPersonIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type PersonRecurringReportedDonationsByPersonIdListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type Pledge = Node & {
  __typename?: 'Pledge';
  anonymous: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  emailVerifiedAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['BigInt'];
  incomePercentage: Scalars['BigFloat'];
  /** Reads and enables pagination through a set of `Liability`. */
  liabilitiesByPledgeId: LiabilitiesConnection;
  /** Reads and enables pagination through a set of `Liability`. */
  liabilitiesByPledgeIdList: Array<Liability>;
  /** Locale in which this pledge was taken */
  locale: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
  motivation?: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  period: DateRange;
  /** Reads a single `Person` that is related to this `Pledge`. */
  personByPersonId?: Maybe<Person>;
  personId: Scalars['BigInt'];
  pledgeType: PledgeType;
  updatedAt: Scalars['Datetime'];
  wealthPercentage?: Maybe<Scalars['BigFloat']>;
};


export type PledgeLiabilitiesByPledgeIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LiabilityCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type PledgeLiabilitiesByPledgeIdListArgs = {
  condition?: InputMaybe<LiabilityCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type PledgeClub = Node & {
  __typename?: 'PledgeClub';
  active: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  id: Scalars['BigInt'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `PledgeClubMember`. */
  pledgeClubMembersByPledgeClubSlug: PledgeClubMembersConnection;
  /** Reads and enables pagination through a set of `PledgeClubMember`. */
  pledgeClubMembersByPledgeClubSlugList: Array<PledgeClubMember>;
  slug: Scalars['String'];
  updatedAt: Scalars['Datetime'];
};


export type PledgeClubPledgeClubMembersByPledgeClubSlugArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PledgeClubMemberCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PledgeClubMembersOrderBy>>;
};


export type PledgeClubPledgeClubMembersByPledgeClubSlugListArgs = {
  condition?: InputMaybe<PledgeClubMemberCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PledgeClubMembersOrderBy>>;
};

/**
 * A condition to be used against `PledgeClub` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type PledgeClubCondition = {
  /** Checks for equality with the object’s `active` field. */
  active?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

export type PledgeClubMember = Node & {
  __typename?: 'PledgeClubMember';
  createdAt: Scalars['Datetime'];
  id: Scalars['BigInt'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Person` that is related to this `PledgeClubMember`. */
  personByPersonId?: Maybe<Person>;
  personId: Scalars['BigInt'];
  /** Reads a single `PledgeClub` that is related to this `PledgeClubMember`. */
  pledgeClubByPledgeClubSlug?: Maybe<PledgeClub>;
  pledgeClubSlug: Scalars['String'];
  source: Scalars['String'];
  updatedAt: Scalars['Datetime'];
};

/**
 * A condition to be used against `PledgeClubMember` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type PledgeClubMemberCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `personId` field. */
  personId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `pledgeClubSlug` field. */
  pledgeClubSlug?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `source` field. */
  source?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A connection to a list of `PledgeClubMember` values. */
export type PledgeClubMembersConnection = {
  __typename?: 'PledgeClubMembersConnection';
  /** A list of edges which contains the `PledgeClubMember` and cursor to aid in pagination. */
  edges: Array<PledgeClubMembersEdge>;
  /** A list of `PledgeClubMember` objects. */
  nodes: Array<PledgeClubMember>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PledgeClubMember` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PledgeClubMember` edge in the connection. */
export type PledgeClubMembersEdge = {
  __typename?: 'PledgeClubMembersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PledgeClubMember` at the end of the edge. */
  node: PledgeClubMember;
};

/** Methods to use when ordering `PledgeClubMember`. */
export enum PledgeClubMembersOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PersonIdAsc = 'PERSON_ID_ASC',
  PersonIdDesc = 'PERSON_ID_DESC',
  PledgeClubSlugAsc = 'PLEDGE_CLUB_SLUG_ASC',
  PledgeClubSlugDesc = 'PLEDGE_CLUB_SLUG_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SourceAsc = 'SOURCE_ASC',
  SourceDesc = 'SOURCE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** A connection to a list of `PledgeClub` values. */
export type PledgeClubsConnection = {
  __typename?: 'PledgeClubsConnection';
  /** A list of edges which contains the `PledgeClub` and cursor to aid in pagination. */
  edges: Array<PledgeClubsEdge>;
  /** A list of `PledgeClub` objects. */
  nodes: Array<PledgeClub>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PledgeClub` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PledgeClub` edge in the connection. */
export type PledgeClubsEdge = {
  __typename?: 'PledgeClubsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PledgeClub` at the end of the edge. */
  node: PledgeClub;
};

/** Methods to use when ordering `PledgeClub`. */
export enum PledgeClubsOrderBy {
  ActiveAsc = 'ACTIVE_ASC',
  ActiveDesc = 'ACTIVE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type PledgeRank = Node & {
  __typename?: 'PledgeRank';
  commencementDate: Scalars['Date'];
  createdAt: Scalars['Datetime'];
  latestPledgeId?: Maybe<Scalars['BigInt']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Person` that is related to this `PledgeRank`. */
  personByPersonId?: Maybe<Person>;
  personId: Scalars['BigInt'];
  /** Reads a single `Pledge` that is related to this `PledgeRank`. */
  pledgeByLatestPledgeId?: Maybe<Pledge>;
  pledgeType: PledgeType;
  rankByPledgeType: Scalars['Int'];
  updatedAt: Scalars['Datetime'];
};

/**
 * A condition to be used against `PledgeRank` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type PledgeRankCondition = {
  /** Checks for equality with the object’s `commencementDate` field. */
  commencementDate?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `latestPledgeId` field. */
  latestPledgeId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `personId` field. */
  personId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `pledgeType` field. */
  pledgeType?: InputMaybe<PledgeType>;
  /** Checks for equality with the object’s `rankByPledgeType` field. */
  rankByPledgeType?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A connection to a list of `PledgeRank` values. */
export type PledgeRanksConnection = {
  __typename?: 'PledgeRanksConnection';
  /** A list of edges which contains the `PledgeRank` and cursor to aid in pagination. */
  edges: Array<PledgeRanksEdge>;
  /** A list of `PledgeRank` objects. */
  nodes: Array<PledgeRank>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PledgeRank` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PledgeRank` edge in the connection. */
export type PledgeRanksEdge = {
  __typename?: 'PledgeRanksEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PledgeRank` at the end of the edge. */
  node: PledgeRank;
};

/** Methods to use when ordering `PledgeRank`. */
export enum PledgeRanksOrderBy {
  CommencementDateAsc = 'COMMENCEMENT_DATE_ASC',
  CommencementDateDesc = 'COMMENCEMENT_DATE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  LatestPledgeIdAsc = 'LATEST_PLEDGE_ID_ASC',
  LatestPledgeIdDesc = 'LATEST_PLEDGE_ID_DESC',
  Natural = 'NATURAL',
  PersonIdAsc = 'PERSON_ID_ASC',
  PersonIdDesc = 'PERSON_ID_DESC',
  PledgeTypeAsc = 'PLEDGE_TYPE_ASC',
  PledgeTypeDesc = 'PLEDGE_TYPE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RankByPledgeTypeAsc = 'RANK_BY_PLEDGE_TYPE_ASC',
  RankByPledgeTypeDesc = 'RANK_BY_PLEDGE_TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export enum PledgeType {
  GivingWhatWeCan = 'GIVING_WHAT_WE_CAN',
  TryGiving = 'TRY_GIVING'
}

export type PledgedMembersPublic = {
  __typename?: 'PledgedMembersPublic';
  active?: Maybe<Scalars['Boolean']>;
  anonymous?: Maybe<Scalars['Boolean']>;
  commencementDate?: Maybe<Scalars['Date']>;
  fullName?: Maybe<Scalars['String']>;
  latestPledgeEndDate?: Maybe<Scalars['Date']>;
  latestPledgePercentage?: Maybe<Scalars['BigFloat']>;
  pledgeType?: Maybe<PledgeType>;
  rankByPledgeType?: Maybe<Scalars['Int']>;
};

/**
 * A condition to be used against `PledgedMembersPublic` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type PledgedMembersPublicCondition = {
  /** Checks for equality with the object’s `active` field. */
  active?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `anonymous` field. */
  anonymous?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `commencementDate` field. */
  commencementDate?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `fullName` field. */
  fullName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `latestPledgeEndDate` field. */
  latestPledgeEndDate?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `latestPledgePercentage` field. */
  latestPledgePercentage?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `pledgeType` field. */
  pledgeType?: InputMaybe<PledgeType>;
  /** Checks for equality with the object’s `rankByPledgeType` field. */
  rankByPledgeType?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `PledgedMembersPublic` values. */
export type PledgedMembersPublicsConnection = {
  __typename?: 'PledgedMembersPublicsConnection';
  /** A list of edges which contains the `PledgedMembersPublic` and cursor to aid in pagination. */
  edges: Array<PledgedMembersPublicsEdge>;
  /** A list of `PledgedMembersPublic` objects. */
  nodes: Array<PledgedMembersPublic>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PledgedMembersPublic` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PledgedMembersPublic` edge in the connection. */
export type PledgedMembersPublicsEdge = {
  __typename?: 'PledgedMembersPublicsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PledgedMembersPublic` at the end of the edge. */
  node: PledgedMembersPublic;
};

/** Methods to use when ordering `PledgedMembersPublic`. */
export enum PledgedMembersPublicsOrderBy {
  ActiveAsc = 'ACTIVE_ASC',
  ActiveDesc = 'ACTIVE_DESC',
  AnonymousAsc = 'ANONYMOUS_ASC',
  AnonymousDesc = 'ANONYMOUS_DESC',
  CommencementDateAsc = 'COMMENCEMENT_DATE_ASC',
  CommencementDateDesc = 'COMMENCEMENT_DATE_DESC',
  FullNameAsc = 'FULL_NAME_ASC',
  FullNameDesc = 'FULL_NAME_DESC',
  LatestPledgeEndDateAsc = 'LATEST_PLEDGE_END_DATE_ASC',
  LatestPledgeEndDateDesc = 'LATEST_PLEDGE_END_DATE_DESC',
  LatestPledgePercentageAsc = 'LATEST_PLEDGE_PERCENTAGE_ASC',
  LatestPledgePercentageDesc = 'LATEST_PLEDGE_PERCENTAGE_DESC',
  Natural = 'NATURAL',
  PledgeTypeAsc = 'PLEDGE_TYPE_ASC',
  PledgeTypeDesc = 'PLEDGE_TYPE_DESC',
  RankByPledgeTypeAsc = 'RANK_BY_PLEDGE_TYPE_ASC',
  RankByPledgeTypeDesc = 'RANK_BY_PLEDGE_TYPE_DESC'
}

/** A connection to a list of `Pledge` values. */
export type PledgesConnection = {
  __typename?: 'PledgesConnection';
  /** A list of edges which contains the `Pledge` and cursor to aid in pagination. */
  edges: Array<PledgesEdge>;
  /** A list of `Pledge` objects. */
  nodes: Array<Pledge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Pledge` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Pledge` edge in the connection. */
export type PledgesEdge = {
  __typename?: 'PledgesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Pledge` at the end of the edge. */
  node: Pledge;
};

export type PollOptionPublic = Node & {
  __typename?: 'PollOptionPublic';
  createdAt: Scalars['Datetime'];
  id: Scalars['BigInt'];
  label: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  pollId: Scalars['BigInt'];
  /** Reads a single `PollPublic` that is related to this `PollOptionPublic`. */
  pollPublicByPollId?: Maybe<PollPublic>;
  updatedAt: Scalars['Datetime'];
  voteCount: Scalars['Int'];
  /** Reads and enables pagination through a set of `PollOptionVote`. */
  votesByPersonOrTemporaryPerson: PollOptionVotesConnection;
  /** Reads and enables pagination through a set of `PollOptionVote`. */
  votesByPersonOrTemporaryPersonList?: Maybe<Array<PollOptionVote>>;
};


export type PollOptionPublicVotesByPersonOrTemporaryPersonArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
};


export type PollOptionPublicVotesByPersonOrTemporaryPersonListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
};

/** A connection to a list of `PollOptionPublic` values. */
export type PollOptionPublicsConnection = {
  __typename?: 'PollOptionPublicsConnection';
  /** A list of edges which contains the `PollOptionPublic` and cursor to aid in pagination. */
  edges: Array<PollOptionPublicsEdge>;
  /** A list of `PollOptionPublic` objects. */
  nodes: Array<PollOptionPublic>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PollOptionPublic` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PollOptionPublic` edge in the connection. */
export type PollOptionPublicsEdge = {
  __typename?: 'PollOptionPublicsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PollOptionPublic` at the end of the edge. */
  node: PollOptionPublic;
};

export type PollOptionVote = Node & {
  __typename?: 'PollOptionVote';
  context: Scalars['String'];
  createdAt: Scalars['Datetime'];
  metadata?: Maybe<Scalars['JSON']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Pledge` that is related to this `PollOptionVote`. */
  pledgeByPledgeId?: Maybe<Pledge>;
  pledgeId: Scalars['BigInt'];
  pollOptionId: Scalars['BigInt'];
  /** Reads a single `PollOptionPublic` that is related to this `PollOptionVote`. */
  pollOptionPublicByPollOptionId?: Maybe<PollOptionPublic>;
  updatedAt: Scalars['Datetime'];
};

/** A connection to a list of `PollOptionVote` values. */
export type PollOptionVotesConnection = {
  __typename?: 'PollOptionVotesConnection';
  /** A list of edges which contains the `PollOptionVote` and cursor to aid in pagination. */
  edges: Array<PollOptionVotesEdge>;
  /** A list of `PollOptionVote` objects. */
  nodes: Array<PollOptionVote>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PollOptionVote` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PollOptionVote` edge in the connection. */
export type PollOptionVotesEdge = {
  __typename?: 'PollOptionVotesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PollOptionVote` at the end of the edge. */
  node: PollOptionVote;
};

export type PollPublic = Node & {
  __typename?: 'PollPublic';
  createdAt: Scalars['Datetime'];
  id: Scalars['BigInt'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `PollOptionPublic`. */
  pollOptionPublicsByPollId: PollOptionPublicsConnection;
  /** Reads and enables pagination through a set of `PollOptionPublic`. */
  pollOptionPublicsByPollIdList: Array<PollOptionPublic>;
  question: Scalars['String'];
  questionOrdering: QuestionOrdering;
  updatedAt: Scalars['Datetime'];
  voteMechanism: VoteMechanism;
};


export type PollPublicPollOptionPublicsByPollIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type PollPublicPollOptionPublicsByPollIdListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `ProgramDetailsLegacy` values. */
export type ProgramDetailsLegaciesConnection = {
  __typename?: 'ProgramDetailsLegaciesConnection';
  /** A list of edges which contains the `ProgramDetailsLegacy` and cursor to aid in pagination. */
  edges: Array<ProgramDetailsLegaciesEdge>;
  /** A list of `ProgramDetailsLegacy` objects. */
  nodes: Array<ProgramDetailsLegacy>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProgramDetailsLegacy` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ProgramDetailsLegacy` edge in the connection. */
export type ProgramDetailsLegaciesEdge = {
  __typename?: 'ProgramDetailsLegaciesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ProgramDetailsLegacy` at the end of the edge. */
  node: ProgramDetailsLegacy;
};

export type ProgramDetailsLegacy = {
  __typename?: 'ProgramDetailsLegacy';
  active?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  hidden?: Maybe<Scalars['Boolean']>;
  href?: Maybe<Scalars['String']>;
  isDataSharingPartner?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  program: Scalars['String'];
  slug: Scalars['String'];
  type?: Maybe<ProgramType>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export enum ProgramType {
  Fund = 'FUND',
  Meta = 'META',
  NonProfit = 'NON_PROFIT'
}

/** The root query type which gives access points into the data universe. */
export type Query = Node & {
  __typename?: 'Query';
  /** Reads and enables pagination through a set of `ActiveRecipientProgram`. */
  allActiveRecipientPrograms?: Maybe<ActiveRecipientProgramsConnection>;
  /** Reads a set of `ActiveRecipientProgram`. */
  allActiveRecipientProgramsList?: Maybe<Array<ActiveRecipientProgram>>;
  /** Reads and enables pagination through a set of `CompletionPayment`. */
  allCompletionPayments?: Maybe<CompletionPaymentsConnection>;
  /** Reads a set of `CompletionPayment`. */
  allCompletionPaymentsList?: Maybe<Array<CompletionPayment>>;
  /** Reads and enables pagination through a set of `Country`. */
  allCountries?: Maybe<CountriesConnection>;
  /** Reads a set of `Country`. */
  allCountriesList?: Maybe<Array<Country>>;
  /** Reads and enables pagination through a set of `Currency`. */
  allCurrencies?: Maybe<CurrenciesConnection>;
  /** Reads a set of `Currency`. */
  allCurrenciesList?: Maybe<Array<Currency>>;
  /** Reads and enables pagination through a set of `GlobalStat`. */
  allGlobalStats?: Maybe<GlobalStatsConnection>;
  /** Reads a set of `GlobalStat`. */
  allGlobalStatsList?: Maybe<Array<GlobalStat>>;
  /** Reads and enables pagination through a set of `Liability`. */
  allLiabilities?: Maybe<LiabilitiesConnection>;
  /** Reads a set of `Liability`. */
  allLiabilitiesList?: Maybe<Array<Liability>>;
  /** Reads and enables pagination through a set of `LotterySummary`. */
  allLotterySummaries?: Maybe<LotterySummariesConnection>;
  /** Reads a set of `LotterySummary`. */
  allLotterySummariesList?: Maybe<Array<LotterySummary>>;
  /** Reads and enables pagination through a set of `PledgeClubMember`. */
  allPledgeClubMembers?: Maybe<PledgeClubMembersConnection>;
  /** Reads a set of `PledgeClubMember`. */
  allPledgeClubMembersList?: Maybe<Array<PledgeClubMember>>;
  /** Reads and enables pagination through a set of `PledgeClub`. */
  allPledgeClubs?: Maybe<PledgeClubsConnection>;
  /** Reads a set of `PledgeClub`. */
  allPledgeClubsList?: Maybe<Array<PledgeClub>>;
  /** Reads and enables pagination through a set of `PledgeRank`. */
  allPledgeRanks?: Maybe<PledgeRanksConnection>;
  /** Reads a set of `PledgeRank`. */
  allPledgeRanksList?: Maybe<Array<PledgeRank>>;
  /** Reads and enables pagination through a set of `PledgedMembersPublic`. */
  allPledgedMembersPublics?: Maybe<PledgedMembersPublicsConnection>;
  /** Reads a set of `PledgedMembersPublic`. */
  allPledgedMembersPublicsList?: Maybe<Array<PledgedMembersPublic>>;
  /** Reads and enables pagination through a set of `ProgramDetailsLegacy`. */
  allProgramDetailsLegacies?: Maybe<ProgramDetailsLegaciesConnection>;
  /** Reads a set of `ProgramDetailsLegacy`. */
  allProgramDetailsLegaciesList?: Maybe<Array<ProgramDetailsLegacy>>;
  arraySuffix?: Maybe<Array<Maybe<Scalars['String']>>>;
  closestExchangeRate?: Maybe<ExchangeRate>;
  /** Reads a single `ContactPreference` using its globally unique `ID`. */
  contactPreference?: Maybe<ContactPreference>;
  contactPreferenceByPersonId?: Maybe<ContactPreference>;
  convertCurrency?: Maybe<Scalars['BigFloat']>;
  /** Reads a single `Country` using its globally unique `ID`. */
  country?: Maybe<Country>;
  countryByIsoAlpha2?: Maybe<Country>;
  /** Reads a single `Currency` using its globally unique `ID`. */
  currency?: Maybe<Currency>;
  currencyByCode?: Maybe<Currency>;
  /** Reads a single `CurrencyByCountry` using its globally unique `ID`. */
  currencyByCountry?: Maybe<CurrencyByCountry>;
  currencyByCountryByCountryCodeAndCurrencyCode?: Maybe<CurrencyByCountry>;
  currentPerson?: Maybe<Person>;
  decodeHashid?: Maybe<Scalars['BigInt']>;
  encodeHashid?: Maybe<Scalars['String']>;
  extendedLotteryById?: Maybe<ExtendedLottery>;
  /** Reads a single `ExternalOrganization` using its globally unique `ID`. */
  externalOrganization?: Maybe<ExternalOrganization>;
  externalOrganizationById?: Maybe<ExternalOrganization>;
  externalOrganizationByName?: Maybe<ExternalOrganization>;
  externalOrganizationByOrganizationSlug?: Maybe<ExternalOrganization>;
  /** Reads and enables pagination through a set of `ExternalOrganization`. */
  externalOrganizationTypeahead?: Maybe<ExternalOrganizationsConnection>;
  /** Reads and enables pagination through a set of `ExternalOrganization`. */
  externalOrganizationTypeaheadList?: Maybe<Array<ExternalOrganization>>;
  /** Reads a single `FinancialPeriod` using its globally unique `ID`. */
  financialPeriod?: Maybe<FinancialPeriod>;
  financialPeriodById?: Maybe<FinancialPeriod>;
  financialPeriodByPersonIdAndPeriodAndCurrencyCode?: Maybe<FinancialPeriod>;
  /** Reads and enables pagination through a set of `Person`. */
  findPersonByNameOrEmail?: Maybe<PeopleConnection>;
  /** Reads and enables pagination through a set of `Person`. */
  findPersonByNameOrEmailList?: Maybe<Array<Person>>;
  /** Reads and enables pagination through a set of `Address`. */
  getAddressesByPersonOrTemporaryPerson?: Maybe<AddressesConnection>;
  /** Reads and enables pagination through a set of `Address`. */
  getAddressesByPersonOrTemporaryPersonList?: Maybe<Array<Address>>;
  /** Reads and enables pagination through a set of `FinancialPeriod`. */
  getFinancialPeriodsByPersonId?: Maybe<FinancialPeriodsConnection>;
  /** Reads and enables pagination through a set of `FinancialPeriod`. */
  getFinancialPeriodsByPersonIdList?: Maybe<Array<FinancialPeriod>>;
  /** Reads and enables pagination through a set of `FundraiserStat`. */
  getFundraiserStats?: Maybe<FundraiserStatsConnection>;
  /** Reads and enables pagination through a set of `FundraiserStat`. */
  getFundraiserStatsList?: Maybe<Array<FundraiserStat>>;
  /** Reads and enables pagination through a set of `GocardlessMandate`. */
  getGocardlessMandatesByPersonOrTemporaryPerson?: Maybe<GocardlessMandatesConnection>;
  /** Reads and enables pagination through a set of `GocardlessMandate`. */
  getGocardlessMandatesByPersonOrTemporaryPersonList?: Maybe<Array<GocardlessMandate>>;
  getGocardlessRedirectFlowByPersonOrTemporaryPerson?: Maybe<GocardlessRedirectFlow>;
  getImpliedExchangeRate?: Maybe<Scalars['BigFloat']>;
  /** Reads and enables pagination through a set of `Income`. */
  getIncomesByPersonId?: Maybe<IncomesConnection>;
  /** Reads and enables pagination through a set of `Income`. */
  getIncomesByPersonIdList?: Maybe<Array<Income>>;
  /** Reads and enables pagination through a set of `LiabilityCompletionT`. */
  getLiabilityCompletionByPledge?: Maybe<LiabilityCompletionTsConnection>;
  /** Reads and enables pagination through a set of `LiabilityCompletionT`. */
  getLiabilityCompletionByPledgeList?: Maybe<Array<LiabilityCompletionT>>;
  /** Reads and enables pagination through a set of `LiabilityTotalCompletionT`. */
  getLiabilityTotalCompletion?: Maybe<LiabilityTotalCompletionTsConnection>;
  /** Reads and enables pagination through a set of `LiabilityTotalCompletionT`. */
  getLiabilityTotalCompletionList?: Maybe<Array<LiabilityTotalCompletionT>>;
  /** Reads and enables pagination through a set of `LotteryEntryPublic`. */
  getLotteryEntriesByLotteryId?: Maybe<LotteryEntryPublicsConnection>;
  /** Reads and enables pagination through a set of `LotteryEntryPublic`. */
  getLotteryEntriesByLotteryIdList?: Maybe<Array<LotteryEntryPublic>>;
  /** Reads and enables pagination through a set of `LotteryEntryComplete`. */
  getLotteryEntriesCompleteByLotteryId?: Maybe<LotteryEntryCompletesConnection>;
  /** Reads and enables pagination through a set of `LotteryEntryComplete`. */
  getLotteryEntriesCompleteByLotteryIdList?: Maybe<Array<LotteryEntryComplete>>;
  getLotterySummaryByLotteryId?: Maybe<LotterySummary>;
  getPaymentByPersonOrTemporaryPerson?: Maybe<Payment>;
  getPaymentStatsAggregate?: Maybe<PaymentStatsAggregate>;
  getPledgeByPersonOrTemporaryPerson?: Maybe<GetPledgeByPersonOrTemporaryPersonT>;
  getPledgeByTemporaryPerson?: Maybe<Pledge>;
  /**
   * Retrieves non-anonymous, email-verified pledges where the motivation has been approved and has sharing consent.
   * Returns pledge information along with the person's full name.
   * Parameters:
   *   result_limit - Maximum number of pledges to return (optional, NULL returns all matching records)
   * Returns:
   *   Set of pledges with person full name ordered by creation date (newest first)
   */
  getPledgesByApprovedMotivation?: Maybe<GetPledgesByApprovedMotivationConnection>;
  /**
   * Retrieves non-anonymous, email-verified pledges where the motivation has been approved and has sharing consent.
   * Returns pledge information along with the person's full name.
   * Parameters:
   *   result_limit - Maximum number of pledges to return (optional, NULL returns all matching records)
   * Returns:
   *   Set of pledges with person full name ordered by creation date (newest first)
   */
  getPledgesByApprovedMotivationList?: Maybe<Array<Maybe<GetPledgesByApprovedMotivationRecord>>>;
  /** Reads and enables pagination through a set of `Pledge`. */
  getPledgesByPersonId?: Maybe<PledgesConnection>;
  /** Reads and enables pagination through a set of `Pledge`. */
  getPledgesByPersonIdList?: Maybe<Array<Pledge>>;
  getRecurringPaymentByPersonOrTemporaryPerson?: Maybe<RecurringPayment>;
  /** Reads and enables pagination through a set of `RecurringReportedDonation`. */
  getRecurringReportedDonationsByPersonId?: Maybe<RecurringReportedDonationsConnection>;
  /** Reads and enables pagination through a set of `RecurringReportedDonation`. */
  getRecurringReportedDonationsByPersonIdList?: Maybe<Array<RecurringReportedDonation>>;
  getTotalPledgeSignups?: Maybe<Scalars['Int']>;
  /** Reads a single `GiftAidClaim` using its globally unique `ID`. */
  giftAidClaim?: Maybe<GiftAidClaim>;
  giftAidClaimByGiftAidPaymentId?: Maybe<GiftAidClaim>;
  giftAidClaimByPaymentId?: Maybe<GiftAidClaim>;
  hashidsdefaultalphabet?: Maybe<Scalars['String']>;
  hex2Bigint?: Maybe<Scalars['BigInt']>;
  /** Reads a single `Income` using its globally unique `ID`. */
  income?: Maybe<Income>;
  incomeById?: Maybe<Income>;
  isValidJson?: Maybe<Scalars['Boolean']>;
  /** Reads a single `Liability` using its globally unique `ID`. */
  liability?: Maybe<Liability>;
  liabilityByPledgeIdAndFinancialPeriodId?: Maybe<Liability>;
  /** Fetches an object given its globally unique `ID`. */
  node?: Maybe<Node>;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  nodeId: Scalars['ID'];
  normalizeAmountToUsd?: Maybe<Scalars['BigFloat']>;
  /** Reads a single `Payment` using its globally unique `ID`. */
  payment?: Maybe<Payment>;
  paymentByGatewayAndGatewayTransactionId?: Maybe<Payment>;
  paymentById?: Maybe<Payment>;
  paymentByIdAndPersonId?: Maybe<Payment>;
  paymentByReference?: Maybe<Payment>;
  /** Reads and enables pagination through a set of `Payment`. */
  paymentsByPersonId?: Maybe<PaymentsConnection>;
  /** Reads and enables pagination through a set of `Payment`. */
  paymentsByPersonIdList?: Maybe<Array<Payment>>;
  /** Reads a single `Person` using its globally unique `ID`. */
  person?: Maybe<Person>;
  personByAuth0UserId?: Maybe<Person>;
  personByEmail?: Maybe<Person>;
  personById?: Maybe<Person>;
  /** Reads a single `Pledge` using its globally unique `ID`. */
  pledge?: Maybe<Pledge>;
  pledgeById?: Maybe<Pledge>;
  /** Reads a single `PledgeClub` using its globally unique `ID`. */
  pledgeClub?: Maybe<PledgeClub>;
  pledgeClubById?: Maybe<PledgeClub>;
  pledgeClubBySlug?: Maybe<PledgeClub>;
  /** Reads a single `PledgeClubMember` using its globally unique `ID`. */
  pledgeClubMember?: Maybe<PledgeClubMember>;
  pledgeClubMemberById?: Maybe<PledgeClubMember>;
  /** Reads a single `PledgeRank` using its globally unique `ID`. */
  pledgeRank?: Maybe<PledgeRank>;
  pledgeRankByPersonIdAndPledgeType?: Maybe<PledgeRank>;
  /** Reads a single `PollOptionPublic` using its globally unique `ID`. */
  pollOptionPublic?: Maybe<PollOptionPublic>;
  pollOptionPublicById?: Maybe<PollOptionPublic>;
  /** Reads and enables pagination through a set of `PollOptionVote`. */
  pollOptionPublicVotesByTemporaryPerson?: Maybe<PollOptionVotesConnection>;
  /** Reads and enables pagination through a set of `PollOptionVote`. */
  pollOptionPublicVotesByTemporaryPersonList?: Maybe<Array<PollOptionVote>>;
  /** Reads a single `PollPublic` using its globally unique `ID`. */
  pollPublic?: Maybe<PollPublic>;
  pollPublicById?: Maybe<PollPublic>;
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
  /** Reads a single `RecurringPayment` using its globally unique `ID`. */
  recurringPayment?: Maybe<RecurringPayment>;
  recurringPaymentById?: Maybe<RecurringPayment>;
  /** Reads and enables pagination through a set of `RecurringPayment`. */
  recurringPaymentsByPersonId?: Maybe<RecurringPaymentsConnection>;
  /** Reads and enables pagination through a set of `RecurringPayment`. */
  recurringPaymentsByPersonIdList?: Maybe<Array<RecurringPayment>>;
  /** Reads a single `RecurringReportedDonation` using its globally unique `ID`. */
  recurringReportedDonation?: Maybe<RecurringReportedDonation>;
  recurringReportedDonationById?: Maybe<RecurringReportedDonation>;
  /** Reads and enables pagination through a set of `ReportedDonation`. */
  reportedDonationsByPersonId?: Maybe<ReportedDonationsConnection>;
  /** Reads and enables pagination through a set of `ReportedDonation`. */
  reportedDonationsByPersonIdList?: Maybe<Array<ReportedDonation>>;
  sendReceipt?: Maybe<Scalars['String']>;
  slugify?: Maybe<Scalars['String']>;
  validHref?: Maybe<Scalars['Boolean']>;
  validRelativeUrl?: Maybe<Scalars['Boolean']>;
  validUrl?: Maybe<Scalars['Boolean']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllActiveRecipientProgramsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllActiveRecipientProgramsListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllCompletionPaymentsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CompletionPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CompletionPaymentsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllCompletionPaymentsListArgs = {
  condition?: InputMaybe<CompletionPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CompletionPaymentsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllCountriesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CountriesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllCountriesListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CountriesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllCurrenciesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CurrencyCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrenciesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllCurrenciesListArgs = {
  condition?: InputMaybe<CurrencyCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrenciesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllGlobalStatsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllGlobalStatsListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLiabilitiesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LiabilityCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLiabilitiesListArgs = {
  condition?: InputMaybe<LiabilityCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLotterySummariesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LotterySummariesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLotterySummariesListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LotterySummariesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPledgeClubMembersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PledgeClubMemberCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PledgeClubMembersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPledgeClubMembersListArgs = {
  condition?: InputMaybe<PledgeClubMemberCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PledgeClubMembersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPledgeClubsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PledgeClubCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PledgeClubsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPledgeClubsListArgs = {
  condition?: InputMaybe<PledgeClubCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PledgeClubsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPledgeRanksArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PledgeRankCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PledgeRanksOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPledgeRanksListArgs = {
  condition?: InputMaybe<PledgeRankCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PledgeRanksOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPledgedMembersPublicsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PledgedMembersPublicCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PledgedMembersPublicsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPledgedMembersPublicsListArgs = {
  condition?: InputMaybe<PledgedMembersPublicCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PledgedMembersPublicsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllProgramDetailsLegaciesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllProgramDetailsLegaciesListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryArraySuffixArgs = {
  arr?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  suffix?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryClosestExchangeRateArgs = {
  currencyCode?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['Date']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryContactPreferenceArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryContactPreferenceByPersonIdArgs = {
  personId: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryConvertCurrencyArgs = {
  amount?: InputMaybe<Scalars['BigFloat']>;
  exchangeRateDate?: InputMaybe<Scalars['Date']>;
  fromCurrencyCode?: InputMaybe<Scalars['String']>;
  toCurrencyCode?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCountryArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCountryByIsoAlpha2Args = {
  isoAlpha2: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCurrencyArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCurrencyByCodeArgs = {
  code: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCurrencyByCountryArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCurrencyByCountryByCountryCodeAndCurrencyCodeArgs = {
  countryCode: Scalars['String'];
  currencyCode: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDecodeHashidArgs = {
  hashid: Scalars['String'];
  slug: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEncodeHashidArgs = {
  key: Scalars['BigInt'];
  slug: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryExtendedLotteryByIdArgs = {
  id?: InputMaybe<Scalars['BigInt']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryExternalOrganizationArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryExternalOrganizationByIdArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryExternalOrganizationByNameArgs = {
  name: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryExternalOrganizationByOrganizationSlugArgs = {
  organizationSlug: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryExternalOrganizationTypeaheadArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryExternalOrganizationTypeaheadListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFinancialPeriodArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFinancialPeriodByIdArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFinancialPeriodByPersonIdAndPeriodAndCurrencyCodeArgs = {
  currencyCode: Scalars['String'];
  period: DateRangeInput;
  personId: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFindPersonByNameOrEmailArgs = {
  _limit?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFindPersonByNameOrEmailListArgs = {
  _limit?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetAddressesByPersonOrTemporaryPersonArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  personId?: InputMaybe<Scalars['BigInt']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetAddressesByPersonOrTemporaryPersonListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  personId?: InputMaybe<Scalars['BigInt']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetFinancialPeriodsByPersonIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  personId?: InputMaybe<Scalars['BigInt']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetFinancialPeriodsByPersonIdListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  personId?: InputMaybe<Scalars['BigInt']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetFundraiserStatsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  fundraiserId?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetFundraiserStatsListArgs = {
  currencyCode?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  fundraiserId?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetGocardlessMandatesByPersonOrTemporaryPersonArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  personId?: InputMaybe<Scalars['BigInt']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetGocardlessMandatesByPersonOrTemporaryPersonListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  personId?: InputMaybe<Scalars['BigInt']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetGocardlessRedirectFlowByPersonOrTemporaryPersonArgs = {
  personId?: InputMaybe<Scalars['BigInt']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetImpliedExchangeRateArgs = {
  exchangeRateDate?: InputMaybe<Scalars['Date']>;
  fromCurrencyCode?: InputMaybe<Scalars['String']>;
  toCurrencyCode?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetIncomesByPersonIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  personId?: InputMaybe<Scalars['BigInt']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetIncomesByPersonIdListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  personId?: InputMaybe<Scalars['BigInt']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetLiabilityCompletionByPledgeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  pledgeId?: InputMaybe<Scalars['BigInt']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetLiabilityCompletionByPledgeListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  pledgeId?: InputMaybe<Scalars['BigInt']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetLiabilityTotalCompletionArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  pledgeId?: InputMaybe<Scalars['BigInt']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetLiabilityTotalCompletionListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  pledgeId?: InputMaybe<Scalars['BigInt']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetLotteryEntriesByLotteryIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  lotteryId?: InputMaybe<Scalars['BigInt']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetLotteryEntriesByLotteryIdListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  lotteryId?: InputMaybe<Scalars['BigInt']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetLotteryEntriesCompleteByLotteryIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  lotteryId?: InputMaybe<Scalars['BigInt']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetLotteryEntriesCompleteByLotteryIdListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  lotteryId?: InputMaybe<Scalars['BigInt']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetLotterySummaryByLotteryIdArgs = {
  lotteryId?: InputMaybe<Scalars['BigInt']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetPaymentByPersonOrTemporaryPersonArgs = {
  paymentId?: InputMaybe<Scalars['BigInt']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetPledgeByPersonOrTemporaryPersonArgs = {
  pledgeId?: InputMaybe<Scalars['BigInt']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetPledgeByTemporaryPersonArgs = {
  sessionToken: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetPledgesByApprovedMotivationArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  resultLimit?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetPledgesByApprovedMotivationListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  resultLimit?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetPledgesByPersonIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  personId?: InputMaybe<Scalars['BigInt']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetPledgesByPersonIdListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  personId?: InputMaybe<Scalars['BigInt']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetRecurringPaymentByPersonOrTemporaryPersonArgs = {
  recurringPaymentId?: InputMaybe<Scalars['BigInt']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetRecurringReportedDonationsByPersonIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  personId?: InputMaybe<Scalars['BigInt']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetRecurringReportedDonationsByPersonIdListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  personId?: InputMaybe<Scalars['BigInt']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetTotalPledgeSignupsArgs = {
  excludeTrialPledges?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Date']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGiftAidClaimArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGiftAidClaimByGiftAidPaymentIdArgs = {
  giftAidPaymentId: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGiftAidClaimByPaymentIdArgs = {
  paymentId: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryHex2BigintArgs = {
  hex?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryIncomeArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIncomeByIdArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIsValidJsonArgs = {
  pJson: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLiabilityArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLiabilityByPledgeIdAndFinancialPeriodIdArgs = {
  financialPeriodId: Scalars['BigInt'];
  pledgeId: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNodeArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNormalizeAmountToUsdArgs = {
  amount?: InputMaybe<Scalars['BigFloat']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  exchangeRateDate?: InputMaybe<Scalars['Date']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryPaymentArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPaymentByGatewayAndGatewayTransactionIdArgs = {
  gateway: PaymentGateway;
  gatewayTransactionId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPaymentByIdArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPaymentByIdAndPersonIdArgs = {
  id: Scalars['BigInt'];
  personId: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPaymentByReferenceArgs = {
  reference: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPaymentsByPersonIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  personId: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPaymentsByPersonIdListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  personId: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPersonArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPersonByAuth0UserIdArgs = {
  auth0UserId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPersonByEmailArgs = {
  email: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPersonByIdArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPledgeArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPledgeByIdArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPledgeClubArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPledgeClubByIdArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPledgeClubBySlugArgs = {
  slug: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPledgeClubMemberArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPledgeClubMemberByIdArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPledgeRankArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPledgeRankByPersonIdAndPledgeTypeArgs = {
  personId: Scalars['BigInt'];
  pledgeType: PledgeType;
};


/** The root query type which gives access points into the data universe. */
export type QueryPollOptionPublicArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPollOptionPublicByIdArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPollOptionPublicVotesByTemporaryPersonArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sessionToken: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPollOptionPublicVotesByTemporaryPersonListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sessionToken: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPollPublicArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPollPublicByIdArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryRecurringPaymentArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryRecurringPaymentByIdArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryRecurringPaymentsByPersonIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  personId: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryRecurringPaymentsByPersonIdListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  personId: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryRecurringReportedDonationArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryRecurringReportedDonationByIdArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReportedDonationsByPersonIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  personId?: InputMaybe<Scalars['BigInt']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryReportedDonationsByPersonIdListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  personId?: InputMaybe<Scalars['BigInt']>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySendReceiptArgs = {
  paymentId?: InputMaybe<Scalars['BigInt']>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySlugifyArgs = {
  value: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryValidHrefArgs = {
  href: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryValidRelativeUrlArgs = {
  url: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryValidUrlArgs = {
  url: Scalars['String'];
};

export enum QuestionOrdering {
  Random = 'RANDOM'
}

/** All input for the `recordSurveyResponsesByTemporaryPerson` mutation. */
export type RecordSurveyResponsesByTemporaryPersonInput = {
  answerText: Array<InputMaybe<Scalars['String']>>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  itemId: Scalars['BigInt'];
  metadata?: InputMaybe<Scalars['JSON']>;
  questionKey: Array<InputMaybe<Scalars['String']>>;
  sessionToken: Scalars['UUID'];
};

/** The output of our `recordSurveyResponsesByTemporaryPerson` mutation. */
export type RecordSurveyResponsesByTemporaryPersonPayload = {
  __typename?: 'RecordSurveyResponsesByTemporaryPersonPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `recordSurveyResponses` mutation. */
export type RecordSurveyResponsesInput = {
  answerText: Array<InputMaybe<Scalars['String']>>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  itemId: Scalars['BigInt'];
  metadata?: InputMaybe<Scalars['JSON']>;
  personId: Scalars['BigInt'];
  questionKey: Array<InputMaybe<Scalars['String']>>;
};

/** The output of our `recordSurveyResponses` mutation. */
export type RecordSurveyResponsesPayload = {
  __typename?: 'RecordSurveyResponsesPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export enum RecurrencePeriod {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR'
}

export type RecurringPayment = Node & {
  __typename?: 'RecurringPayment';
  allocation: Scalars['JSON'];
  amount: Scalars['BigFloat'];
  billingAddressId?: Maybe<Scalars['BigInt']>;
  createdAt: Scalars['Datetime'];
  /** Reads a single `Currency` that is related to this `RecurringPayment`. */
  currencyByCurrencyCode?: Maybe<Currency>;
  currencyCode: Scalars['String'];
  gateway: PaymentGateway;
  gatewayData?: Maybe<Scalars['JSON']>;
  id: Scalars['BigInt'];
  metadata?: Maybe<Scalars['JSON']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  paymentMethod?: Maybe<PaymentMethod>;
  period: RecurrencePeriod;
  periodIndex: Scalars['Int'];
  periodInterval: Scalars['Int'];
  /** Reads a single `Person` that is related to this `RecurringPayment`. */
  personByPersonId?: Maybe<Person>;
  personId?: Maybe<Scalars['BigInt']>;
  recipient: Scalars['String'];
  status: RecurringPaymentStatus;
  statusData?: Maybe<Scalars['JSON']>;
  updatedAt: Scalars['Datetime'];
};

export enum RecurringPaymentStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Failed = 'FAILED',
  Incomplete = 'INCOMPLETE',
  Pending = 'PENDING',
  Updating = 'UPDATING'
}

/** A connection to a list of `RecurringPayment` values. */
export type RecurringPaymentsConnection = {
  __typename?: 'RecurringPaymentsConnection';
  /** A list of edges which contains the `RecurringPayment` and cursor to aid in pagination. */
  edges: Array<RecurringPaymentsEdge>;
  /** A list of `RecurringPayment` objects. */
  nodes: Array<RecurringPayment>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `RecurringPayment` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `RecurringPayment` edge in the connection. */
export type RecurringPaymentsEdge = {
  __typename?: 'RecurringPaymentsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `RecurringPayment` at the end of the edge. */
  node: RecurringPayment;
};

export type RecurringReportedDonation = Node & {
  __typename?: 'RecurringReportedDonation';
  amount: Scalars['BigFloat'];
  createdAt: Scalars['Datetime'];
  /** Reads a single `Currency` that is related to this `RecurringReportedDonation`. */
  currencyByCurrencyCode?: Maybe<Currency>;
  currencyCode: Scalars['String'];
  /** Reads a single `ExternalOrganization` that is related to this `RecurringReportedDonation`. */
  externalOrganizationByOrganizationId?: Maybe<ExternalOrganization>;
  id: Scalars['BigInt'];
  nextDate: Scalars['Date'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  organizationId: Scalars['BigInt'];
  /** Reads a single `Person` that is related to this `RecurringReportedDonation`. */
  personByPersonId?: Maybe<Person>;
  personId: Scalars['BigInt'];
  recurrenceInterval: Interval;
  startDate: Scalars['Date'];
  status: RecurringPaymentStatus;
  updatedAt: Scalars['Datetime'];
};

/** A connection to a list of `RecurringReportedDonation` values. */
export type RecurringReportedDonationsConnection = {
  __typename?: 'RecurringReportedDonationsConnection';
  /** A list of edges which contains the `RecurringReportedDonation` and cursor to aid in pagination. */
  edges: Array<RecurringReportedDonationsEdge>;
  /** A list of `RecurringReportedDonation` objects. */
  nodes: Array<RecurringReportedDonation>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `RecurringReportedDonation` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `RecurringReportedDonation` edge in the connection. */
export type RecurringReportedDonationsEdge = {
  __typename?: 'RecurringReportedDonationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `RecurringReportedDonation` at the end of the edge. */
  node: RecurringReportedDonation;
};

export type Reminder = Node & {
  __typename?: 'Reminder';
  createdAt: Scalars['Datetime'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['BigInt'];
  newsletterSignup: Scalars['Boolean'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  remindedAt?: Maybe<Scalars['Datetime']>;
  reminderDate: Scalars['Date'];
  reminderSourcePath?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Datetime'];
};

export type ReportedDonation = Node & {
  __typename?: 'ReportedDonation';
  amount?: Maybe<Scalars['BigFloat']>;
  amountNormalized?: Maybe<Scalars['BigFloat']>;
  /** Reads and enables pagination through a set of `CompletionPayment`. */
  completionPaymentsByReportedDonationId: CompletionPaymentsConnection;
  /** Reads and enables pagination through a set of `CompletionPayment`. */
  completionPaymentsByReportedDonationIdList: Array<CompletionPayment>;
  createdAt: Scalars['Datetime'];
  /** Reads a single `Currency` that is related to this `ReportedDonation`. */
  currencyByCurrencyCode?: Maybe<Currency>;
  currencyCode?: Maybe<Scalars['String']>;
  donationDate?: Maybe<Scalars['Date']>;
  /** Reads a single `ExternalOrganization` that is related to this `ReportedDonation`. */
  externalOrganizationByOrganizationId?: Maybe<ExternalOrganization>;
  id: Scalars['BigInt'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  organizationId: Scalars['BigInt'];
  /** Reads a single `Person` that is related to this `ReportedDonation`. */
  personByPersonId?: Maybe<Person>;
  personId: Scalars['BigInt'];
  recurrence: PaymentRecurrence;
  updatedAt: Scalars['Datetime'];
};


export type ReportedDonationCompletionPaymentsByReportedDonationIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CompletionPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CompletionPaymentsOrderBy>>;
};


export type ReportedDonationCompletionPaymentsByReportedDonationIdListArgs = {
  condition?: InputMaybe<CompletionPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CompletionPaymentsOrderBy>>;
};

/** A connection to a list of `ReportedDonation` values. */
export type ReportedDonationsConnection = {
  __typename?: 'ReportedDonationsConnection';
  /** A list of edges which contains the `ReportedDonation` and cursor to aid in pagination. */
  edges: Array<ReportedDonationsEdge>;
  /** A list of `ReportedDonation` objects. */
  nodes: Array<ReportedDonation>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ReportedDonation` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ReportedDonation` edge in the connection. */
export type ReportedDonationsEdge = {
  __typename?: 'ReportedDonationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ReportedDonation` at the end of the edge. */
  node: ReportedDonation;
};

/** All input for the `signPledgeByTemporaryPerson` mutation. */
export type SignPledgeByTemporaryPersonInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  employmentStatus?: InputMaybe<IncomeEmploymentStatus>;
  incomeAmount?: InputMaybe<Scalars['BigFloat']>;
  incomePercentage?: InputMaybe<Scalars['BigFloat']>;
  locale?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  motivation?: InputMaybe<Scalars['String']>;
  personBirthDate?: InputMaybe<Scalars['Date']>;
  personFirstName?: InputMaybe<Scalars['String']>;
  personLastName?: InputMaybe<Scalars['String']>;
  pledgePeriod?: InputMaybe<DateRangeInput>;
  pledgeType?: InputMaybe<PledgeType>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
  wealthAmount?: InputMaybe<Scalars['BigFloat']>;
  wealthPercentage?: InputMaybe<Scalars['BigFloat']>;
};

/** The output of our `signPledgeByTemporaryPerson` mutation. */
export type SignPledgeByTemporaryPersonPayload = {
  __typename?: 'SignPledgeByTemporaryPersonPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Person` that is related to this `Pledge`. */
  personByPersonId?: Maybe<Person>;
  pledge?: Maybe<Pledge>;
  /** An edge for our `Pledge`. May be used by Relay 1. */
  pledgeEdge?: Maybe<PledgesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `signPledge` mutation. */
export type SignPledgeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  employmentStatus?: InputMaybe<IncomeEmploymentStatus>;
  incomeAmount?: InputMaybe<Scalars['BigFloat']>;
  incomePercentage?: InputMaybe<Scalars['BigFloat']>;
  locale?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  motivation?: InputMaybe<Scalars['String']>;
  personBirthDate?: InputMaybe<Scalars['Date']>;
  personFirstName?: InputMaybe<Scalars['String']>;
  personId?: InputMaybe<Scalars['BigInt']>;
  personLastName?: InputMaybe<Scalars['String']>;
  pledgePeriod?: InputMaybe<DateRangeInput>;
  pledgeType?: InputMaybe<PledgeType>;
  wealthAmount?: InputMaybe<Scalars['BigFloat']>;
  wealthPercentage?: InputMaybe<Scalars['BigFloat']>;
};

/** The output of our `signPledge` mutation. */
export type SignPledgePayload = {
  __typename?: 'SignPledgePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Person` that is related to this `Pledge`. */
  personByPersonId?: Maybe<Person>;
  pledge?: Maybe<Pledge>;
  /** An edge for our `Pledge`. May be used by Relay 1. */
  pledgeEdge?: Maybe<PledgesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `syncAuth0User` mutation. */
export type SyncAuth0UserInput = {
  auth0User?: InputMaybe<Scalars['JSON']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our `syncAuth0User` mutation. */
export type SyncAuth0UserPayload = {
  __typename?: 'SyncAuth0UserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  person?: Maybe<Person>;
  /** An edge for our `Person`. May be used by Relay 1. */
  personEdge?: Maybe<PeopleEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type TemporaryPerson = Node & {
  __typename?: 'TemporaryPerson';
  email: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  sessionToken: Scalars['UUID'];
  tokenExpiresAt: Scalars['Datetime'];
  tokenIssuedAt: Scalars['Datetime'];
};

/** All input for the `updateContactPreferenceByPersonId` mutation. */
export type UpdateContactPreferenceByPersonIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  currentAddressId?: InputMaybe<Scalars['BigInt']>;
  personId?: InputMaybe<Scalars['BigInt']>;
};

/** The output of our `updateContactPreferenceByPersonId` mutation. */
export type UpdateContactPreferenceByPersonIdPayload = {
  __typename?: 'UpdateContactPreferenceByPersonIdPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  contactPreference?: Maybe<ContactPreference>;
  /** An edge for our `ContactPreference`. May be used by Relay 1. */
  contactPreferenceEdge?: Maybe<ContactPreferencesEdge>;
  /** Reads a single `Person` that is related to this `ContactPreference`. */
  personByPersonId?: Maybe<Person>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateFinancialPeriod` mutation. */
export type UpdateFinancialPeriodInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  donationsTaxDeductible?: InputMaybe<Scalars['Boolean']>;
  employmentStatus?: InputMaybe<IncomeEmploymentStatus>;
  id?: InputMaybe<Scalars['BigInt']>;
  incomes?: InputMaybe<Array<InputMaybe<IncomeInput>>>;
  period?: InputMaybe<DateRangeInput>;
  wealthAmount?: InputMaybe<Scalars['BigFloat']>;
};

/** The output of our `updateFinancialPeriod` mutation. */
export type UpdateFinancialPeriodPayload = {
  __typename?: 'UpdateFinancialPeriodPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  financialPeriod?: Maybe<FinancialPeriod>;
  /** An edge for our `FinancialPeriod`. May be used by Relay 1. */
  financialPeriodEdge?: Maybe<FinancialPeriodsEdge>;
  /** Reads a single `Person` that is related to this `FinancialPeriod`. */
  personByPersonId?: Maybe<Person>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updatePersonByPersonId` mutation. */
export type UpdatePersonByPersonIdInput = {
  birthDate?: InputMaybe<Scalars['Date']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  personId?: InputMaybe<Scalars['BigInt']>;
};

/** The output of our `updatePersonByPersonId` mutation. */
export type UpdatePersonByPersonIdPayload = {
  __typename?: 'UpdatePersonByPersonIdPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  person?: Maybe<Person>;
  /** An edge for our `Person`. May be used by Relay 1. */
  personEdge?: Maybe<PeopleEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updatePersonEmailAuth0` mutation. */
export type UpdatePersonEmailAuth0Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  personId?: InputMaybe<Scalars['BigInt']>;
};

/** The output of our `updatePersonEmailAuth0` mutation. */
export type UpdatePersonEmailAuth0Payload = {
  __typename?: 'UpdatePersonEmailAuth0Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  string?: Maybe<Scalars['String']>;
};

/** All input for the `updatePledgeByPersonOrTemporaryPerson` mutation. */
export type UpdatePledgeByPersonOrTemporaryPersonInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  pledgeId?: InputMaybe<Scalars['BigInt']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
};

/** The output of our `updatePledgeByPersonOrTemporaryPerson` mutation. */
export type UpdatePledgeByPersonOrTemporaryPersonPayload = {
  __typename?: 'UpdatePledgeByPersonOrTemporaryPersonPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Person` that is related to this `Pledge`. */
  personByPersonId?: Maybe<Person>;
  pledge?: Maybe<Pledge>;
  /** An edge for our `Pledge`. May be used by Relay 1. */
  pledgeEdge?: Maybe<PledgesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updatePledgeDetailsByTemporaryPerson` mutation. */
export type UpdatePledgeDetailsByTemporaryPersonInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  motivation?: InputMaybe<Scalars['String']>;
  personBirthDate?: InputMaybe<Scalars['Date']>;
  pledgeCountryCode?: InputMaybe<Scalars['String']>;
  pledgeId?: InputMaybe<Scalars['BigInt']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
  shareMotivation?: InputMaybe<Scalars['Boolean']>;
};

/** The output of our `updatePledgeDetailsByTemporaryPerson` mutation. */
export type UpdatePledgeDetailsByTemporaryPersonPayload = {
  __typename?: 'UpdatePledgeDetailsByTemporaryPersonPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updatePledgeDetails` mutation. */
export type UpdatePledgeDetailsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  motivation?: InputMaybe<Scalars['String']>;
  personBirthDate?: InputMaybe<Scalars['Date']>;
  personId?: InputMaybe<Scalars['BigInt']>;
  pledgeCountryCode?: InputMaybe<Scalars['String']>;
  pledgeId?: InputMaybe<Scalars['BigInt']>;
  shareMotivation?: InputMaybe<Scalars['Boolean']>;
};

/** The output of our `updatePledgeDetails` mutation. */
export type UpdatePledgeDetailsPayload = {
  __typename?: 'UpdatePledgeDetailsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateRecurringPaymentAmount` mutation. */
export type UpdateRecurringPaymentAmountInput = {
  amount: Scalars['BigFloat'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  recurringPaymentId: Scalars['BigInt'];
};

/** The output of our `updateRecurringPaymentAmount` mutation. */
export type UpdateRecurringPaymentAmountPayload = {
  __typename?: 'UpdateRecurringPaymentAmountPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Currency` that is related to this `RecurringPayment`. */
  currencyByCurrencyCode?: Maybe<Currency>;
  /** Reads a single `Person` that is related to this `RecurringPayment`. */
  personByPersonId?: Maybe<Person>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  recurringPayment?: Maybe<RecurringPayment>;
  /** An edge for our `RecurringPayment`. May be used by Relay 1. */
  recurringPaymentEdge?: Maybe<RecurringPaymentsEdge>;
};

/** All input for the `updateReportedDonation` mutation. */
export type UpdateReportedDonationInput = {
  amount?: InputMaybe<Scalars['BigFloat']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  donationDate?: InputMaybe<Scalars['Date']>;
  financialPeriodId?: InputMaybe<Scalars['BigInt']>;
  id?: InputMaybe<Scalars['BigInt']>;
  organizationId?: InputMaybe<Scalars['BigInt']>;
  personId?: InputMaybe<Scalars['BigInt']>;
  pledgeId?: InputMaybe<Scalars['BigInt']>;
};

/** The output of our `updateReportedDonation` mutation. */
export type UpdateReportedDonationPayload = {
  __typename?: 'UpdateReportedDonationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Currency` that is related to this `ReportedDonation`. */
  currencyByCurrencyCode?: Maybe<Currency>;
  /** Reads a single `ExternalOrganization` that is related to this `ReportedDonation`. */
  externalOrganizationByOrganizationId?: Maybe<ExternalOrganization>;
  /** Reads a single `Person` that is related to this `ReportedDonation`. */
  personByPersonId?: Maybe<Person>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  reportedDonation?: Maybe<ReportedDonation>;
};

/** All input for the `updateReportedPayment` mutation. */
export type UpdateReportedPaymentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  financialPeriodId?: InputMaybe<Scalars['BigInt']>;
  id?: InputMaybe<Scalars['BigInt']>;
  personId?: InputMaybe<Scalars['BigInt']>;
  pledgeId?: InputMaybe<Scalars['BigInt']>;
};

/** The output of our `updateReportedPayment` mutation. */
export type UpdateReportedPaymentPayload = {
  __typename?: 'UpdateReportedPaymentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Currency` that is related to this `Payment`. */
  currencyByChargeCurrencyCode?: Maybe<Currency>;
  /** Reads a single `Currency` that is related to this `Payment`. */
  currencyByCurrencyCode?: Maybe<Currency>;
  payment?: Maybe<Payment>;
  /** An edge for our `Payment`. May be used by Relay 1. */
  paymentEdge?: Maybe<PaymentsEdge>;
  /** Reads a single `Person` that is related to this `Payment`. */
  personByPersonId?: Maybe<Person>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `verifyEmail` mutation. */
export type VerifyEmailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  pledgeId: Scalars['BigInt'];
  token: Scalars['UUID'];
};

/** The output of our `verifyEmail` mutation. */
export type VerifyEmailPayload = {
  __typename?: 'VerifyEmailPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Person` that is related to this `Pledge`. */
  personByPersonId?: Maybe<Person>;
  pledge?: Maybe<Pledge>;
  /** An edge for our `Pledge`. May be used by Relay 1. */
  pledgeEdge?: Maybe<PledgesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `voidPayment` mutation. */
export type VoidPaymentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  paymentId?: InputMaybe<Scalars['BigInt']>;
};

/** The output of our `voidPayment` mutation. */
export type VoidPaymentPayload = {
  __typename?: 'VoidPaymentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Currency` that is related to this `Payment`. */
  currencyByChargeCurrencyCode?: Maybe<Currency>;
  /** Reads a single `Currency` that is related to this `Payment`. */
  currencyByCurrencyCode?: Maybe<Currency>;
  payment?: Maybe<Payment>;
  /** An edge for our `Payment`. May be used by Relay 1. */
  paymentEdge?: Maybe<PaymentsEdge>;
  /** Reads a single `Person` that is related to this `Payment`. */
  personByPersonId?: Maybe<Person>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export enum VoteMechanism {
  Multiple = 'MULTIPLE'
}

export type CreateExternalOrganizationMutationVariables = Exact<{
  name: Scalars['String'];
  organizationSlug?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
  keywords?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type CreateExternalOrganizationMutation = { __typename?: 'Mutation', createExternalOrganization?: { __typename?: 'CreateExternalOrganizationPayload', externalOrganization?: { __typename?: 'ExternalOrganization', id: string, name: string, keywords?: Array<string | null | undefined> | null | undefined, organizationSlug?: string | null | undefined, url?: string | null | undefined } | null | undefined } | null | undefined };

export type GetExternalOrganizationTypeaheadQueryVariables = Exact<{
  searchText: Scalars['String'];
}>;


export type GetExternalOrganizationTypeaheadQuery = { __typename?: 'Query', ExternalOrganizations?: { __typename?: 'ExternalOrganizationsConnection', edges: Array<{ __typename?: 'ExternalOrganizationsEdge', node: { __typename?: 'ExternalOrganization', id: string, name: string, organizationSlug?: string | null | undefined, url?: string | null | undefined } }> } | null | undefined };

export type GetOrganizationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrganizationsQuery = { __typename?: 'Query', Organizations?: { __typename?: 'ProgramDetailsLegaciesConnection', edges: Array<{ __typename?: 'ProgramDetailsLegaciesEdge', node: { __typename?: 'ProgramDetailsLegacy', slug: string, name: string, program: string, type?: ProgramType | null | undefined, active?: boolean | null | undefined, hidden?: boolean | null | undefined, href?: string | null | undefined, isDataSharingPartner?: boolean | null | undefined } }> } | null | undefined };

export type CancelRecurringPaymentMutationVariables = Exact<{
  recurringPaymentId: Scalars['BigInt'];
}>;


export type CancelRecurringPaymentMutation = { __typename?: 'Mutation', cancelRecurringPayment?: { __typename?: 'CancelRecurringPaymentPayload', clientMutationId?: string | null | undefined } | null | undefined };

export type CreateGiftAidClaimMutationVariables = Exact<{
  paymentId: Scalars['BigInt'];
  addressId: Scalars['BigInt'];
}>;


export type CreateGiftAidClaimMutation = { __typename?: 'Mutation', createGiftAidClaimFromPayment?: { __typename?: 'CreateGiftAidClaimFromPaymentPayload', giftAidClaim?: { __typename?: 'GiftAidClaim', paymentId: string, personId: string, addressId: string, reportId?: number | null | undefined } | null | undefined } | null | undefined };

export type CreateGocardlessRedirectFlowMutationVariables = Exact<{
  personId?: InputMaybe<Scalars['BigInt']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
}>;


export type CreateGocardlessRedirectFlowMutation = { __typename?: 'Mutation', mutationResult?: { __typename?: 'CreateGocardlessRedirectFlowByPersonOrTemporaryPersonPayload', RedirectFlow?: { __typename?: 'GocardlessRedirectFlow', personId: string, sessionToken: string, gocardlessRedirectFlowId?: string | null | undefined, gocardlessRedirectFlowUrl?: string | null | undefined, expires: string, createdAt: string, updatedAt: string } | null | undefined } | null | undefined };

export type CreatePaymentMutationVariables = Exact<{
  personId: Scalars['BigInt'];
  currencyCode: Scalars['String'];
  amount: Scalars['BigFloat'];
  recipient: Scalars['String'];
  allocation: Scalars['JSON'];
  gateway: PaymentGateway;
  gatewayData?: InputMaybe<Scalars['JSON']>;
  paymentMethod: PaymentMethod;
  billingAddressId?: InputMaybe<Scalars['BigInt']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  status?: InputMaybe<PaymentStatus>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
}>;


export type CreatePaymentMutation = { __typename?: 'Mutation', mutationResult?: { __typename?: 'CreatePaymentByPersonIdPayload', payment?: { __typename?: 'Payment', id: string, personId: string, chargeCurrencyCode: string, chargeAmount: string, currencyCode?: string | null | undefined, amount?: string | null | undefined, amountNormalized?: string | null | undefined, net?: string | null | undefined, fee?: string | null | undefined, recipient: string, allocation: unknown, gateway: PaymentGateway, gatewayData?: unknown | null | undefined, gatewayTransactionId?: string | null | undefined, paymentMethod?: PaymentMethod | null | undefined, recurrence: PaymentRecurrence, billingAddressId?: string | null | undefined, metadata?: unknown | null | undefined, status: PaymentStatus, statusData?: unknown | null | undefined, reference: string, createdAt: string, updatedAt: string } | null | undefined } | null | undefined };

export type CreatePaymentByTemporaryPersonMutationVariables = Exact<{
  sessionToken: Scalars['UUID'];
  currencyCode: Scalars['String'];
  amount: Scalars['BigFloat'];
  recipient: Scalars['String'];
  allocation: Scalars['JSON'];
  gateway: PaymentGateway;
  gatewayData?: InputMaybe<Scalars['JSON']>;
  paymentMethod: PaymentMethod;
  billingAddressId?: InputMaybe<Scalars['BigInt']>;
  metadata?: InputMaybe<Scalars['JSON']>;
}>;


export type CreatePaymentByTemporaryPersonMutation = { __typename?: 'Mutation', mutationResult?: { __typename?: 'CreatePaymentByTemporaryPersonPayload', payment?: { __typename?: 'Payment', id: string, personId: string, chargeCurrencyCode: string, chargeAmount: string, currencyCode?: string | null | undefined, amount?: string | null | undefined, amountNormalized?: string | null | undefined, net?: string | null | undefined, fee?: string | null | undefined, recipient: string, allocation: unknown, gateway: PaymentGateway, gatewayData?: unknown | null | undefined, gatewayTransactionId?: string | null | undefined, paymentMethod?: PaymentMethod | null | undefined, recurrence: PaymentRecurrence, billingAddressId?: string | null | undefined, metadata?: unknown | null | undefined, status: PaymentStatus, statusData?: unknown | null | undefined, reference: string, createdAt: string, updatedAt: string } | null | undefined } | null | undefined };

export type CreateRecurringPaymentMutationVariables = Exact<{
  personId: Scalars['BigInt'];
  period: RecurrencePeriod;
  periodIndex: Scalars['Int'];
  allocation: Scalars['JSON'];
  amount: Scalars['BigFloat'];
  currencyCode: Scalars['String'];
  recipient: Scalars['String'];
  gateway: PaymentGateway;
  gatewayData?: InputMaybe<Scalars['JSON']>;
  paymentMethod: PaymentMethod;
  billingAddressId?: InputMaybe<Scalars['BigInt']>;
  metadata?: InputMaybe<Scalars['JSON']>;
}>;


export type CreateRecurringPaymentMutation = { __typename?: 'Mutation', mutationResult?: { __typename?: 'CreateRecurringPaymentPayload', recurringPayment?: { __typename?: 'RecurringPayment', id: string, personId?: string | null | undefined, period: RecurrencePeriod, periodIndex: number, periodInterval: number, allocation: unknown, amount: string, currencyCode: string, recipient: string, gateway: PaymentGateway, gatewayData?: unknown | null | undefined, paymentMethod?: PaymentMethod | null | undefined, billingAddressId?: string | null | undefined, metadata?: unknown | null | undefined, status: RecurringPaymentStatus, statusData?: unknown | null | undefined, createdAt: string, updatedAt: string } | null | undefined } | null | undefined };

export type CreateRecurringPaymentByTemporaryPersonMutationVariables = Exact<{
  sessionToken: Scalars['UUID'];
  period: RecurrencePeriod;
  periodIndex: Scalars['Int'];
  allocation: Scalars['JSON'];
  amount: Scalars['BigFloat'];
  currencyCode: Scalars['String'];
  recipient: Scalars['String'];
  gateway: PaymentGateway;
  gatewayData?: InputMaybe<Scalars['JSON']>;
  paymentMethod: PaymentMethod;
  billingAddressId?: InputMaybe<Scalars['BigInt']>;
  metadata?: InputMaybe<Scalars['JSON']>;
}>;


export type CreateRecurringPaymentByTemporaryPersonMutation = { __typename?: 'Mutation', mutationResult?: { __typename?: 'CreateRecurringPaymentByTemporaryPersonPayload', recurringPayment?: { __typename?: 'RecurringPayment', id: string, personId?: string | null | undefined, period: RecurrencePeriod, periodIndex: number, periodInterval: number, allocation: unknown, amount: string, currencyCode: string, recipient: string, gateway: PaymentGateway, gatewayData?: unknown | null | undefined, paymentMethod?: PaymentMethod | null | undefined, billingAddressId?: string | null | undefined, metadata?: unknown | null | undefined, status: RecurringPaymentStatus, statusData?: unknown | null | undefined, createdAt: string, updatedAt: string } | null | undefined } | null | undefined };

export type DeleteGiftAidClaimMutationVariables = Exact<{
  paymentId: Scalars['BigInt'];
}>;


export type DeleteGiftAidClaimMutation = { __typename?: 'Mutation', GiftAidClaim?: { __typename?: 'DeleteGiftAidClaimFromPaymentPayload', giftAidClaim?: { __typename?: 'GiftAidClaim', paymentId: string, personId: string, addressId: string, reportId?: number | null | undefined } | null | undefined } | null | undefined };

export type UpdateRecurringPaymentAmountMutationVariables = Exact<{
  recurringPaymentId: Scalars['BigInt'];
  amount: Scalars['BigFloat'];
}>;


export type UpdateRecurringPaymentAmountMutation = { __typename?: 'Mutation', updateRecurringPaymentAmount?: { __typename?: 'UpdateRecurringPaymentAmountPayload', clientMutationId?: string | null | undefined } | null | undefined };

export type VoidPaymentMutationVariables = Exact<{
  paymentId: Scalars['BigInt'];
}>;


export type VoidPaymentMutation = { __typename?: 'Mutation', voidPayment?: { __typename?: 'VoidPaymentPayload', payment?: { __typename?: 'Payment', id: string, status: PaymentStatus } | null | undefined } | null | undefined };

export type LotteryFieldsFragment = { __typename?: 'ExtendedLottery', id?: string | null | undefined, blockSize?: string | null | undefined, closeTimestamp?: string | null | undefined, lockTimestamp?: string | null | undefined, drawTimestamp?: string | null | undefined, drawEpoch?: number | null | undefined, status?: string | null | undefined, winningNumber?: string | null | undefined };

export type LotterySummaryFieldsFragment = { __typename?: 'LotterySummary', id?: string | null | undefined, blockSize?: string | null | undefined, closeTimestamp?: string | null | undefined, lockTimestamp?: string | null | undefined, drawTimestamp?: string | null | undefined, winningNumber?: string | null | undefined, winningNumberHex?: string | null | undefined, drawEpoch?: string | null | undefined, status?: string | null | undefined, ticketsAssigned?: boolean | null | undefined, maxBlockId?: number | null | undefined, numEntries?: number | null | undefined, entriesTotalNormalized?: string | null | undefined, benefactorLiability?: string | null | undefined, benefactorStartingTicketNumber?: string | null | undefined, benefactorWinsLastBlock?: boolean | null | undefined, winningTickets?: unknown | null | undefined };

export type PaymentFieldsFragment = { __typename?: 'Payment', id: string, personId: string, chargeCurrencyCode: string, chargeAmount: string, currencyCode?: string | null | undefined, amount?: string | null | undefined, amountNormalized?: string | null | undefined, net?: string | null | undefined, fee?: string | null | undefined, recipient: string, allocation: unknown, gateway: PaymentGateway, gatewayData?: unknown | null | undefined, gatewayTransactionId?: string | null | undefined, paymentMethod?: PaymentMethod | null | undefined, recurrence: PaymentRecurrence, billingAddressId?: string | null | undefined, metadata?: unknown | null | undefined, status: PaymentStatus, statusData?: unknown | null | undefined, reference: string, createdAt: string, updatedAt: string };

export type RecurringPaymentFieldsFragment = { __typename?: 'RecurringPayment', id: string, personId?: string | null | undefined, period: RecurrencePeriod, periodIndex: number, periodInterval: number, allocation: unknown, amount: string, currencyCode: string, recipient: string, gateway: PaymentGateway, gatewayData?: unknown | null | undefined, paymentMethod?: PaymentMethod | null | undefined, billingAddressId?: string | null | undefined, metadata?: unknown | null | undefined, status: RecurringPaymentStatus, statusData?: unknown | null | undefined, createdAt: string, updatedAt: string };

export type GetAllRecipientProgramsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllRecipientProgramsQuery = { __typename?: 'Query', RecipientPrograms?: Array<{ __typename?: 'ActiveRecipientProgram', organizationSlug: string, recipientSlug: string }> | null | undefined };

export type GetFundraiserStatsQueryVariables = Exact<{
  fundraiserId: Scalars['String'];
  currencyCode: Scalars['String'];
}>;


export type GetFundraiserStatsQuery = { __typename?: 'Query', FundraiserStats?: { __typename?: 'FundraiserStatsConnection', edges: Array<{ __typename?: 'FundraiserStatsEdge', node: { __typename?: 'FundraiserStat', fundraiserId?: string | null | undefined, amountRaisedNormalized?: string | null | undefined, numDonors?: string | null | undefined } }> } | null | undefined };

export type GetGocardlessMandatesQueryVariables = Exact<{
  personId?: InputMaybe<Scalars['BigInt']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
}>;


export type GetGocardlessMandatesQuery = { __typename?: 'Query', gocardlessMandates?: { __typename?: 'GocardlessMandatesConnection', edges: Array<{ __typename?: 'GocardlessMandatesEdge', node: { __typename?: 'GocardlessMandate', personId: string, gocardlessCustomerId: string, gocardlessMandateId: string, status: GocardlessMandateStatus, gocardlessBankAccountId?: string | null | undefined, gocardlessBankAccountHolderName?: string | null | undefined, gocardlessBankAccountBankName?: string | null | undefined, gocardlessBankAccountNumberEnding?: number | null | undefined } }> } | null | undefined };

export type GetGocardlessRedirectFlowQueryVariables = Exact<{
  personId?: InputMaybe<Scalars['BigInt']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
}>;


export type GetGocardlessRedirectFlowQuery = { __typename?: 'Query', GocardlessRedirectFlow?: { __typename?: 'GocardlessRedirectFlow', personId: string, sessionToken: string, gocardlessRedirectFlowId?: string | null | undefined, gocardlessRedirectFlowUrl?: string | null | undefined, expires: string, createdAt: string, updatedAt: string } | null | undefined };

export type GetLotteryByIdQueryVariables = Exact<{
  lotteryId: Scalars['BigInt'];
}>;


export type GetLotteryByIdQuery = { __typename?: 'Query', Lottery?: { __typename?: 'ExtendedLottery', id?: string | null | undefined, blockSize?: string | null | undefined, closeTimestamp?: string | null | undefined, lockTimestamp?: string | null | undefined, drawTimestamp?: string | null | undefined, drawEpoch?: number | null | undefined, status?: string | null | undefined, winningNumber?: string | null | undefined } | null | undefined };

export type GetLotteryEntriesCompleteQueryVariables = Exact<{
  lotteryId: Scalars['BigInt'];
}>;


export type GetLotteryEntriesCompleteQuery = { __typename?: 'Query', LotteryEntriesComplete?: { __typename?: 'LotteryEntryCompletesConnection', edges: Array<{ __typename?: 'LotteryEntryCompletesEdge', node: { __typename?: 'LotteryEntryComplete', lotteryId?: string | null | undefined, paymentReference?: string | null | undefined, amountNormalized?: string | null | undefined, displayName?: string | null | undefined, tickets?: unknown | null | undefined, createdAt?: string | null | undefined, benefactor?: boolean | null | undefined } }> } | null | undefined };

export type GetLotterySummariesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLotterySummariesQuery = { __typename?: 'Query', Lotteries?: { __typename?: 'LotterySummariesConnection', edges: Array<{ __typename?: 'LotterySummariesEdge', node: { __typename?: 'LotterySummary', id?: string | null | undefined, blockSize?: string | null | undefined, closeTimestamp?: string | null | undefined, lockTimestamp?: string | null | undefined, drawTimestamp?: string | null | undefined, winningNumber?: string | null | undefined, winningNumberHex?: string | null | undefined, drawEpoch?: string | null | undefined, status?: string | null | undefined, ticketsAssigned?: boolean | null | undefined, maxBlockId?: number | null | undefined, numEntries?: number | null | undefined, entriesTotalNormalized?: string | null | undefined, benefactorLiability?: string | null | undefined, benefactorStartingTicketNumber?: string | null | undefined, benefactorWinsLastBlock?: boolean | null | undefined, winningTickets?: unknown | null | undefined } }> } | null | undefined };

export type GetLotterySummaryQueryVariables = Exact<{
  lotteryId: Scalars['BigInt'];
}>;


export type GetLotterySummaryQuery = { __typename?: 'Query', LotterySummary?: { __typename?: 'LotterySummary', id?: string | null | undefined, blockSize?: string | null | undefined, closeTimestamp?: string | null | undefined, lockTimestamp?: string | null | undefined, drawTimestamp?: string | null | undefined, winningNumber?: string | null | undefined, winningNumberHex?: string | null | undefined, drawEpoch?: string | null | undefined, status?: string | null | undefined, ticketsAssigned?: boolean | null | undefined, maxBlockId?: number | null | undefined, numEntries?: number | null | undefined, entriesTotalNormalized?: string | null | undefined, benefactorLiability?: string | null | undefined, benefactorStartingTicketNumber?: string | null | undefined, benefactorWinsLastBlock?: boolean | null | undefined, winningTickets?: unknown | null | undefined } | null | undefined };

export type GetPaymentByPersonOrTemporaryPersonQueryVariables = Exact<{
  paymentId: Scalars['BigInt'];
  sessionToken?: InputMaybe<Scalars['UUID']>;
}>;


export type GetPaymentByPersonOrTemporaryPersonQuery = { __typename?: 'Query', Payment?: { __typename?: 'Payment', id: string, personId: string, chargeCurrencyCode: string, chargeAmount: string, currencyCode?: string | null | undefined, amount?: string | null | undefined, amountNormalized?: string | null | undefined, net?: string | null | undefined, fee?: string | null | undefined, recipient: string, allocation: unknown, gateway: PaymentGateway, gatewayData?: unknown | null | undefined, gatewayTransactionId?: string | null | undefined, paymentMethod?: PaymentMethod | null | undefined, recurrence: PaymentRecurrence, billingAddressId?: string | null | undefined, metadata?: unknown | null | undefined, status: PaymentStatus, statusData?: unknown | null | undefined, reference: string, createdAt: string, updatedAt: string } | null | undefined };

export type GetPaymentsQueryVariables = Exact<{
  personId: Scalars['BigInt'];
}>;


export type GetPaymentsQuery = { __typename?: 'Query', Payments?: { __typename?: 'PaymentsConnection', edges: Array<{ __typename?: 'PaymentsEdge', node: { __typename?: 'Payment', id: string, personId: string, chargeCurrencyCode: string, chargeAmount: string, currencyCode?: string | null | undefined, amount?: string | null | undefined, amountNormalized?: string | null | undefined, net?: string | null | undefined, fee?: string | null | undefined, recipient: string, allocation: unknown, gateway: PaymentGateway, gatewayData?: unknown | null | undefined, gatewayTransactionId?: string | null | undefined, paymentMethod?: PaymentMethod | null | undefined, recurrence: PaymentRecurrence, billingAddressId?: string | null | undefined, metadata?: unknown | null | undefined, status: PaymentStatus, statusData?: unknown | null | undefined, reference: string, createdAt: string, updatedAt: string, giftAidClaimsByPaymentIdAndPersonId: { __typename?: 'GiftAidClaimsConnection', edges: Array<{ __typename?: 'GiftAidClaimsEdge', node: { __typename?: 'GiftAidClaim', nodeId: string, paymentId: string, personId: string, addressId: string, reportId?: number | null | undefined } }> } } }> } | null | undefined };

export type GetRecurringPaymentQueryVariables = Exact<{
  id: Scalars['BigInt'];
}>;


export type GetRecurringPaymentQuery = { __typename?: 'Query', recurringPayment?: { __typename?: 'RecurringPayment', id: string, personId?: string | null | undefined, period: RecurrencePeriod, periodIndex: number, periodInterval: number, allocation: unknown, amount: string, currencyCode: string, recipient: string, gateway: PaymentGateway, gatewayData?: unknown | null | undefined, paymentMethod?: PaymentMethod | null | undefined, billingAddressId?: string | null | undefined, metadata?: unknown | null | undefined, status: RecurringPaymentStatus, statusData?: unknown | null | undefined, createdAt: string, updatedAt: string } | null | undefined };

export type GetRecurringPaymentByPersonOrTemporaryPersonQueryVariables = Exact<{
  id?: InputMaybe<Scalars['BigInt']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
}>;


export type GetRecurringPaymentByPersonOrTemporaryPersonQuery = { __typename?: 'Query', recurringPayment?: { __typename?: 'RecurringPayment', id: string, personId?: string | null | undefined, period: RecurrencePeriod, periodIndex: number, periodInterval: number, allocation: unknown, amount: string, currencyCode: string, recipient: string, gateway: PaymentGateway, gatewayData?: unknown | null | undefined, paymentMethod?: PaymentMethod | null | undefined, billingAddressId?: string | null | undefined, metadata?: unknown | null | undefined, status: RecurringPaymentStatus, statusData?: unknown | null | undefined, createdAt: string, updatedAt: string } | null | undefined };

export type GetRecurringPaymentsQueryVariables = Exact<{
  personId: Scalars['BigInt'];
}>;


export type GetRecurringPaymentsQuery = { __typename?: 'Query', RecurringPayments?: { __typename?: 'RecurringPaymentsConnection', edges: Array<{ __typename?: 'RecurringPaymentsEdge', node: { __typename?: 'RecurringPayment', id: string, personId?: string | null | undefined, period: RecurrencePeriod, periodIndex: number, periodInterval: number, allocation: unknown, amount: string, currencyCode: string, recipient: string, gateway: PaymentGateway, gatewayData?: unknown | null | undefined, paymentMethod?: PaymentMethod | null | undefined, billingAddressId?: string | null | undefined, metadata?: unknown | null | undefined, status: RecurringPaymentStatus, statusData?: unknown | null | undefined, createdAt: string, updatedAt: string } }> } | null | undefined };

export type GetTotalPledgeSignupsQueryVariables = Exact<{
  startDate: Scalars['Date'];
  excludeTrialPledges?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetTotalPledgeSignupsQuery = { __typename?: 'Query', TotalPledgeSignups?: number | null | undefined };

export type SendReceiptQueryVariables = Exact<{
  paymentId: Scalars['BigInt'];
}>;


export type SendReceiptQuery = { __typename?: 'Query', sendReceipt?: string | null | undefined };

export type AddReminderMutationVariables = Exact<{
  reminderDate: Scalars['Date'];
  email: Scalars['String'];
  newsletterSignup: Scalars['Boolean'];
  reminderSourcePath: Scalars['String'];
}>;


export type AddReminderMutation = { __typename?: 'Mutation', addReminder?: { __typename?: 'AddReminderPayload', reminder?: { __typename?: 'Reminder', email: string, reminderDate: string, newsletterSignup: boolean, reminderSourcePath?: string | null | undefined } | null | undefined } | null | undefined };

export type CreateAddressMutationVariables = Exact<{
  personId?: InputMaybe<Scalars['BigInt']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
  addressType?: InputMaybe<AddressType>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  organizationName?: InputMaybe<Scalars['String']>;
  houseNumber?: InputMaybe<Scalars['String']>;
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  googlePlaceId?: InputMaybe<Scalars['String']>;
}>;


export type CreateAddressMutation = { __typename?: 'Mutation', mutationResult?: { __typename?: 'CreateAddressByPersonOrTemporaryPersonPayload', address?: { __typename?: 'Address', id: string, personId: string, addressType?: AddressType | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined, organizationName?: string | null | undefined, houseNumber?: string | null | undefined, addressLine1?: string | null | undefined, addressLine2?: string | null | undefined, city?: string | null | undefined, region?: string | null | undefined, postalCode?: string | null | undefined, countryCode?: string | null | undefined } | null | undefined } | null | undefined };

export type CreateTemporaryPersonMutationVariables = Exact<{
  email: Scalars['String'];
  recaptchaToken: Scalars['String'];
  isLongLasting?: InputMaybe<Scalars['Boolean']>;
}>;


export type CreateTemporaryPersonMutation = { __typename?: 'Mutation', createTemporaryPersonByEmail?: { __typename?: 'CreateTemporaryPersonByEmailPayload', TemporaryPerson?: { __typename?: 'TemporaryPerson', email: string, sessionToken: string, tokenIssuedAt: string, tokenExpiresAt: string } | null | undefined } | null | undefined };

export type SyncAuth0UserMutationVariables = Exact<{
  auth0User: Scalars['JSON'];
}>;


export type SyncAuth0UserMutation = { __typename?: 'Mutation', syncAuth0User?: { __typename?: 'SyncAuth0UserPayload', Person?: { __typename?: 'Person', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, email?: string | null | undefined, fullName?: string | null | undefined, birthDate?: string | null | undefined, createdAt: string, updatedAt: string, isAnonymized: boolean, auth0UserId?: string | null | undefined, contactType?: ContactType | null | undefined, organizationName?: string | null | undefined } | null | undefined } | null | undefined };

export type UpdatePersonByIdMutationVariables = Exact<{
  personId: Scalars['BigInt'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  birthDate?: InputMaybe<Scalars['Date']>;
}>;


export type UpdatePersonByIdMutation = { __typename?: 'Mutation', updatePersonByPersonId?: { __typename?: 'UpdatePersonByPersonIdPayload', person?: { __typename?: 'Person', id: string, fullName?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined, email?: string | null | undefined, birthDate?: string | null | undefined, contactType?: ContactType | null | undefined, organizationName?: string | null | undefined } | null | undefined } | null | undefined };

export type UpdatePersonEmailMutationVariables = Exact<{
  personId: Scalars['BigInt'];
  email: Scalars['String'];
}>;


export type UpdatePersonEmailMutation = { __typename?: 'Mutation', Person?: { __typename?: 'UpdatePersonEmailAuth0Payload', message?: string | null | undefined } | null | undefined };

export type GetAddressesQueryVariables = Exact<{
  personId?: InputMaybe<Scalars['BigInt']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
}>;


export type GetAddressesQuery = { __typename?: 'Query', Addresses?: { __typename?: 'AddressesConnection', edges: Array<{ __typename?: 'AddressesEdge', node: { __typename?: 'Address', id: string, personId: string, addressType?: AddressType | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined, organizationName?: string | null | undefined, houseNumber?: string | null | undefined, addressLine1?: string | null | undefined, addressLine2?: string | null | undefined, city?: string | null | undefined, region?: string | null | undefined, postalCode?: string | null | undefined, countryCode?: string | null | undefined, coordinates?: string | null | undefined, createdAt: string, updatedAt: string } }> } | null | undefined };

export type GetPersonQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPersonQuery = { __typename?: 'Query', Person?: { __typename?: 'Person', id: string, email?: string | null | undefined, fullName?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined, contactType?: ContactType | null | undefined, organizationName?: string | null | undefined, birthDate?: string | null | undefined, auth0UserId?: string | null | undefined } | null | undefined };

export type GetPersonByIdQueryVariables = Exact<{
  personId: Scalars['BigInt'];
}>;


export type GetPersonByIdQuery = { __typename?: 'Query', Person?: { __typename?: 'Person', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, fullName?: string | null | undefined, email?: string | null | undefined, birthDate?: string | null | undefined, createdAt: string, updatedAt: string, auth0UserId?: string | null | undefined, contactType?: ContactType | null | undefined, organizationName?: string | null | undefined } | null | undefined };

export type CancelRecurringReportedDonationMutationVariables = Exact<{
  recurringReportedDonationId: Scalars['BigInt'];
}>;


export type CancelRecurringReportedDonationMutation = { __typename?: 'Mutation', cancelRecurringReportedDonation?: { __typename?: 'CancelRecurringReportedDonationPayload', RecurringReportedDonation?: { __typename?: 'RecurringReportedDonation', id: string } | null | undefined } | null | undefined };

export type CreateFinancialPeriodMutationVariables = Exact<{
  personId: Scalars['BigInt'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  wealthAmount?: InputMaybe<Scalars['BigFloat']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  employmentStatus?: InputMaybe<IncomeEmploymentStatus>;
  donationsTaxDeductible?: InputMaybe<Scalars['Boolean']>;
  incomes?: InputMaybe<Array<InputMaybe<IncomeInput>> | InputMaybe<IncomeInput>>;
}>;


export type CreateFinancialPeriodMutation = { __typename?: 'Mutation', createFinancialPeriod?: { __typename?: 'CreateFinancialPeriodPayload', financialPeriod?: { __typename?: 'FinancialPeriod', id: string, personId: string, wealthAmount?: string | null | undefined, currencyCode: string, period: { __typename?: 'DateRange', start?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined, end?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined } } | null | undefined } | null | undefined };

export type CreateRecurringReportedDonationMutationVariables = Exact<{
  personId: Scalars['BigInt'];
  organizationId: Scalars['BigInt'];
  amount: Scalars['BigFloat'];
  currencyCode: Scalars['String'];
  startDate: Scalars['Date'];
  recurrenceInterval: IntervalInput;
}>;


export type CreateRecurringReportedDonationMutation = { __typename?: 'Mutation', RecurringReportedDonation?: { __typename?: 'CreateRecurringReportedDonationPayload', recurringReportedDonation?: { __typename?: 'RecurringReportedDonation', id: string, personId: string, organizationId: string, amount: string, currencyCode: string, startDate: string, nextDate: string, status: RecurringPaymentStatus, createdAt: string, updatedAt: string, recurrenceInterval: { __typename?: 'Interval', years?: number | null | undefined, months?: number | null | undefined, days?: number | null | undefined, hours?: number | null | undefined, minutes?: number | null | undefined, seconds?: number | null | undefined } } | null | undefined } | null | undefined };

export type CreateReportedDonationMutationVariables = Exact<{
  personId: Scalars['BigInt'];
  donationDate: Scalars['Date'];
  amount: Scalars['BigFloat'];
  currencyCode: Scalars['String'];
  organizationId: Scalars['BigInt'];
  pledgeId?: InputMaybe<Scalars['BigInt']>;
  financialPeriodId?: InputMaybe<Scalars['BigInt']>;
}>;


export type CreateReportedDonationMutation = { __typename?: 'Mutation', createReportedDonationByPersonId?: { __typename?: 'CreateReportedDonationByPersonIdPayload', reportedDonation?: { __typename?: 'ReportedDonation', id: string, personId: string, donationDate?: string | null | undefined, amount?: string | null | undefined, currencyCode?: string | null | undefined, organizationId: string } | null | undefined } | null | undefined };

export type DeleteFinancialPeriodMutationVariables = Exact<{
  id: Scalars['BigInt'];
}>;


export type DeleteFinancialPeriodMutation = { __typename?: 'Mutation', deleteFinancialPeriod?: { __typename?: 'DeleteFinancialPeriodPayload', clientMutationId?: string | null | undefined } | null | undefined };

export type DeleteReportedDonationMutationVariables = Exact<{
  reportedDonationId: Scalars['BigInt'];
}>;


export type DeleteReportedDonationMutation = { __typename?: 'Mutation', deleteReportedDonation?: { __typename?: 'DeleteReportedDonationPayload', clientMutationId?: string | null | undefined } | null | undefined };

export type JoinPledgeClubMutationVariables = Exact<{
  personId: Scalars['BigInt'];
  pledgeClubSlug: Scalars['String'];
  source: Scalars['String'];
}>;


export type JoinPledgeClubMutation = { __typename?: 'Mutation', PledgeClubMember?: { __typename?: 'JoinPledgeClubPayload', pledgeClubMember?: { __typename?: 'PledgeClubMember', pledgeClubSlug: string, personId: string } | null | undefined } | null | undefined };

export type LeavePledgeClubMutationVariables = Exact<{
  personId: Scalars['BigInt'];
  pledgeClubSlug: Scalars['String'];
}>;


export type LeavePledgeClubMutation = { __typename?: 'Mutation', PledgeClubMember?: { __typename?: 'LeavePledgeClubPayload', pledgeClubMember?: { __typename?: 'PledgeClubMember', pledgeClubSlug: string, personId: string } | null | undefined } | null | undefined };

export type SignPledgeMutationVariables = Exact<{
  personId: Scalars['BigInt'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  incomePercentage: Scalars['BigFloat'];
  wealthPercentage?: InputMaybe<Scalars['BigFloat']>;
  pledgeType: PledgeType;
  motivation?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  metadata: Scalars['JSON'];
  employmentStatus: IncomeEmploymentStatus;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
}>;


export type SignPledgeMutation = { __typename?: 'Mutation', signPledge?: { __typename?: 'SignPledgePayload', pledge?: { __typename?: 'Pledge', id: string, personId: string, incomePercentage: string, wealthPercentage?: string | null | undefined, motivation?: string | null | undefined, locale: string, metadata?: unknown | null | undefined, pledgeType: PledgeType, period: { __typename?: 'DateRange', start?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined, end?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined } } | null | undefined } | null | undefined };

export type SignPledgeByTemporaryPersonMutationVariables = Exact<{
  sessionToken: Scalars['UUID'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  incomePercentage: Scalars['BigFloat'];
  wealthPercentage?: InputMaybe<Scalars['BigFloat']>;
  pledgeType: PledgeType;
  motivation?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  metadata: Scalars['JSON'];
  employmentStatus: IncomeEmploymentStatus;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
}>;


export type SignPledgeByTemporaryPersonMutation = { __typename?: 'Mutation', signPledgeByTemporaryPerson?: { __typename?: 'SignPledgeByTemporaryPersonPayload', pledge?: { __typename?: 'Pledge', id: string, personId: string, incomePercentage: string, wealthPercentage?: string | null | undefined, motivation?: string | null | undefined, locale: string, metadata?: unknown | null | undefined, pledgeType: PledgeType, period: { __typename?: 'DateRange', start?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined, end?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined } } | null | undefined } | null | undefined };

export type UpdateFinancialPeriodMutationVariables = Exact<{
  id: Scalars['BigInt'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  wealthAmount?: InputMaybe<Scalars['BigFloat']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  employmentStatus?: InputMaybe<IncomeEmploymentStatus>;
  donationsTaxDeductible?: InputMaybe<Scalars['Boolean']>;
  incomes?: InputMaybe<Array<InputMaybe<IncomeInput>> | InputMaybe<IncomeInput>>;
}>;


export type UpdateFinancialPeriodMutation = { __typename?: 'Mutation', updateFinancialPeriod?: { __typename?: 'UpdateFinancialPeriodPayload', financialPeriod?: { __typename?: 'FinancialPeriod', id: string, personId: string, wealthAmount?: string | null | undefined, currencyCode: string, period: { __typename?: 'DateRange', start?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined, end?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined } } | null | undefined } | null | undefined };

export type UpdateReportedPaymentMutationVariables = Exact<{
  id: Scalars['BigInt'];
  personId: Scalars['BigInt'];
  pledgeId?: InputMaybe<Scalars['BigInt']>;
  financialPeriodId?: InputMaybe<Scalars['BigInt']>;
}>;


export type UpdateReportedPaymentMutation = { __typename?: 'Mutation', updateReportedPayment?: { __typename?: 'UpdateReportedPaymentPayload', payment?: { __typename?: 'Payment', id: string } | null | undefined } | null | undefined };

export type UpdatePledgeByPersonOrTemporaryPersonMutationVariables = Exact<{
  pledgeId: Scalars['BigInt'];
  metadata: Scalars['JSON'];
  sessionToken?: InputMaybe<Scalars['UUID']>;
}>;


export type UpdatePledgeByPersonOrTemporaryPersonMutation = { __typename?: 'Mutation', updatePledgeByPersonOrTemporaryPerson?: { __typename?: 'UpdatePledgeByPersonOrTemporaryPersonPayload', pledge?: { __typename?: 'Pledge', id: string, metadata?: unknown | null | undefined } | null | undefined } | null | undefined };

export type UpdatePledgeDetailsMutationVariables = Exact<{
  personId: Scalars['BigInt'];
  pledgeId: Scalars['BigInt'];
  personBirthDate?: InputMaybe<Scalars['Date']>;
  pledgeCountryCode?: InputMaybe<Scalars['String']>;
  motivation?: InputMaybe<Scalars['String']>;
  shareMotivation: Scalars['Boolean'];
}>;


export type UpdatePledgeDetailsMutation = { __typename?: 'Mutation', updatePledgeDetails?: { __typename?: 'UpdatePledgeDetailsPayload', clientMutationId?: string | null | undefined } | null | undefined };

export type UpdatePledgeDetailsByTemporaryPersonMutationVariables = Exact<{
  sessionToken: Scalars['UUID'];
  pledgeId: Scalars['BigInt'];
  personBirthDate?: InputMaybe<Scalars['Date']>;
  pledgeCountryCode?: InputMaybe<Scalars['String']>;
  motivation?: InputMaybe<Scalars['String']>;
  shareMotivation: Scalars['Boolean'];
}>;


export type UpdatePledgeDetailsByTemporaryPersonMutation = { __typename?: 'Mutation', updatePledgeDetailsByTemporaryPerson?: { __typename?: 'UpdatePledgeDetailsByTemporaryPersonPayload', clientMutationId?: string | null | undefined } | null | undefined };

export type UpdateReportedDonationMutationVariables = Exact<{
  id: Scalars['BigInt'];
  personId: Scalars['BigInt'];
  donationDate: Scalars['Date'];
  amount: Scalars['BigFloat'];
  currencyCode: Scalars['String'];
  organizationId: Scalars['BigInt'];
  pledgeId?: InputMaybe<Scalars['BigInt']>;
  financialPeriodId?: InputMaybe<Scalars['BigInt']>;
}>;


export type UpdateReportedDonationMutation = { __typename?: 'Mutation', updateReportedDonation?: { __typename?: 'UpdateReportedDonationPayload', reportedDonation?: { __typename?: 'ReportedDonation', id: string, personId: string, donationDate?: string | null | undefined, amount?: string | null | undefined, currencyCode?: string | null | undefined, organizationId: string } | null | undefined } | null | undefined };

export type VerifyEmailMutationVariables = Exact<{
  pledgeId: Scalars['BigInt'];
  emailVerificationToken: Scalars['UUID'];
}>;


export type VerifyEmailMutation = { __typename?: 'Mutation', verifyEmail?: { __typename?: 'VerifyEmailPayload', pledge?: { __typename?: 'Pledge', id: string } | null | undefined } | null | undefined };

export type GetCompletionPaymentsByPersonIdQueryVariables = Exact<{
  personId: Scalars['BigInt'];
}>;


export type GetCompletionPaymentsByPersonIdQuery = { __typename?: 'Query', CompletionPayments?: { __typename?: 'CompletionPaymentsConnection', edges: Array<{ __typename?: 'CompletionPaymentsEdge', node: { __typename?: 'CompletionPayment', objectId: string, objectType: CompletionPaymentObjectType, reportedDonationId?: string | null | undefined, organizationId?: string | null | undefined, paymentId?: string | null | undefined, personId?: string | null | undefined, pledgeId?: string | null | undefined, financialPeriodId?: string | null | undefined, amount: string, currencyCode: string, amountNormalized?: string | null | undefined, allocation?: unknown | null | undefined, donationDate: string, externalOrganization?: { __typename?: 'ExternalOrganization', id: string, name: string } | null | undefined, liability?: { __typename?: 'Liability', liabilityPeriod: { __typename?: 'DateRange', start?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined, end?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined } } | null | undefined } }> } | null | undefined };

export type GetFinancialPeriodsByPersonIdQueryVariables = Exact<{
  personId: Scalars['BigInt'];
}>;


export type GetFinancialPeriodsByPersonIdQuery = { __typename?: 'Query', FinancialPeriods?: { __typename?: 'FinancialPeriodsConnection', edges: Array<{ __typename?: 'FinancialPeriodsEdge', node: { __typename?: 'FinancialPeriod', id: string, personId: string, employmentStatus?: IncomeEmploymentStatus | null | undefined, donationsTaxDeductible?: boolean | null | undefined, wealthAmount?: string | null | undefined, currencyCode: string, period: { __typename?: 'DateRange', start?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined, end?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined }, incomes: { __typename?: 'IncomesConnection', edges: Array<{ __typename?: 'IncomesEdge', node: { __typename?: 'Income', nodeId: string, id: string, personId: string, amount: string, financialPeriodId: string } }> }, liabilities: { __typename?: 'LiabilitiesConnection', edges: Array<{ __typename?: 'LiabilitiesEdge', node: { __typename?: 'Liability', nodeId: string, pledgeId: string, financialPeriodId: string, personId: string, currencyCode: string, liabilityAmount: string, liabilityNormalized: string, incomeLiabilityAmount?: string | null | undefined, wealthLiabilityAmount?: string | null | undefined, pledge?: { __typename?: 'Pledge', id: string, pledgeType: PledgeType, incomePercentage: string, wealthPercentage?: string | null | undefined } | null | undefined, liabilityPeriod: { __typename?: 'DateRange', start?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined, end?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined } } }> } } }> } | null | undefined };

export type GetIncomesByPersonIdQueryVariables = Exact<{
  personId: Scalars['BigInt'];
}>;


export type GetIncomesByPersonIdQuery = { __typename?: 'Query', Incomes?: { __typename?: 'IncomesConnection', edges: Array<{ __typename?: 'IncomesEdge', node: { __typename?: 'Income', id: string, personId: string, amount: string, financialPeriod?: { __typename?: 'FinancialPeriod', id: string, currencyCode: string, period: { __typename?: 'DateRange', start?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined, end?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined } } | null | undefined } }> } | null | undefined };

export type GetLiabilitiesByPersonIdQueryVariables = Exact<{
  personId: Scalars['BigInt'];
}>;


export type GetLiabilitiesByPersonIdQuery = { __typename?: 'Query', Liabilities?: { __typename?: 'LiabilitiesConnection', edges: Array<{ __typename?: 'LiabilitiesEdge', node: { __typename?: 'Liability', personId: string, pledgeId: string, financialPeriodId: string, currencyCode: string, liabilityAmount: string, liabilityNormalized: string, liabilityPeriod: { __typename?: 'DateRange', start?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined, end?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined } } }> } | null | undefined };

export type GetLiabilityCompletionsQueryVariables = Exact<{
  pledgeId: Scalars['BigInt'];
}>;


export type GetLiabilityCompletionsQuery = { __typename?: 'Query', LiabilityCompletions?: { __typename?: 'LiabilityCompletionTsConnection', edges: Array<{ __typename?: 'LiabilityCompletionTsEdge', node: { __typename?: 'LiabilityCompletionT', personId?: string | null | undefined, pledgeId?: string | null | undefined, financialPeriodId?: string | null | undefined, currencyCode?: string | null | undefined, liabilityAmount?: string | null | undefined, incomeLiabilityAmount?: string | null | undefined, wealthLiabilityAmount?: string | null | undefined, financialPeriodWealth?: string | null | undefined, completionAmount?: string | null | undefined, completionAmountNormalized?: string | null | undefined, completionPercentage?: string | null | undefined, period?: { __typename?: 'DateRange', start?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined, end?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined } | null | undefined } }> } | null | undefined };

export type GetLiabilityTotalCompletionsQueryVariables = Exact<{
  pledgeId: Scalars['BigInt'];
}>;


export type GetLiabilityTotalCompletionsQuery = { __typename?: 'Query', LiabilityTotalCompletions?: { __typename?: 'LiabilityTotalCompletionTsConnection', edges: Array<{ __typename?: 'LiabilityTotalCompletionTsEdge', node: { __typename?: 'LiabilityTotalCompletionT', pledgeId?: string | null | undefined, currencyCode?: string | null | undefined, currencySortOrder?: string | null | undefined, liabilityAmount?: string | null | undefined, liabilityAmountNormalized?: string | null | undefined, completionAmount?: string | null | undefined, completionAmountNormalized?: string | null | undefined, completionPercentage?: string | null | undefined } }> } | null | undefined };

export type GetPledgeByPersonOrTemporaryPersonQueryVariables = Exact<{
  pledgeId: Scalars['BigInt'];
  sessionToken?: InputMaybe<Scalars['UUID']>;
}>;


export type GetPledgeByPersonOrTemporaryPersonQuery = { __typename?: 'Query', Pledge?: { __typename?: 'GetPledgeByPersonOrTemporaryPersonT', id: string, personId: string, incomePercentage: string, wealthPercentage?: string | null | undefined, pledgeType: PledgeType, motivation?: string | null | undefined, locale: string, metadata?: unknown | null | undefined, emailVerifiedAt?: string | null | undefined, email?: string | null | undefined, period: { __typename?: 'DateRange', start?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined, end?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined } } | null | undefined };

export type GetPledgeClubMembersByPersonIdQueryVariables = Exact<{
  personId: Scalars['BigInt'];
}>;


export type GetPledgeClubMembersByPersonIdQuery = { __typename?: 'Query', PledgeClubMembers?: { __typename?: 'PledgeClubMembersConnection', edges: Array<{ __typename?: 'PledgeClubMembersEdge', node: { __typename?: 'PledgeClubMember', pledgeClubSlug: string, personId: string } }> } | null | undefined };

export type GetPledgeClubsQueryVariables = Exact<{
  active?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetPledgeClubsQuery = { __typename?: 'Query', PledgeClubs?: Array<{ __typename?: 'PledgeClub', id: string, slug: string }> | null | undefined };

export type GetPledgeRanksByPersonIdQueryVariables = Exact<{
  personId: Scalars['BigInt'];
}>;


export type GetPledgeRanksByPersonIdQuery = { __typename?: 'Query', PledgeRanks?: { __typename?: 'PledgeRanksConnection', edges: Array<{ __typename?: 'PledgeRanksEdge', node: { __typename?: 'PledgeRank', createdAt: string, pledgeType: PledgeType, rankByPledgeType: number, commencementDate: string, personByPersonId?: { __typename?: 'Person', id: string, fullName?: string | null | undefined } | null | undefined } }> } | null | undefined };

export type GetPledgedMemberPublicsLatestRankQueryVariables = Exact<{
  pledgeType?: InputMaybe<PledgeType>;
}>;


export type GetPledgedMemberPublicsLatestRankQuery = { __typename?: 'Query', PledgedMembers?: Array<{ __typename?: 'PledgedMembersPublic', rankByPledgeType?: number | null | undefined }> | null | undefined };

export type GetPledgesByApprovedMotivationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPledgesByApprovedMotivationQuery = { __typename?: 'Query', Pledges?: { __typename?: 'GetPledgesByApprovedMotivationConnection', edges: Array<{ __typename?: 'GetPledgesByApprovedMotivationEdge', node: { __typename?: 'GetPledgesByApprovedMotivationRecord', motivation?: string | null | undefined, countryOfResidence?: string | null | undefined, createdAt?: string | null | undefined, personFullName?: string | null | undefined } }> } | null | undefined };

export type GetPledgesByPersonIdQueryVariables = Exact<{
  personId: Scalars['BigInt'];
}>;


export type GetPledgesByPersonIdQuery = { __typename?: 'Query', Pledges?: { __typename?: 'PledgesConnection', edges: Array<{ __typename?: 'PledgesEdge', node: { __typename?: 'Pledge', id: string, personId: string, incomePercentage: string, wealthPercentage?: string | null | undefined, pledgeType: PledgeType, motivation?: string | null | undefined, period: { __typename?: 'DateRange', start?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined, end?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined }, liabilities: { __typename?: 'LiabilitiesConnection', edges: Array<{ __typename?: 'LiabilitiesEdge', node: { __typename?: 'Liability', nodeId: string, pledgeId: string, financialPeriodId: string, personId: string, currencyCode: string, liabilityAmount: string, liabilityNormalized: string } }> } } }> } | null | undefined };

export type GetRecurringReportedDonationsByPersonIdQueryVariables = Exact<{
  personId: Scalars['BigInt'];
}>;


export type GetRecurringReportedDonationsByPersonIdQuery = { __typename?: 'Query', RecurringReportedDonations?: { __typename?: 'RecurringReportedDonationsConnection', edges: Array<{ __typename?: 'RecurringReportedDonationsEdge', node: { __typename?: 'RecurringReportedDonation', id: string, personId: string, organizationId: string, amount: string, currencyCode: string, startDate: string, nextDate: string, status: RecurringPaymentStatus, createdAt: string, updatedAt: string, externalOrganization?: { __typename?: 'ExternalOrganization', id: string, name: string, organizationSlug?: string | null | undefined, url?: string | null | undefined } | null | undefined, recurrenceInterval: { __typename?: 'Interval', years?: number | null | undefined, months?: number | null | undefined, days?: number | null | undefined, hours?: number | null | undefined, minutes?: number | null | undefined, seconds?: number | null | undefined } } }> } | null | undefined };

export type CreatePollOptionVoteByPersonOrTemporaryPersonMutationVariables = Exact<{
  pledgeId: Scalars['BigInt'];
  pollOptionId: Scalars['BigInt'];
  context: Scalars['String'];
  sessionToken?: InputMaybe<Scalars['UUID']>;
}>;


export type CreatePollOptionVoteByPersonOrTemporaryPersonMutation = { __typename?: 'Mutation', createPollOptionVoteByPersonOrTemporaryPerson?: { __typename?: 'CreatePollOptionVoteByPersonOrTemporaryPersonPayload', pollOptionVote?: { __typename?: 'PollOptionVote', pledgeId: string, pollOptionId: string, context: string } | null | undefined } | null | undefined };

export type RecordSurveyResponsesMutationVariables = Exact<{
  personId: Scalars['BigInt'];
  questionKey: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
  answerText: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
  itemId: Scalars['BigInt'];
  metadata?: InputMaybe<Scalars['JSON']>;
}>;


export type RecordSurveyResponsesMutation = { __typename?: 'Mutation', recordSurveyResponses?: { __typename?: 'RecordSurveyResponsesPayload', clientMutationId?: string | null | undefined } | null | undefined };

export type RecordSurveyResponsesByTemporaryPersonMutationVariables = Exact<{
  sessionToken: Scalars['UUID'];
  questionKey: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
  answerText: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
  itemId: Scalars['BigInt'];
  metadata?: InputMaybe<Scalars['JSON']>;
}>;


export type RecordSurveyResponsesByTemporaryPersonMutation = { __typename?: 'Mutation', recordSurveyResponsesByTemporaryPerson?: { __typename?: 'RecordSurveyResponsesByTemporaryPersonPayload', clientMutationId?: string | null | undefined } | null | undefined };

export type GetPollByPersonOrTemporaryPersonQueryVariables = Exact<{
  pollId: Scalars['BigInt'];
  sessionToken?: InputMaybe<Scalars['UUID']>;
}>;


export type GetPollByPersonOrTemporaryPersonQuery = { __typename?: 'Query', Poll?: { __typename?: 'PollPublic', id: string, voteMechanism: VoteMechanism, questionOrdering: QuestionOrdering, question: string, Options: Array<{ __typename?: 'PollOptionPublic', id: string, pollId: string, label: string, voteCount: number, Votes?: Array<{ __typename?: 'PollOptionVote', pledgeId: string, pollOptionId: string, context: string }> | null | undefined }> } | null | undefined };

export type AllCountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllCountriesQuery = { __typename?: 'Query', Countries?: { __typename?: 'CountriesConnection', edges: Array<{ __typename?: 'CountriesEdge', node: { __typename?: 'Country', name: string, isoAlpha2: string, isoAlpha3: string } }> } | null | undefined };

export type AllCountriesWithCurrenciesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllCountriesWithCurrenciesQuery = { __typename?: 'Query', Countries?: { __typename?: 'CountriesConnection', edges: Array<{ __typename?: 'CountriesEdge', node: { __typename?: 'Country', name: string, isoAlpha2: string, isoAlpha3: string, currencies: Array<{ __typename?: 'CurrencyByCountry', currencyCode: string }> } }> } | null | undefined };

export type AllCurrenciesQueryVariables = Exact<{
  currencyType?: InputMaybe<CurrencyType>;
}>;


export type AllCurrenciesQuery = { __typename?: 'Query', Currencies?: { __typename?: 'CurrenciesConnection', edges: Array<{ __typename?: 'CurrenciesEdge', node: { __typename?: 'Currency', name: string, code: string, decimals: number, symbol?: string | null | undefined, currencyType: CurrencyType } }> } | null | undefined };

export type AllFiatCurrenciesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllFiatCurrenciesQuery = { __typename?: 'Query', FiatCurrencies?: { __typename?: 'CurrenciesConnection', edges: Array<{ __typename?: 'CurrenciesEdge', node: { __typename?: 'Currency', name: string, code: string, symbol?: string | null | undefined } }> } | null | undefined };

export type AllMembersQueryVariables = Exact<{ [key: string]: never; }>;


export type AllMembersQuery = { __typename?: 'Query', Pledges?: Array<{ __typename?: 'PledgedMembersPublic', pledgeType?: PledgeType | null | undefined, active?: boolean | null | undefined, rankByPledgeType?: number | null | undefined, fullName?: string | null | undefined, commencementDate?: string | null | undefined }> | null | undefined };

export type ClosestExchangeRateQueryVariables = Exact<{
  currencyCode: Scalars['String'];
  date: Scalars['Date'];
}>;


export type ClosestExchangeRateQuery = { __typename?: 'Query', ExchangeRate?: { __typename?: 'ExchangeRate', currencyCode: string, date: string, rate?: string | null | undefined } | null | undefined };

export type GetCurrencyQueryVariables = Exact<{
  code: Scalars['String'];
}>;


export type GetCurrencyQuery = { __typename?: 'Query', Currency?: { __typename?: 'Currency', name: string, code: string, decimals: number, symbol?: string | null | undefined, currencyType: CurrencyType } | null | undefined };

export type GetStatsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStatsQuery = { __typename?: 'Query', allGlobalStatsList?: Array<{ __typename?: 'GlobalStat', gwwcPledgesTotal?: number | null | undefined, activeTryGivingPledgesTotal?: number | null | undefined, tryGivingCompletedTotal?: number | null | undefined, pledgersCountriesTotal?: number | null | undefined, pledgesDonationsTotal?: string | null | undefined, gwwcDonationsTotal?: string | null | undefined }> | null | undefined };

export type UpdateSettingsContactPreferenceMutationVariables = Exact<{
  personId: Scalars['BigInt'];
  currentAddressId?: InputMaybe<Scalars['BigInt']>;
}>;


export type UpdateSettingsContactPreferenceMutation = { __typename?: 'Mutation', updateContactPreferenceByPersonId?: { __typename?: 'UpdateContactPreferenceByPersonIdPayload', contactPreference?: { __typename?: 'ContactPreference', personId: string, currentAddressId?: string | null | undefined } | null | undefined } | null | undefined };

export type GetSettingsQueryVariables = Exact<{
  personId: Scalars['BigInt'];
}>;


export type GetSettingsQuery = { __typename?: 'Query', ContactPreference?: { __typename?: 'ContactPreference', currentAddressId?: string | null | undefined } | null | undefined };

export const LotteryFieldsFragmentDoc = gql`
    fragment LotteryFields on ExtendedLottery {
  id
  blockSize
  closeTimestamp
  lockTimestamp
  drawTimestamp
  drawEpoch
  status
  winningNumber
}
    `;
export const LotterySummaryFieldsFragmentDoc = gql`
    fragment LotterySummaryFields on LotterySummary {
  id
  blockSize
  closeTimestamp
  lockTimestamp
  drawTimestamp
  winningNumber
  winningNumberHex
  drawEpoch
  status
  ticketsAssigned
  maxBlockId
  numEntries
  entriesTotalNormalized
  benefactorLiability
  benefactorStartingTicketNumber
  benefactorWinsLastBlock
  winningTickets
}
    `;
export const PaymentFieldsFragmentDoc = gql`
    fragment PaymentFields on Payment {
  id
  personId
  chargeCurrencyCode
  chargeAmount
  currencyCode
  amount
  amountNormalized
  net
  fee
  recipient
  allocation
  gateway
  gatewayData
  gatewayTransactionId
  paymentMethod
  recurrence
  billingAddressId
  metadata
  status
  statusData
  reference
  createdAt
  updatedAt
}
    `;
export const RecurringPaymentFieldsFragmentDoc = gql`
    fragment RecurringPaymentFields on RecurringPayment {
  id
  personId
  period
  periodIndex
  periodInterval
  allocation
  amount
  currencyCode
  recipient
  gateway
  gatewayData
  paymentMethod
  billingAddressId
  metadata
  status
  statusData
  createdAt
  updatedAt
}
    `;
export const CreateExternalOrganizationDocument = gql`
    mutation createExternalOrganization($name: String!, $organizationSlug: String, $url: String, $keywords: [String]) {
  createExternalOrganization(
    input: {name: $name, organizationSlug: $organizationSlug, url: $url, keywords: $keywords}
  ) {
    externalOrganization {
      id
      name
      keywords
      organizationSlug
      url
    }
  }
}
    `;
export type CreateExternalOrganizationMutationFn = Apollo.MutationFunction<CreateExternalOrganizationMutation, CreateExternalOrganizationMutationVariables>;

/**
 * __useCreateExternalOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateExternalOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExternalOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExternalOrganizationMutation, { data, loading, error }] = useCreateExternalOrganizationMutation({
 *   variables: {
 *      name: // value for 'name'
 *      organizationSlug: // value for 'organizationSlug'
 *      url: // value for 'url'
 *      keywords: // value for 'keywords'
 *   },
 * });
 */
export function useCreateExternalOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<CreateExternalOrganizationMutation, CreateExternalOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateExternalOrganizationMutation, CreateExternalOrganizationMutationVariables>(CreateExternalOrganizationDocument, options);
      }
export type CreateExternalOrganizationMutationHookResult = ReturnType<typeof useCreateExternalOrganizationMutation>;
export type CreateExternalOrganizationMutationResult = Apollo.MutationResult<CreateExternalOrganizationMutation>;
export type CreateExternalOrganizationMutationOptions = Apollo.BaseMutationOptions<CreateExternalOrganizationMutation, CreateExternalOrganizationMutationVariables>;
export const GetExternalOrganizationTypeaheadDocument = gql`
    query getExternalOrganizationTypeahead($searchText: String!) {
  ExternalOrganizations: externalOrganizationTypeahead(
    searchText: $searchText
    first: 10
  ) {
    edges {
      node {
        id
        name
        organizationSlug
        url
      }
    }
  }
}
    `;

/**
 * __useGetExternalOrganizationTypeaheadQuery__
 *
 * To run a query within a React component, call `useGetExternalOrganizationTypeaheadQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExternalOrganizationTypeaheadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExternalOrganizationTypeaheadQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useGetExternalOrganizationTypeaheadQuery(baseOptions: Apollo.QueryHookOptions<GetExternalOrganizationTypeaheadQuery, GetExternalOrganizationTypeaheadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExternalOrganizationTypeaheadQuery, GetExternalOrganizationTypeaheadQueryVariables>(GetExternalOrganizationTypeaheadDocument, options);
      }
export function useGetExternalOrganizationTypeaheadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExternalOrganizationTypeaheadQuery, GetExternalOrganizationTypeaheadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExternalOrganizationTypeaheadQuery, GetExternalOrganizationTypeaheadQueryVariables>(GetExternalOrganizationTypeaheadDocument, options);
        }
export type GetExternalOrganizationTypeaheadQueryHookResult = ReturnType<typeof useGetExternalOrganizationTypeaheadQuery>;
export type GetExternalOrganizationTypeaheadLazyQueryHookResult = ReturnType<typeof useGetExternalOrganizationTypeaheadLazyQuery>;
export type GetExternalOrganizationTypeaheadQueryResult = Apollo.QueryResult<GetExternalOrganizationTypeaheadQuery, GetExternalOrganizationTypeaheadQueryVariables>;
export const GetOrganizationsDocument = gql`
    query getOrganizations {
  Organizations: allProgramDetailsLegacies {
    edges {
      node {
        slug
        name
        program
        type
        active
        hidden
        href
        isDataSharingPartner
      }
    }
  }
}
    `;

/**
 * __useGetOrganizationsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<GetOrganizationsQuery, GetOrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationsQuery, GetOrganizationsQueryVariables>(GetOrganizationsDocument, options);
      }
export function useGetOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationsQuery, GetOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationsQuery, GetOrganizationsQueryVariables>(GetOrganizationsDocument, options);
        }
export type GetOrganizationsQueryHookResult = ReturnType<typeof useGetOrganizationsQuery>;
export type GetOrganizationsLazyQueryHookResult = ReturnType<typeof useGetOrganizationsLazyQuery>;
export type GetOrganizationsQueryResult = Apollo.QueryResult<GetOrganizationsQuery, GetOrganizationsQueryVariables>;
export const CancelRecurringPaymentDocument = gql`
    mutation cancelRecurringPayment($recurringPaymentId: BigInt!) {
  cancelRecurringPayment(input: {recurringPaymentId: $recurringPaymentId}) {
    clientMutationId
  }
}
    `;
export type CancelRecurringPaymentMutationFn = Apollo.MutationFunction<CancelRecurringPaymentMutation, CancelRecurringPaymentMutationVariables>;

/**
 * __useCancelRecurringPaymentMutation__
 *
 * To run a mutation, you first call `useCancelRecurringPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelRecurringPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelRecurringPaymentMutation, { data, loading, error }] = useCancelRecurringPaymentMutation({
 *   variables: {
 *      recurringPaymentId: // value for 'recurringPaymentId'
 *   },
 * });
 */
export function useCancelRecurringPaymentMutation(baseOptions?: Apollo.MutationHookOptions<CancelRecurringPaymentMutation, CancelRecurringPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelRecurringPaymentMutation, CancelRecurringPaymentMutationVariables>(CancelRecurringPaymentDocument, options);
      }
export type CancelRecurringPaymentMutationHookResult = ReturnType<typeof useCancelRecurringPaymentMutation>;
export type CancelRecurringPaymentMutationResult = Apollo.MutationResult<CancelRecurringPaymentMutation>;
export type CancelRecurringPaymentMutationOptions = Apollo.BaseMutationOptions<CancelRecurringPaymentMutation, CancelRecurringPaymentMutationVariables>;
export const CreateGiftAidClaimDocument = gql`
    mutation createGiftAidClaim($paymentId: BigInt!, $addressId: BigInt!) {
  createGiftAidClaimFromPayment(
    input: {paymentId: $paymentId, addressId: $addressId}
  ) {
    giftAidClaim {
      paymentId
      personId
      addressId
      reportId
    }
  }
}
    `;
export type CreateGiftAidClaimMutationFn = Apollo.MutationFunction<CreateGiftAidClaimMutation, CreateGiftAidClaimMutationVariables>;

/**
 * __useCreateGiftAidClaimMutation__
 *
 * To run a mutation, you first call `useCreateGiftAidClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGiftAidClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGiftAidClaimMutation, { data, loading, error }] = useCreateGiftAidClaimMutation({
 *   variables: {
 *      paymentId: // value for 'paymentId'
 *      addressId: // value for 'addressId'
 *   },
 * });
 */
export function useCreateGiftAidClaimMutation(baseOptions?: Apollo.MutationHookOptions<CreateGiftAidClaimMutation, CreateGiftAidClaimMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGiftAidClaimMutation, CreateGiftAidClaimMutationVariables>(CreateGiftAidClaimDocument, options);
      }
export type CreateGiftAidClaimMutationHookResult = ReturnType<typeof useCreateGiftAidClaimMutation>;
export type CreateGiftAidClaimMutationResult = Apollo.MutationResult<CreateGiftAidClaimMutation>;
export type CreateGiftAidClaimMutationOptions = Apollo.BaseMutationOptions<CreateGiftAidClaimMutation, CreateGiftAidClaimMutationVariables>;
export const CreateGocardlessRedirectFlowDocument = gql`
    mutation createGocardlessRedirectFlow($personId: BigInt, $sessionToken: UUID) {
  mutationResult: createGocardlessRedirectFlowByPersonOrTemporaryPerson(
    input: {personId: $personId, sessionToken: $sessionToken}
  ) {
    RedirectFlow: gocardlessRedirectFlow {
      personId
      sessionToken
      gocardlessRedirectFlowId
      gocardlessRedirectFlowUrl
      expires
      createdAt
      updatedAt
    }
  }
}
    `;
export type CreateGocardlessRedirectFlowMutationFn = Apollo.MutationFunction<CreateGocardlessRedirectFlowMutation, CreateGocardlessRedirectFlowMutationVariables>;

/**
 * __useCreateGocardlessRedirectFlowMutation__
 *
 * To run a mutation, you first call `useCreateGocardlessRedirectFlowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGocardlessRedirectFlowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGocardlessRedirectFlowMutation, { data, loading, error }] = useCreateGocardlessRedirectFlowMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      sessionToken: // value for 'sessionToken'
 *   },
 * });
 */
export function useCreateGocardlessRedirectFlowMutation(baseOptions?: Apollo.MutationHookOptions<CreateGocardlessRedirectFlowMutation, CreateGocardlessRedirectFlowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGocardlessRedirectFlowMutation, CreateGocardlessRedirectFlowMutationVariables>(CreateGocardlessRedirectFlowDocument, options);
      }
export type CreateGocardlessRedirectFlowMutationHookResult = ReturnType<typeof useCreateGocardlessRedirectFlowMutation>;
export type CreateGocardlessRedirectFlowMutationResult = Apollo.MutationResult<CreateGocardlessRedirectFlowMutation>;
export type CreateGocardlessRedirectFlowMutationOptions = Apollo.BaseMutationOptions<CreateGocardlessRedirectFlowMutation, CreateGocardlessRedirectFlowMutationVariables>;
export const CreatePaymentDocument = gql`
    mutation createPayment($personId: BigInt!, $currencyCode: String!, $amount: BigFloat!, $recipient: String!, $allocation: JSON!, $gateway: PaymentGateway!, $gatewayData: JSON, $paymentMethod: PaymentMethod!, $billingAddressId: BigInt, $metadata: JSON, $status: PaymentStatus, $createdAt: Datetime) {
  mutationResult: createPaymentByPersonId(
    input: {personId: $personId, currencyCode: $currencyCode, amount: $amount, recipient: $recipient, allocation: $allocation, gateway: $gateway, gatewayData: $gatewayData, paymentMethod: $paymentMethod, billingAddressId: $billingAddressId, metadata: $metadata, status: $status, createdAt: $createdAt}
  ) {
    payment {
      ...PaymentFields
    }
  }
}
    ${PaymentFieldsFragmentDoc}`;
export type CreatePaymentMutationFn = Apollo.MutationFunction<CreatePaymentMutation, CreatePaymentMutationVariables>;

/**
 * __useCreatePaymentMutation__
 *
 * To run a mutation, you first call `useCreatePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentMutation, { data, loading, error }] = useCreatePaymentMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      currencyCode: // value for 'currencyCode'
 *      amount: // value for 'amount'
 *      recipient: // value for 'recipient'
 *      allocation: // value for 'allocation'
 *      gateway: // value for 'gateway'
 *      gatewayData: // value for 'gatewayData'
 *      paymentMethod: // value for 'paymentMethod'
 *      billingAddressId: // value for 'billingAddressId'
 *      metadata: // value for 'metadata'
 *      status: // value for 'status'
 *      createdAt: // value for 'createdAt'
 *   },
 * });
 */
export function useCreatePaymentMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentMutation, CreatePaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaymentMutation, CreatePaymentMutationVariables>(CreatePaymentDocument, options);
      }
export type CreatePaymentMutationHookResult = ReturnType<typeof useCreatePaymentMutation>;
export type CreatePaymentMutationResult = Apollo.MutationResult<CreatePaymentMutation>;
export type CreatePaymentMutationOptions = Apollo.BaseMutationOptions<CreatePaymentMutation, CreatePaymentMutationVariables>;
export const CreatePaymentByTemporaryPersonDocument = gql`
    mutation createPaymentByTemporaryPerson($sessionToken: UUID!, $currencyCode: String!, $amount: BigFloat!, $recipient: String!, $allocation: JSON!, $gateway: PaymentGateway!, $gatewayData: JSON, $paymentMethod: PaymentMethod!, $billingAddressId: BigInt, $metadata: JSON) {
  mutationResult: createPaymentByTemporaryPerson(
    input: {sessionToken: $sessionToken, currencyCode: $currencyCode, amount: $amount, recipient: $recipient, allocation: $allocation, gateway: $gateway, gatewayData: $gatewayData, paymentMethod: $paymentMethod, billingAddressId: $billingAddressId, metadata: $metadata}
  ) {
    payment {
      ...PaymentFields
    }
  }
}
    ${PaymentFieldsFragmentDoc}`;
export type CreatePaymentByTemporaryPersonMutationFn = Apollo.MutationFunction<CreatePaymentByTemporaryPersonMutation, CreatePaymentByTemporaryPersonMutationVariables>;

/**
 * __useCreatePaymentByTemporaryPersonMutation__
 *
 * To run a mutation, you first call `useCreatePaymentByTemporaryPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentByTemporaryPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentByTemporaryPersonMutation, { data, loading, error }] = useCreatePaymentByTemporaryPersonMutation({
 *   variables: {
 *      sessionToken: // value for 'sessionToken'
 *      currencyCode: // value for 'currencyCode'
 *      amount: // value for 'amount'
 *      recipient: // value for 'recipient'
 *      allocation: // value for 'allocation'
 *      gateway: // value for 'gateway'
 *      gatewayData: // value for 'gatewayData'
 *      paymentMethod: // value for 'paymentMethod'
 *      billingAddressId: // value for 'billingAddressId'
 *      metadata: // value for 'metadata'
 *   },
 * });
 */
export function useCreatePaymentByTemporaryPersonMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentByTemporaryPersonMutation, CreatePaymentByTemporaryPersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaymentByTemporaryPersonMutation, CreatePaymentByTemporaryPersonMutationVariables>(CreatePaymentByTemporaryPersonDocument, options);
      }
export type CreatePaymentByTemporaryPersonMutationHookResult = ReturnType<typeof useCreatePaymentByTemporaryPersonMutation>;
export type CreatePaymentByTemporaryPersonMutationResult = Apollo.MutationResult<CreatePaymentByTemporaryPersonMutation>;
export type CreatePaymentByTemporaryPersonMutationOptions = Apollo.BaseMutationOptions<CreatePaymentByTemporaryPersonMutation, CreatePaymentByTemporaryPersonMutationVariables>;
export const CreateRecurringPaymentDocument = gql`
    mutation createRecurringPayment($personId: BigInt!, $period: RecurrencePeriod!, $periodIndex: Int!, $allocation: JSON!, $amount: BigFloat!, $currencyCode: String!, $recipient: String!, $gateway: PaymentGateway!, $gatewayData: JSON, $paymentMethod: PaymentMethod!, $billingAddressId: BigInt, $metadata: JSON) {
  mutationResult: createRecurringPayment(
    input: {personId: $personId, period: $period, periodIndex: $periodIndex, allocation: $allocation, amount: $amount, currencyCode: $currencyCode, recipient: $recipient, gateway: $gateway, gatewayData: $gatewayData, paymentMethod: $paymentMethod, billingAddressId: $billingAddressId, metadata: $metadata}
  ) {
    recurringPayment {
      ...RecurringPaymentFields
    }
  }
}
    ${RecurringPaymentFieldsFragmentDoc}`;
export type CreateRecurringPaymentMutationFn = Apollo.MutationFunction<CreateRecurringPaymentMutation, CreateRecurringPaymentMutationVariables>;

/**
 * __useCreateRecurringPaymentMutation__
 *
 * To run a mutation, you first call `useCreateRecurringPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecurringPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecurringPaymentMutation, { data, loading, error }] = useCreateRecurringPaymentMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      period: // value for 'period'
 *      periodIndex: // value for 'periodIndex'
 *      allocation: // value for 'allocation'
 *      amount: // value for 'amount'
 *      currencyCode: // value for 'currencyCode'
 *      recipient: // value for 'recipient'
 *      gateway: // value for 'gateway'
 *      gatewayData: // value for 'gatewayData'
 *      paymentMethod: // value for 'paymentMethod'
 *      billingAddressId: // value for 'billingAddressId'
 *      metadata: // value for 'metadata'
 *   },
 * });
 */
export function useCreateRecurringPaymentMutation(baseOptions?: Apollo.MutationHookOptions<CreateRecurringPaymentMutation, CreateRecurringPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRecurringPaymentMutation, CreateRecurringPaymentMutationVariables>(CreateRecurringPaymentDocument, options);
      }
export type CreateRecurringPaymentMutationHookResult = ReturnType<typeof useCreateRecurringPaymentMutation>;
export type CreateRecurringPaymentMutationResult = Apollo.MutationResult<CreateRecurringPaymentMutation>;
export type CreateRecurringPaymentMutationOptions = Apollo.BaseMutationOptions<CreateRecurringPaymentMutation, CreateRecurringPaymentMutationVariables>;
export const CreateRecurringPaymentByTemporaryPersonDocument = gql`
    mutation createRecurringPaymentByTemporaryPerson($sessionToken: UUID!, $period: RecurrencePeriod!, $periodIndex: Int!, $allocation: JSON!, $amount: BigFloat!, $currencyCode: String!, $recipient: String!, $gateway: PaymentGateway!, $gatewayData: JSON, $paymentMethod: PaymentMethod!, $billingAddressId: BigInt, $metadata: JSON) {
  mutationResult: createRecurringPaymentByTemporaryPerson(
    input: {sessionToken: $sessionToken, period: $period, periodIndex: $periodIndex, allocation: $allocation, amount: $amount, currencyCode: $currencyCode, recipient: $recipient, gateway: $gateway, gatewayData: $gatewayData, paymentMethod: $paymentMethod, billingAddressId: $billingAddressId, metadata: $metadata}
  ) {
    recurringPayment {
      ...RecurringPaymentFields
    }
  }
}
    ${RecurringPaymentFieldsFragmentDoc}`;
export type CreateRecurringPaymentByTemporaryPersonMutationFn = Apollo.MutationFunction<CreateRecurringPaymentByTemporaryPersonMutation, CreateRecurringPaymentByTemporaryPersonMutationVariables>;

/**
 * __useCreateRecurringPaymentByTemporaryPersonMutation__
 *
 * To run a mutation, you first call `useCreateRecurringPaymentByTemporaryPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecurringPaymentByTemporaryPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecurringPaymentByTemporaryPersonMutation, { data, loading, error }] = useCreateRecurringPaymentByTemporaryPersonMutation({
 *   variables: {
 *      sessionToken: // value for 'sessionToken'
 *      period: // value for 'period'
 *      periodIndex: // value for 'periodIndex'
 *      allocation: // value for 'allocation'
 *      amount: // value for 'amount'
 *      currencyCode: // value for 'currencyCode'
 *      recipient: // value for 'recipient'
 *      gateway: // value for 'gateway'
 *      gatewayData: // value for 'gatewayData'
 *      paymentMethod: // value for 'paymentMethod'
 *      billingAddressId: // value for 'billingAddressId'
 *      metadata: // value for 'metadata'
 *   },
 * });
 */
export function useCreateRecurringPaymentByTemporaryPersonMutation(baseOptions?: Apollo.MutationHookOptions<CreateRecurringPaymentByTemporaryPersonMutation, CreateRecurringPaymentByTemporaryPersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRecurringPaymentByTemporaryPersonMutation, CreateRecurringPaymentByTemporaryPersonMutationVariables>(CreateRecurringPaymentByTemporaryPersonDocument, options);
      }
export type CreateRecurringPaymentByTemporaryPersonMutationHookResult = ReturnType<typeof useCreateRecurringPaymentByTemporaryPersonMutation>;
export type CreateRecurringPaymentByTemporaryPersonMutationResult = Apollo.MutationResult<CreateRecurringPaymentByTemporaryPersonMutation>;
export type CreateRecurringPaymentByTemporaryPersonMutationOptions = Apollo.BaseMutationOptions<CreateRecurringPaymentByTemporaryPersonMutation, CreateRecurringPaymentByTemporaryPersonMutationVariables>;
export const DeleteGiftAidClaimDocument = gql`
    mutation deleteGiftAidClaim($paymentId: BigInt!) {
  GiftAidClaim: deleteGiftAidClaimFromPayment(input: {paymentId: $paymentId}) {
    giftAidClaim {
      paymentId
      personId
      addressId
      reportId
    }
  }
}
    `;
export type DeleteGiftAidClaimMutationFn = Apollo.MutationFunction<DeleteGiftAidClaimMutation, DeleteGiftAidClaimMutationVariables>;

/**
 * __useDeleteGiftAidClaimMutation__
 *
 * To run a mutation, you first call `useDeleteGiftAidClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGiftAidClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGiftAidClaimMutation, { data, loading, error }] = useDeleteGiftAidClaimMutation({
 *   variables: {
 *      paymentId: // value for 'paymentId'
 *   },
 * });
 */
export function useDeleteGiftAidClaimMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGiftAidClaimMutation, DeleteGiftAidClaimMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGiftAidClaimMutation, DeleteGiftAidClaimMutationVariables>(DeleteGiftAidClaimDocument, options);
      }
export type DeleteGiftAidClaimMutationHookResult = ReturnType<typeof useDeleteGiftAidClaimMutation>;
export type DeleteGiftAidClaimMutationResult = Apollo.MutationResult<DeleteGiftAidClaimMutation>;
export type DeleteGiftAidClaimMutationOptions = Apollo.BaseMutationOptions<DeleteGiftAidClaimMutation, DeleteGiftAidClaimMutationVariables>;
export const UpdateRecurringPaymentAmountDocument = gql`
    mutation updateRecurringPaymentAmount($recurringPaymentId: BigInt!, $amount: BigFloat!) {
  updateRecurringPaymentAmount(
    input: {recurringPaymentId: $recurringPaymentId, amount: $amount}
  ) {
    clientMutationId
  }
}
    `;
export type UpdateRecurringPaymentAmountMutationFn = Apollo.MutationFunction<UpdateRecurringPaymentAmountMutation, UpdateRecurringPaymentAmountMutationVariables>;

/**
 * __useUpdateRecurringPaymentAmountMutation__
 *
 * To run a mutation, you first call `useUpdateRecurringPaymentAmountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecurringPaymentAmountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecurringPaymentAmountMutation, { data, loading, error }] = useUpdateRecurringPaymentAmountMutation({
 *   variables: {
 *      recurringPaymentId: // value for 'recurringPaymentId'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useUpdateRecurringPaymentAmountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRecurringPaymentAmountMutation, UpdateRecurringPaymentAmountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRecurringPaymentAmountMutation, UpdateRecurringPaymentAmountMutationVariables>(UpdateRecurringPaymentAmountDocument, options);
      }
export type UpdateRecurringPaymentAmountMutationHookResult = ReturnType<typeof useUpdateRecurringPaymentAmountMutation>;
export type UpdateRecurringPaymentAmountMutationResult = Apollo.MutationResult<UpdateRecurringPaymentAmountMutation>;
export type UpdateRecurringPaymentAmountMutationOptions = Apollo.BaseMutationOptions<UpdateRecurringPaymentAmountMutation, UpdateRecurringPaymentAmountMutationVariables>;
export const VoidPaymentDocument = gql`
    mutation voidPayment($paymentId: BigInt!) {
  voidPayment(input: {paymentId: $paymentId}) {
    payment {
      id
      status
    }
  }
}
    `;
export type VoidPaymentMutationFn = Apollo.MutationFunction<VoidPaymentMutation, VoidPaymentMutationVariables>;

/**
 * __useVoidPaymentMutation__
 *
 * To run a mutation, you first call `useVoidPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoidPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voidPaymentMutation, { data, loading, error }] = useVoidPaymentMutation({
 *   variables: {
 *      paymentId: // value for 'paymentId'
 *   },
 * });
 */
export function useVoidPaymentMutation(baseOptions?: Apollo.MutationHookOptions<VoidPaymentMutation, VoidPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VoidPaymentMutation, VoidPaymentMutationVariables>(VoidPaymentDocument, options);
      }
export type VoidPaymentMutationHookResult = ReturnType<typeof useVoidPaymentMutation>;
export type VoidPaymentMutationResult = Apollo.MutationResult<VoidPaymentMutation>;
export type VoidPaymentMutationOptions = Apollo.BaseMutationOptions<VoidPaymentMutation, VoidPaymentMutationVariables>;
export const GetAllRecipientProgramsDocument = gql`
    query getAllRecipientPrograms {
  RecipientPrograms: allActiveRecipientProgramsList {
    organizationSlug
    recipientSlug
  }
}
    `;

/**
 * __useGetAllRecipientProgramsQuery__
 *
 * To run a query within a React component, call `useGetAllRecipientProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRecipientProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRecipientProgramsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllRecipientProgramsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllRecipientProgramsQuery, GetAllRecipientProgramsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllRecipientProgramsQuery, GetAllRecipientProgramsQueryVariables>(GetAllRecipientProgramsDocument, options);
      }
export function useGetAllRecipientProgramsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllRecipientProgramsQuery, GetAllRecipientProgramsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllRecipientProgramsQuery, GetAllRecipientProgramsQueryVariables>(GetAllRecipientProgramsDocument, options);
        }
export type GetAllRecipientProgramsQueryHookResult = ReturnType<typeof useGetAllRecipientProgramsQuery>;
export type GetAllRecipientProgramsLazyQueryHookResult = ReturnType<typeof useGetAllRecipientProgramsLazyQuery>;
export type GetAllRecipientProgramsQueryResult = Apollo.QueryResult<GetAllRecipientProgramsQuery, GetAllRecipientProgramsQueryVariables>;
export const GetFundraiserStatsDocument = gql`
    query getFundraiserStats($fundraiserId: String!, $currencyCode: String!) {
  FundraiserStats: getFundraiserStats(
    fundraiserId: $fundraiserId
    currencyCode: $currencyCode
  ) {
    edges {
      node {
        fundraiserId
        amountRaisedNormalized
        numDonors
      }
    }
  }
}
    `;

/**
 * __useGetFundraiserStatsQuery__
 *
 * To run a query within a React component, call `useGetFundraiserStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFundraiserStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFundraiserStatsQuery({
 *   variables: {
 *      fundraiserId: // value for 'fundraiserId'
 *      currencyCode: // value for 'currencyCode'
 *   },
 * });
 */
export function useGetFundraiserStatsQuery(baseOptions: Apollo.QueryHookOptions<GetFundraiserStatsQuery, GetFundraiserStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFundraiserStatsQuery, GetFundraiserStatsQueryVariables>(GetFundraiserStatsDocument, options);
      }
export function useGetFundraiserStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFundraiserStatsQuery, GetFundraiserStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFundraiserStatsQuery, GetFundraiserStatsQueryVariables>(GetFundraiserStatsDocument, options);
        }
export type GetFundraiserStatsQueryHookResult = ReturnType<typeof useGetFundraiserStatsQuery>;
export type GetFundraiserStatsLazyQueryHookResult = ReturnType<typeof useGetFundraiserStatsLazyQuery>;
export type GetFundraiserStatsQueryResult = Apollo.QueryResult<GetFundraiserStatsQuery, GetFundraiserStatsQueryVariables>;
export const GetGocardlessMandatesDocument = gql`
    query getGocardlessMandates($personId: BigInt, $sessionToken: UUID) {
  gocardlessMandates: getGocardlessMandatesByPersonOrTemporaryPerson(
    personId: $personId
    sessionToken: $sessionToken
  ) {
    edges {
      node {
        personId
        gocardlessCustomerId
        gocardlessMandateId
        status
        gocardlessBankAccountId
        gocardlessBankAccountHolderName
        gocardlessBankAccountBankName
        gocardlessBankAccountNumberEnding
      }
    }
  }
}
    `;

/**
 * __useGetGocardlessMandatesQuery__
 *
 * To run a query within a React component, call `useGetGocardlessMandatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGocardlessMandatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGocardlessMandatesQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *      sessionToken: // value for 'sessionToken'
 *   },
 * });
 */
export function useGetGocardlessMandatesQuery(baseOptions?: Apollo.QueryHookOptions<GetGocardlessMandatesQuery, GetGocardlessMandatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGocardlessMandatesQuery, GetGocardlessMandatesQueryVariables>(GetGocardlessMandatesDocument, options);
      }
export function useGetGocardlessMandatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGocardlessMandatesQuery, GetGocardlessMandatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGocardlessMandatesQuery, GetGocardlessMandatesQueryVariables>(GetGocardlessMandatesDocument, options);
        }
export type GetGocardlessMandatesQueryHookResult = ReturnType<typeof useGetGocardlessMandatesQuery>;
export type GetGocardlessMandatesLazyQueryHookResult = ReturnType<typeof useGetGocardlessMandatesLazyQuery>;
export type GetGocardlessMandatesQueryResult = Apollo.QueryResult<GetGocardlessMandatesQuery, GetGocardlessMandatesQueryVariables>;
export const GetGocardlessRedirectFlowDocument = gql`
    query getGocardlessRedirectFlow($personId: BigInt, $sessionToken: UUID) {
  GocardlessRedirectFlow: getGocardlessRedirectFlowByPersonOrTemporaryPerson(
    personId: $personId
    sessionToken: $sessionToken
  ) {
    personId
    sessionToken
    gocardlessRedirectFlowId
    gocardlessRedirectFlowUrl
    expires
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetGocardlessRedirectFlowQuery__
 *
 * To run a query within a React component, call `useGetGocardlessRedirectFlowQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGocardlessRedirectFlowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGocardlessRedirectFlowQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *      sessionToken: // value for 'sessionToken'
 *   },
 * });
 */
export function useGetGocardlessRedirectFlowQuery(baseOptions?: Apollo.QueryHookOptions<GetGocardlessRedirectFlowQuery, GetGocardlessRedirectFlowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGocardlessRedirectFlowQuery, GetGocardlessRedirectFlowQueryVariables>(GetGocardlessRedirectFlowDocument, options);
      }
export function useGetGocardlessRedirectFlowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGocardlessRedirectFlowQuery, GetGocardlessRedirectFlowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGocardlessRedirectFlowQuery, GetGocardlessRedirectFlowQueryVariables>(GetGocardlessRedirectFlowDocument, options);
        }
export type GetGocardlessRedirectFlowQueryHookResult = ReturnType<typeof useGetGocardlessRedirectFlowQuery>;
export type GetGocardlessRedirectFlowLazyQueryHookResult = ReturnType<typeof useGetGocardlessRedirectFlowLazyQuery>;
export type GetGocardlessRedirectFlowQueryResult = Apollo.QueryResult<GetGocardlessRedirectFlowQuery, GetGocardlessRedirectFlowQueryVariables>;
export const GetLotteryByIdDocument = gql`
    query getLotteryById($lotteryId: BigInt!) {
  Lottery: extendedLotteryById(id: $lotteryId) {
    ...LotteryFields
  }
}
    ${LotteryFieldsFragmentDoc}`;

/**
 * __useGetLotteryByIdQuery__
 *
 * To run a query within a React component, call `useGetLotteryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLotteryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLotteryByIdQuery({
 *   variables: {
 *      lotteryId: // value for 'lotteryId'
 *   },
 * });
 */
export function useGetLotteryByIdQuery(baseOptions: Apollo.QueryHookOptions<GetLotteryByIdQuery, GetLotteryByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLotteryByIdQuery, GetLotteryByIdQueryVariables>(GetLotteryByIdDocument, options);
      }
export function useGetLotteryByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLotteryByIdQuery, GetLotteryByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLotteryByIdQuery, GetLotteryByIdQueryVariables>(GetLotteryByIdDocument, options);
        }
export type GetLotteryByIdQueryHookResult = ReturnType<typeof useGetLotteryByIdQuery>;
export type GetLotteryByIdLazyQueryHookResult = ReturnType<typeof useGetLotteryByIdLazyQuery>;
export type GetLotteryByIdQueryResult = Apollo.QueryResult<GetLotteryByIdQuery, GetLotteryByIdQueryVariables>;
export const GetLotteryEntriesCompleteDocument = gql`
    query getLotteryEntriesComplete($lotteryId: BigInt!) {
  LotteryEntriesComplete: getLotteryEntriesCompleteByLotteryId(
    lotteryId: $lotteryId
  ) {
    edges {
      node {
        lotteryId
        paymentReference
        amountNormalized
        displayName
        tickets
        createdAt
        benefactor
      }
    }
  }
}
    `;

/**
 * __useGetLotteryEntriesCompleteQuery__
 *
 * To run a query within a React component, call `useGetLotteryEntriesCompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLotteryEntriesCompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLotteryEntriesCompleteQuery({
 *   variables: {
 *      lotteryId: // value for 'lotteryId'
 *   },
 * });
 */
export function useGetLotteryEntriesCompleteQuery(baseOptions: Apollo.QueryHookOptions<GetLotteryEntriesCompleteQuery, GetLotteryEntriesCompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLotteryEntriesCompleteQuery, GetLotteryEntriesCompleteQueryVariables>(GetLotteryEntriesCompleteDocument, options);
      }
export function useGetLotteryEntriesCompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLotteryEntriesCompleteQuery, GetLotteryEntriesCompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLotteryEntriesCompleteQuery, GetLotteryEntriesCompleteQueryVariables>(GetLotteryEntriesCompleteDocument, options);
        }
export type GetLotteryEntriesCompleteQueryHookResult = ReturnType<typeof useGetLotteryEntriesCompleteQuery>;
export type GetLotteryEntriesCompleteLazyQueryHookResult = ReturnType<typeof useGetLotteryEntriesCompleteLazyQuery>;
export type GetLotteryEntriesCompleteQueryResult = Apollo.QueryResult<GetLotteryEntriesCompleteQuery, GetLotteryEntriesCompleteQueryVariables>;
export const GetLotterySummariesDocument = gql`
    query getLotterySummaries {
  Lotteries: allLotterySummaries(orderBy: [DRAW_TIMESTAMP_DESC, BLOCK_SIZE_ASC]) {
    edges {
      node {
        ...LotterySummaryFields
      }
    }
  }
}
    ${LotterySummaryFieldsFragmentDoc}`;

/**
 * __useGetLotterySummariesQuery__
 *
 * To run a query within a React component, call `useGetLotterySummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLotterySummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLotterySummariesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLotterySummariesQuery(baseOptions?: Apollo.QueryHookOptions<GetLotterySummariesQuery, GetLotterySummariesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLotterySummariesQuery, GetLotterySummariesQueryVariables>(GetLotterySummariesDocument, options);
      }
export function useGetLotterySummariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLotterySummariesQuery, GetLotterySummariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLotterySummariesQuery, GetLotterySummariesQueryVariables>(GetLotterySummariesDocument, options);
        }
export type GetLotterySummariesQueryHookResult = ReturnType<typeof useGetLotterySummariesQuery>;
export type GetLotterySummariesLazyQueryHookResult = ReturnType<typeof useGetLotterySummariesLazyQuery>;
export type GetLotterySummariesQueryResult = Apollo.QueryResult<GetLotterySummariesQuery, GetLotterySummariesQueryVariables>;
export const GetLotterySummaryDocument = gql`
    query getLotterySummary($lotteryId: BigInt!) {
  LotterySummary: getLotterySummaryByLotteryId(lotteryId: $lotteryId) {
    ...LotterySummaryFields
  }
}
    ${LotterySummaryFieldsFragmentDoc}`;

/**
 * __useGetLotterySummaryQuery__
 *
 * To run a query within a React component, call `useGetLotterySummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLotterySummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLotterySummaryQuery({
 *   variables: {
 *      lotteryId: // value for 'lotteryId'
 *   },
 * });
 */
export function useGetLotterySummaryQuery(baseOptions: Apollo.QueryHookOptions<GetLotterySummaryQuery, GetLotterySummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLotterySummaryQuery, GetLotterySummaryQueryVariables>(GetLotterySummaryDocument, options);
      }
export function useGetLotterySummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLotterySummaryQuery, GetLotterySummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLotterySummaryQuery, GetLotterySummaryQueryVariables>(GetLotterySummaryDocument, options);
        }
export type GetLotterySummaryQueryHookResult = ReturnType<typeof useGetLotterySummaryQuery>;
export type GetLotterySummaryLazyQueryHookResult = ReturnType<typeof useGetLotterySummaryLazyQuery>;
export type GetLotterySummaryQueryResult = Apollo.QueryResult<GetLotterySummaryQuery, GetLotterySummaryQueryVariables>;
export const GetPaymentByPersonOrTemporaryPersonDocument = gql`
    query getPaymentByPersonOrTemporaryPerson($paymentId: BigInt!, $sessionToken: UUID) {
  Payment: getPaymentByPersonOrTemporaryPerson(
    paymentId: $paymentId
    sessionToken: $sessionToken
  ) {
    ...PaymentFields
  }
}
    ${PaymentFieldsFragmentDoc}`;

/**
 * __useGetPaymentByPersonOrTemporaryPersonQuery__
 *
 * To run a query within a React component, call `useGetPaymentByPersonOrTemporaryPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentByPersonOrTemporaryPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentByPersonOrTemporaryPersonQuery({
 *   variables: {
 *      paymentId: // value for 'paymentId'
 *      sessionToken: // value for 'sessionToken'
 *   },
 * });
 */
export function useGetPaymentByPersonOrTemporaryPersonQuery(baseOptions: Apollo.QueryHookOptions<GetPaymentByPersonOrTemporaryPersonQuery, GetPaymentByPersonOrTemporaryPersonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentByPersonOrTemporaryPersonQuery, GetPaymentByPersonOrTemporaryPersonQueryVariables>(GetPaymentByPersonOrTemporaryPersonDocument, options);
      }
export function useGetPaymentByPersonOrTemporaryPersonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentByPersonOrTemporaryPersonQuery, GetPaymentByPersonOrTemporaryPersonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentByPersonOrTemporaryPersonQuery, GetPaymentByPersonOrTemporaryPersonQueryVariables>(GetPaymentByPersonOrTemporaryPersonDocument, options);
        }
export type GetPaymentByPersonOrTemporaryPersonQueryHookResult = ReturnType<typeof useGetPaymentByPersonOrTemporaryPersonQuery>;
export type GetPaymentByPersonOrTemporaryPersonLazyQueryHookResult = ReturnType<typeof useGetPaymentByPersonOrTemporaryPersonLazyQuery>;
export type GetPaymentByPersonOrTemporaryPersonQueryResult = Apollo.QueryResult<GetPaymentByPersonOrTemporaryPersonQuery, GetPaymentByPersonOrTemporaryPersonQueryVariables>;
export const GetPaymentsDocument = gql`
    query getPayments($personId: BigInt!) {
  Payments: paymentsByPersonId(personId: $personId) {
    edges {
      node {
        ...PaymentFields
        giftAidClaimsByPaymentIdAndPersonId {
          edges {
            node {
              nodeId
              paymentId
              personId
              addressId
              reportId
            }
          }
        }
      }
    }
  }
}
    ${PaymentFieldsFragmentDoc}`;

/**
 * __useGetPaymentsQuery__
 *
 * To run a query within a React component, call `useGetPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentsQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetPaymentsQuery(baseOptions: Apollo.QueryHookOptions<GetPaymentsQuery, GetPaymentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentsQuery, GetPaymentsQueryVariables>(GetPaymentsDocument, options);
      }
export function useGetPaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentsQuery, GetPaymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentsQuery, GetPaymentsQueryVariables>(GetPaymentsDocument, options);
        }
export type GetPaymentsQueryHookResult = ReturnType<typeof useGetPaymentsQuery>;
export type GetPaymentsLazyQueryHookResult = ReturnType<typeof useGetPaymentsLazyQuery>;
export type GetPaymentsQueryResult = Apollo.QueryResult<GetPaymentsQuery, GetPaymentsQueryVariables>;
export const GetRecurringPaymentDocument = gql`
    query getRecurringPayment($id: BigInt!) {
  recurringPayment: recurringPaymentById(id: $id) {
    ...RecurringPaymentFields
  }
}
    ${RecurringPaymentFieldsFragmentDoc}`;

/**
 * __useGetRecurringPaymentQuery__
 *
 * To run a query within a React component, call `useGetRecurringPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecurringPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecurringPaymentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRecurringPaymentQuery(baseOptions: Apollo.QueryHookOptions<GetRecurringPaymentQuery, GetRecurringPaymentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecurringPaymentQuery, GetRecurringPaymentQueryVariables>(GetRecurringPaymentDocument, options);
      }
export function useGetRecurringPaymentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecurringPaymentQuery, GetRecurringPaymentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecurringPaymentQuery, GetRecurringPaymentQueryVariables>(GetRecurringPaymentDocument, options);
        }
export type GetRecurringPaymentQueryHookResult = ReturnType<typeof useGetRecurringPaymentQuery>;
export type GetRecurringPaymentLazyQueryHookResult = ReturnType<typeof useGetRecurringPaymentLazyQuery>;
export type GetRecurringPaymentQueryResult = Apollo.QueryResult<GetRecurringPaymentQuery, GetRecurringPaymentQueryVariables>;
export const GetRecurringPaymentByPersonOrTemporaryPersonDocument = gql`
    query getRecurringPaymentByPersonOrTemporaryPerson($id: BigInt, $sessionToken: UUID) {
  recurringPayment: getRecurringPaymentByPersonOrTemporaryPerson(
    recurringPaymentId: $id
    sessionToken: $sessionToken
  ) {
    ...RecurringPaymentFields
  }
}
    ${RecurringPaymentFieldsFragmentDoc}`;

/**
 * __useGetRecurringPaymentByPersonOrTemporaryPersonQuery__
 *
 * To run a query within a React component, call `useGetRecurringPaymentByPersonOrTemporaryPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecurringPaymentByPersonOrTemporaryPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecurringPaymentByPersonOrTemporaryPersonQuery({
 *   variables: {
 *      id: // value for 'id'
 *      sessionToken: // value for 'sessionToken'
 *   },
 * });
 */
export function useGetRecurringPaymentByPersonOrTemporaryPersonQuery(baseOptions?: Apollo.QueryHookOptions<GetRecurringPaymentByPersonOrTemporaryPersonQuery, GetRecurringPaymentByPersonOrTemporaryPersonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecurringPaymentByPersonOrTemporaryPersonQuery, GetRecurringPaymentByPersonOrTemporaryPersonQueryVariables>(GetRecurringPaymentByPersonOrTemporaryPersonDocument, options);
      }
export function useGetRecurringPaymentByPersonOrTemporaryPersonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecurringPaymentByPersonOrTemporaryPersonQuery, GetRecurringPaymentByPersonOrTemporaryPersonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecurringPaymentByPersonOrTemporaryPersonQuery, GetRecurringPaymentByPersonOrTemporaryPersonQueryVariables>(GetRecurringPaymentByPersonOrTemporaryPersonDocument, options);
        }
export type GetRecurringPaymentByPersonOrTemporaryPersonQueryHookResult = ReturnType<typeof useGetRecurringPaymentByPersonOrTemporaryPersonQuery>;
export type GetRecurringPaymentByPersonOrTemporaryPersonLazyQueryHookResult = ReturnType<typeof useGetRecurringPaymentByPersonOrTemporaryPersonLazyQuery>;
export type GetRecurringPaymentByPersonOrTemporaryPersonQueryResult = Apollo.QueryResult<GetRecurringPaymentByPersonOrTemporaryPersonQuery, GetRecurringPaymentByPersonOrTemporaryPersonQueryVariables>;
export const GetRecurringPaymentsDocument = gql`
    query getRecurringPayments($personId: BigInt!) {
  RecurringPayments: recurringPaymentsByPersonId(personId: $personId) {
    edges {
      node {
        ...RecurringPaymentFields
      }
    }
  }
}
    ${RecurringPaymentFieldsFragmentDoc}`;

/**
 * __useGetRecurringPaymentsQuery__
 *
 * To run a query within a React component, call `useGetRecurringPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecurringPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecurringPaymentsQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetRecurringPaymentsQuery(baseOptions: Apollo.QueryHookOptions<GetRecurringPaymentsQuery, GetRecurringPaymentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecurringPaymentsQuery, GetRecurringPaymentsQueryVariables>(GetRecurringPaymentsDocument, options);
      }
export function useGetRecurringPaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecurringPaymentsQuery, GetRecurringPaymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecurringPaymentsQuery, GetRecurringPaymentsQueryVariables>(GetRecurringPaymentsDocument, options);
        }
export type GetRecurringPaymentsQueryHookResult = ReturnType<typeof useGetRecurringPaymentsQuery>;
export type GetRecurringPaymentsLazyQueryHookResult = ReturnType<typeof useGetRecurringPaymentsLazyQuery>;
export type GetRecurringPaymentsQueryResult = Apollo.QueryResult<GetRecurringPaymentsQuery, GetRecurringPaymentsQueryVariables>;
export const GetTotalPledgeSignupsDocument = gql`
    query getTotalPledgeSignups($startDate: Date!, $excludeTrialPledges: Boolean = false) {
  TotalPledgeSignups: getTotalPledgeSignups(
    startDate: $startDate
    excludeTrialPledges: $excludeTrialPledges
  )
}
    `;

/**
 * __useGetTotalPledgeSignupsQuery__
 *
 * To run a query within a React component, call `useGetTotalPledgeSignupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTotalPledgeSignupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTotalPledgeSignupsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      excludeTrialPledges: // value for 'excludeTrialPledges'
 *   },
 * });
 */
export function useGetTotalPledgeSignupsQuery(baseOptions: Apollo.QueryHookOptions<GetTotalPledgeSignupsQuery, GetTotalPledgeSignupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTotalPledgeSignupsQuery, GetTotalPledgeSignupsQueryVariables>(GetTotalPledgeSignupsDocument, options);
      }
export function useGetTotalPledgeSignupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTotalPledgeSignupsQuery, GetTotalPledgeSignupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTotalPledgeSignupsQuery, GetTotalPledgeSignupsQueryVariables>(GetTotalPledgeSignupsDocument, options);
        }
export type GetTotalPledgeSignupsQueryHookResult = ReturnType<typeof useGetTotalPledgeSignupsQuery>;
export type GetTotalPledgeSignupsLazyQueryHookResult = ReturnType<typeof useGetTotalPledgeSignupsLazyQuery>;
export type GetTotalPledgeSignupsQueryResult = Apollo.QueryResult<GetTotalPledgeSignupsQuery, GetTotalPledgeSignupsQueryVariables>;
export const SendReceiptDocument = gql`
    query sendReceipt($paymentId: BigInt!) {
  sendReceipt(paymentId: $paymentId)
}
    `;

/**
 * __useSendReceiptQuery__
 *
 * To run a query within a React component, call `useSendReceiptQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendReceiptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendReceiptQuery({
 *   variables: {
 *      paymentId: // value for 'paymentId'
 *   },
 * });
 */
export function useSendReceiptQuery(baseOptions: Apollo.QueryHookOptions<SendReceiptQuery, SendReceiptQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SendReceiptQuery, SendReceiptQueryVariables>(SendReceiptDocument, options);
      }
export function useSendReceiptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SendReceiptQuery, SendReceiptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SendReceiptQuery, SendReceiptQueryVariables>(SendReceiptDocument, options);
        }
export type SendReceiptQueryHookResult = ReturnType<typeof useSendReceiptQuery>;
export type SendReceiptLazyQueryHookResult = ReturnType<typeof useSendReceiptLazyQuery>;
export type SendReceiptQueryResult = Apollo.QueryResult<SendReceiptQuery, SendReceiptQueryVariables>;
export const AddReminderDocument = gql`
    mutation addReminder($reminderDate: Date!, $email: String!, $newsletterSignup: Boolean!, $reminderSourcePath: String!) {
  addReminder(
    input: {email: $email, reminderDate: $reminderDate, newsletterSignup: $newsletterSignup, reminderSourcePath: $reminderSourcePath}
  ) {
    reminder {
      email
      reminderDate
      newsletterSignup
      reminderSourcePath
    }
  }
}
    `;
export type AddReminderMutationFn = Apollo.MutationFunction<AddReminderMutation, AddReminderMutationVariables>;

/**
 * __useAddReminderMutation__
 *
 * To run a mutation, you first call `useAddReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addReminderMutation, { data, loading, error }] = useAddReminderMutation({
 *   variables: {
 *      reminderDate: // value for 'reminderDate'
 *      email: // value for 'email'
 *      newsletterSignup: // value for 'newsletterSignup'
 *      reminderSourcePath: // value for 'reminderSourcePath'
 *   },
 * });
 */
export function useAddReminderMutation(baseOptions?: Apollo.MutationHookOptions<AddReminderMutation, AddReminderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddReminderMutation, AddReminderMutationVariables>(AddReminderDocument, options);
      }
export type AddReminderMutationHookResult = ReturnType<typeof useAddReminderMutation>;
export type AddReminderMutationResult = Apollo.MutationResult<AddReminderMutation>;
export type AddReminderMutationOptions = Apollo.BaseMutationOptions<AddReminderMutation, AddReminderMutationVariables>;
export const CreateAddressDocument = gql`
    mutation createAddress($personId: BigInt, $sessionToken: UUID, $addressType: AddressType, $firstName: String, $lastName: String, $organizationName: String, $houseNumber: String, $addressLine1: String, $addressLine2: String, $city: String, $region: String, $countryCode: String, $postalCode: String, $latitude: Float, $longitude: Float, $googlePlaceId: String) {
  mutationResult: createAddressByPersonOrTemporaryPerson(
    input: {personId: $personId, sessionToken: $sessionToken, addressType: $addressType, firstName: $firstName, lastName: $lastName, organizationName: $organizationName, houseNumber: $houseNumber, addressLine1: $addressLine1, addressLine2: $addressLine2, city: $city, region: $region, countryCode: $countryCode, postalCode: $postalCode, latitude: $latitude, longitude: $longitude, googlePlaceId: $googlePlaceId}
  ) {
    address {
      id
      personId
      addressType
      firstName
      lastName
      organizationName
      houseNumber
      addressLine1
      addressLine2
      city
      region
      postalCode
      countryCode
    }
  }
}
    `;
export type CreateAddressMutationFn = Apollo.MutationFunction<CreateAddressMutation, CreateAddressMutationVariables>;

/**
 * __useCreateAddressMutation__
 *
 * To run a mutation, you first call `useCreateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAddressMutation, { data, loading, error }] = useCreateAddressMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      sessionToken: // value for 'sessionToken'
 *      addressType: // value for 'addressType'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      organizationName: // value for 'organizationName'
 *      houseNumber: // value for 'houseNumber'
 *      addressLine1: // value for 'addressLine1'
 *      addressLine2: // value for 'addressLine2'
 *      city: // value for 'city'
 *      region: // value for 'region'
 *      countryCode: // value for 'countryCode'
 *      postalCode: // value for 'postalCode'
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *      googlePlaceId: // value for 'googlePlaceId'
 *   },
 * });
 */
export function useCreateAddressMutation(baseOptions?: Apollo.MutationHookOptions<CreateAddressMutation, CreateAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAddressMutation, CreateAddressMutationVariables>(CreateAddressDocument, options);
      }
export type CreateAddressMutationHookResult = ReturnType<typeof useCreateAddressMutation>;
export type CreateAddressMutationResult = Apollo.MutationResult<CreateAddressMutation>;
export type CreateAddressMutationOptions = Apollo.BaseMutationOptions<CreateAddressMutation, CreateAddressMutationVariables>;
export const CreateTemporaryPersonDocument = gql`
    mutation createTemporaryPerson($email: String!, $recaptchaToken: String!, $isLongLasting: Boolean = false) {
  createTemporaryPersonByEmail(
    input: {email: $email, recaptchaToken: $recaptchaToken, isLongLasting: $isLongLasting}
  ) {
    TemporaryPerson: temporaryPerson {
      email
      sessionToken
      tokenIssuedAt
      tokenExpiresAt
    }
  }
}
    `;
export type CreateTemporaryPersonMutationFn = Apollo.MutationFunction<CreateTemporaryPersonMutation, CreateTemporaryPersonMutationVariables>;

/**
 * __useCreateTemporaryPersonMutation__
 *
 * To run a mutation, you first call `useCreateTemporaryPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTemporaryPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTemporaryPersonMutation, { data, loading, error }] = useCreateTemporaryPersonMutation({
 *   variables: {
 *      email: // value for 'email'
 *      recaptchaToken: // value for 'recaptchaToken'
 *      isLongLasting: // value for 'isLongLasting'
 *   },
 * });
 */
export function useCreateTemporaryPersonMutation(baseOptions?: Apollo.MutationHookOptions<CreateTemporaryPersonMutation, CreateTemporaryPersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTemporaryPersonMutation, CreateTemporaryPersonMutationVariables>(CreateTemporaryPersonDocument, options);
      }
export type CreateTemporaryPersonMutationHookResult = ReturnType<typeof useCreateTemporaryPersonMutation>;
export type CreateTemporaryPersonMutationResult = Apollo.MutationResult<CreateTemporaryPersonMutation>;
export type CreateTemporaryPersonMutationOptions = Apollo.BaseMutationOptions<CreateTemporaryPersonMutation, CreateTemporaryPersonMutationVariables>;
export const SyncAuth0UserDocument = gql`
    mutation syncAuth0User($auth0User: JSON!) {
  syncAuth0User(input: {auth0User: $auth0User}) {
    Person: person {
      id
      firstName
      lastName
      email
      fullName
      birthDate
      createdAt
      updatedAt
      isAnonymized
      auth0UserId
      contactType
      organizationName
    }
  }
}
    `;
export type SyncAuth0UserMutationFn = Apollo.MutationFunction<SyncAuth0UserMutation, SyncAuth0UserMutationVariables>;

/**
 * __useSyncAuth0UserMutation__
 *
 * To run a mutation, you first call `useSyncAuth0UserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncAuth0UserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncAuth0UserMutation, { data, loading, error }] = useSyncAuth0UserMutation({
 *   variables: {
 *      auth0User: // value for 'auth0User'
 *   },
 * });
 */
export function useSyncAuth0UserMutation(baseOptions?: Apollo.MutationHookOptions<SyncAuth0UserMutation, SyncAuth0UserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncAuth0UserMutation, SyncAuth0UserMutationVariables>(SyncAuth0UserDocument, options);
      }
export type SyncAuth0UserMutationHookResult = ReturnType<typeof useSyncAuth0UserMutation>;
export type SyncAuth0UserMutationResult = Apollo.MutationResult<SyncAuth0UserMutation>;
export type SyncAuth0UserMutationOptions = Apollo.BaseMutationOptions<SyncAuth0UserMutation, SyncAuth0UserMutationVariables>;
export const UpdatePersonByIdDocument = gql`
    mutation updatePersonById($personId: BigInt!, $firstName: String, $lastName: String, $email: String, $birthDate: Date) {
  updatePersonByPersonId(
    input: {personId: $personId, firstName: $firstName, lastName: $lastName, email: $email, birthDate: $birthDate}
  ) {
    person {
      id
      fullName
      firstName
      lastName
      email
      birthDate
      contactType
      organizationName
    }
  }
}
    `;
export type UpdatePersonByIdMutationFn = Apollo.MutationFunction<UpdatePersonByIdMutation, UpdatePersonByIdMutationVariables>;

/**
 * __useUpdatePersonByIdMutation__
 *
 * To run a mutation, you first call `useUpdatePersonByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonByIdMutation, { data, loading, error }] = useUpdatePersonByIdMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      birthDate: // value for 'birthDate'
 *   },
 * });
 */
export function useUpdatePersonByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePersonByIdMutation, UpdatePersonByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePersonByIdMutation, UpdatePersonByIdMutationVariables>(UpdatePersonByIdDocument, options);
      }
export type UpdatePersonByIdMutationHookResult = ReturnType<typeof useUpdatePersonByIdMutation>;
export type UpdatePersonByIdMutationResult = Apollo.MutationResult<UpdatePersonByIdMutation>;
export type UpdatePersonByIdMutationOptions = Apollo.BaseMutationOptions<UpdatePersonByIdMutation, UpdatePersonByIdMutationVariables>;
export const UpdatePersonEmailDocument = gql`
    mutation updatePersonEmail($personId: BigInt!, $email: String!) {
  Person: updatePersonEmailAuth0(input: {personId: $personId, email: $email}) {
    message: string
  }
}
    `;
export type UpdatePersonEmailMutationFn = Apollo.MutationFunction<UpdatePersonEmailMutation, UpdatePersonEmailMutationVariables>;

/**
 * __useUpdatePersonEmailMutation__
 *
 * To run a mutation, you first call `useUpdatePersonEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonEmailMutation, { data, loading, error }] = useUpdatePersonEmailMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUpdatePersonEmailMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePersonEmailMutation, UpdatePersonEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePersonEmailMutation, UpdatePersonEmailMutationVariables>(UpdatePersonEmailDocument, options);
      }
export type UpdatePersonEmailMutationHookResult = ReturnType<typeof useUpdatePersonEmailMutation>;
export type UpdatePersonEmailMutationResult = Apollo.MutationResult<UpdatePersonEmailMutation>;
export type UpdatePersonEmailMutationOptions = Apollo.BaseMutationOptions<UpdatePersonEmailMutation, UpdatePersonEmailMutationVariables>;
export const GetAddressesDocument = gql`
    query getAddresses($personId: BigInt, $sessionToken: UUID) {
  Addresses: getAddressesByPersonOrTemporaryPerson(
    personId: $personId
    sessionToken: $sessionToken
  ) {
    edges {
      node {
        id
        personId
        addressType
        firstName
        lastName
        organizationName
        houseNumber
        addressLine1
        addressLine2
        city
        region
        postalCode
        countryCode
        coordinates
        createdAt
        updatedAt
      }
    }
  }
}
    `;

/**
 * __useGetAddressesQuery__
 *
 * To run a query within a React component, call `useGetAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddressesQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *      sessionToken: // value for 'sessionToken'
 *   },
 * });
 */
export function useGetAddressesQuery(baseOptions?: Apollo.QueryHookOptions<GetAddressesQuery, GetAddressesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAddressesQuery, GetAddressesQueryVariables>(GetAddressesDocument, options);
      }
export function useGetAddressesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAddressesQuery, GetAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAddressesQuery, GetAddressesQueryVariables>(GetAddressesDocument, options);
        }
export type GetAddressesQueryHookResult = ReturnType<typeof useGetAddressesQuery>;
export type GetAddressesLazyQueryHookResult = ReturnType<typeof useGetAddressesLazyQuery>;
export type GetAddressesQueryResult = Apollo.QueryResult<GetAddressesQuery, GetAddressesQueryVariables>;
export const GetPersonDocument = gql`
    query getPerson {
  Person: currentPerson {
    id
    email
    fullName
    firstName
    lastName
    contactType
    organizationName
    birthDate
    auth0UserId
  }
}
    `;

/**
 * __useGetPersonQuery__
 *
 * To run a query within a React component, call `useGetPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPersonQuery(baseOptions?: Apollo.QueryHookOptions<GetPersonQuery, GetPersonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPersonQuery, GetPersonQueryVariables>(GetPersonDocument, options);
      }
export function useGetPersonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPersonQuery, GetPersonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPersonQuery, GetPersonQueryVariables>(GetPersonDocument, options);
        }
export type GetPersonQueryHookResult = ReturnType<typeof useGetPersonQuery>;
export type GetPersonLazyQueryHookResult = ReturnType<typeof useGetPersonLazyQuery>;
export type GetPersonQueryResult = Apollo.QueryResult<GetPersonQuery, GetPersonQueryVariables>;
export const GetPersonByIdDocument = gql`
    query getPersonById($personId: BigInt!) {
  Person: personById(id: $personId) {
    id
    firstName
    lastName
    fullName
    email
    birthDate
    createdAt
    updatedAt
    auth0UserId
    contactType
    organizationName
  }
}
    `;

/**
 * __useGetPersonByIdQuery__
 *
 * To run a query within a React component, call `useGetPersonByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonByIdQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetPersonByIdQuery(baseOptions: Apollo.QueryHookOptions<GetPersonByIdQuery, GetPersonByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPersonByIdQuery, GetPersonByIdQueryVariables>(GetPersonByIdDocument, options);
      }
export function useGetPersonByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPersonByIdQuery, GetPersonByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPersonByIdQuery, GetPersonByIdQueryVariables>(GetPersonByIdDocument, options);
        }
export type GetPersonByIdQueryHookResult = ReturnType<typeof useGetPersonByIdQuery>;
export type GetPersonByIdLazyQueryHookResult = ReturnType<typeof useGetPersonByIdLazyQuery>;
export type GetPersonByIdQueryResult = Apollo.QueryResult<GetPersonByIdQuery, GetPersonByIdQueryVariables>;
export const CancelRecurringReportedDonationDocument = gql`
    mutation cancelRecurringReportedDonation($recurringReportedDonationId: BigInt!) {
  cancelRecurringReportedDonation(
    input: {recurringReportedDonationId: $recurringReportedDonationId}
  ) {
    RecurringReportedDonation: recurringReportedDonation {
      id
    }
  }
}
    `;
export type CancelRecurringReportedDonationMutationFn = Apollo.MutationFunction<CancelRecurringReportedDonationMutation, CancelRecurringReportedDonationMutationVariables>;

/**
 * __useCancelRecurringReportedDonationMutation__
 *
 * To run a mutation, you first call `useCancelRecurringReportedDonationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelRecurringReportedDonationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelRecurringReportedDonationMutation, { data, loading, error }] = useCancelRecurringReportedDonationMutation({
 *   variables: {
 *      recurringReportedDonationId: // value for 'recurringReportedDonationId'
 *   },
 * });
 */
export function useCancelRecurringReportedDonationMutation(baseOptions?: Apollo.MutationHookOptions<CancelRecurringReportedDonationMutation, CancelRecurringReportedDonationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelRecurringReportedDonationMutation, CancelRecurringReportedDonationMutationVariables>(CancelRecurringReportedDonationDocument, options);
      }
export type CancelRecurringReportedDonationMutationHookResult = ReturnType<typeof useCancelRecurringReportedDonationMutation>;
export type CancelRecurringReportedDonationMutationResult = Apollo.MutationResult<CancelRecurringReportedDonationMutation>;
export type CancelRecurringReportedDonationMutationOptions = Apollo.BaseMutationOptions<CancelRecurringReportedDonationMutation, CancelRecurringReportedDonationMutationVariables>;
export const CreateFinancialPeriodDocument = gql`
    mutation createFinancialPeriod($personId: BigInt!, $startDate: Date!, $endDate: Date!, $wealthAmount: BigFloat, $currencyCode: String, $employmentStatus: IncomeEmploymentStatus, $donationsTaxDeductible: Boolean, $incomes: [IncomeInput]) {
  createFinancialPeriod(
    input: {personId: $personId, period: {start: {value: $startDate, inclusive: true}, end: {value: $endDate, inclusive: true}}, wealthAmount: $wealthAmount, currencyCode: $currencyCode, employmentStatus: $employmentStatus, donationsTaxDeductible: $donationsTaxDeductible, incomes: $incomes}
  ) {
    financialPeriod {
      id
      personId
      period {
        start {
          value
          inclusive
        }
        end {
          value
          inclusive
        }
      }
      wealthAmount
      currencyCode
    }
  }
}
    `;
export type CreateFinancialPeriodMutationFn = Apollo.MutationFunction<CreateFinancialPeriodMutation, CreateFinancialPeriodMutationVariables>;

/**
 * __useCreateFinancialPeriodMutation__
 *
 * To run a mutation, you first call `useCreateFinancialPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFinancialPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFinancialPeriodMutation, { data, loading, error }] = useCreateFinancialPeriodMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      wealthAmount: // value for 'wealthAmount'
 *      currencyCode: // value for 'currencyCode'
 *      employmentStatus: // value for 'employmentStatus'
 *      donationsTaxDeductible: // value for 'donationsTaxDeductible'
 *      incomes: // value for 'incomes'
 *   },
 * });
 */
export function useCreateFinancialPeriodMutation(baseOptions?: Apollo.MutationHookOptions<CreateFinancialPeriodMutation, CreateFinancialPeriodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFinancialPeriodMutation, CreateFinancialPeriodMutationVariables>(CreateFinancialPeriodDocument, options);
      }
export type CreateFinancialPeriodMutationHookResult = ReturnType<typeof useCreateFinancialPeriodMutation>;
export type CreateFinancialPeriodMutationResult = Apollo.MutationResult<CreateFinancialPeriodMutation>;
export type CreateFinancialPeriodMutationOptions = Apollo.BaseMutationOptions<CreateFinancialPeriodMutation, CreateFinancialPeriodMutationVariables>;
export const CreateRecurringReportedDonationDocument = gql`
    mutation createRecurringReportedDonation($personId: BigInt!, $organizationId: BigInt!, $amount: BigFloat!, $currencyCode: String!, $startDate: Date!, $recurrenceInterval: IntervalInput!) {
  RecurringReportedDonation: createRecurringReportedDonation(
    input: {personId: $personId, organizationId: $organizationId, amount: $amount, currencyCode: $currencyCode, startDate: $startDate, recurrenceInterval: $recurrenceInterval}
  ) {
    recurringReportedDonation {
      id
      personId
      organizationId
      amount
      currencyCode
      startDate
      recurrenceInterval {
        years
        months
        days
        hours
        minutes
        seconds
      }
      nextDate
      status
      createdAt
      updatedAt
    }
  }
}
    `;
export type CreateRecurringReportedDonationMutationFn = Apollo.MutationFunction<CreateRecurringReportedDonationMutation, CreateRecurringReportedDonationMutationVariables>;

/**
 * __useCreateRecurringReportedDonationMutation__
 *
 * To run a mutation, you first call `useCreateRecurringReportedDonationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecurringReportedDonationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecurringReportedDonationMutation, { data, loading, error }] = useCreateRecurringReportedDonationMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      organizationId: // value for 'organizationId'
 *      amount: // value for 'amount'
 *      currencyCode: // value for 'currencyCode'
 *      startDate: // value for 'startDate'
 *      recurrenceInterval: // value for 'recurrenceInterval'
 *   },
 * });
 */
export function useCreateRecurringReportedDonationMutation(baseOptions?: Apollo.MutationHookOptions<CreateRecurringReportedDonationMutation, CreateRecurringReportedDonationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRecurringReportedDonationMutation, CreateRecurringReportedDonationMutationVariables>(CreateRecurringReportedDonationDocument, options);
      }
export type CreateRecurringReportedDonationMutationHookResult = ReturnType<typeof useCreateRecurringReportedDonationMutation>;
export type CreateRecurringReportedDonationMutationResult = Apollo.MutationResult<CreateRecurringReportedDonationMutation>;
export type CreateRecurringReportedDonationMutationOptions = Apollo.BaseMutationOptions<CreateRecurringReportedDonationMutation, CreateRecurringReportedDonationMutationVariables>;
export const CreateReportedDonationDocument = gql`
    mutation createReportedDonation($personId: BigInt!, $donationDate: Date!, $amount: BigFloat!, $currencyCode: String!, $organizationId: BigInt!, $pledgeId: BigInt, $financialPeriodId: BigInt) {
  createReportedDonationByPersonId(
    input: {personId: $personId, donationDate: $donationDate, amount: $amount, currencyCode: $currencyCode, organizationId: $organizationId, pledgeId: $pledgeId, financialPeriodId: $financialPeriodId}
  ) {
    reportedDonation {
      id
      personId
      donationDate
      amount
      currencyCode
      organizationId
    }
  }
}
    `;
export type CreateReportedDonationMutationFn = Apollo.MutationFunction<CreateReportedDonationMutation, CreateReportedDonationMutationVariables>;

/**
 * __useCreateReportedDonationMutation__
 *
 * To run a mutation, you first call `useCreateReportedDonationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportedDonationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportedDonationMutation, { data, loading, error }] = useCreateReportedDonationMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      donationDate: // value for 'donationDate'
 *      amount: // value for 'amount'
 *      currencyCode: // value for 'currencyCode'
 *      organizationId: // value for 'organizationId'
 *      pledgeId: // value for 'pledgeId'
 *      financialPeriodId: // value for 'financialPeriodId'
 *   },
 * });
 */
export function useCreateReportedDonationMutation(baseOptions?: Apollo.MutationHookOptions<CreateReportedDonationMutation, CreateReportedDonationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReportedDonationMutation, CreateReportedDonationMutationVariables>(CreateReportedDonationDocument, options);
      }
export type CreateReportedDonationMutationHookResult = ReturnType<typeof useCreateReportedDonationMutation>;
export type CreateReportedDonationMutationResult = Apollo.MutationResult<CreateReportedDonationMutation>;
export type CreateReportedDonationMutationOptions = Apollo.BaseMutationOptions<CreateReportedDonationMutation, CreateReportedDonationMutationVariables>;
export const DeleteFinancialPeriodDocument = gql`
    mutation deleteFinancialPeriod($id: BigInt!) {
  deleteFinancialPeriod(input: {id: $id}) {
    clientMutationId
  }
}
    `;
export type DeleteFinancialPeriodMutationFn = Apollo.MutationFunction<DeleteFinancialPeriodMutation, DeleteFinancialPeriodMutationVariables>;

/**
 * __useDeleteFinancialPeriodMutation__
 *
 * To run a mutation, you first call `useDeleteFinancialPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFinancialPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFinancialPeriodMutation, { data, loading, error }] = useDeleteFinancialPeriodMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFinancialPeriodMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFinancialPeriodMutation, DeleteFinancialPeriodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFinancialPeriodMutation, DeleteFinancialPeriodMutationVariables>(DeleteFinancialPeriodDocument, options);
      }
export type DeleteFinancialPeriodMutationHookResult = ReturnType<typeof useDeleteFinancialPeriodMutation>;
export type DeleteFinancialPeriodMutationResult = Apollo.MutationResult<DeleteFinancialPeriodMutation>;
export type DeleteFinancialPeriodMutationOptions = Apollo.BaseMutationOptions<DeleteFinancialPeriodMutation, DeleteFinancialPeriodMutationVariables>;
export const DeleteReportedDonationDocument = gql`
    mutation deleteReportedDonation($reportedDonationId: BigInt!) {
  deleteReportedDonation(input: {id: $reportedDonationId}) {
    clientMutationId
  }
}
    `;
export type DeleteReportedDonationMutationFn = Apollo.MutationFunction<DeleteReportedDonationMutation, DeleteReportedDonationMutationVariables>;

/**
 * __useDeleteReportedDonationMutation__
 *
 * To run a mutation, you first call `useDeleteReportedDonationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReportedDonationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReportedDonationMutation, { data, loading, error }] = useDeleteReportedDonationMutation({
 *   variables: {
 *      reportedDonationId: // value for 'reportedDonationId'
 *   },
 * });
 */
export function useDeleteReportedDonationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReportedDonationMutation, DeleteReportedDonationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReportedDonationMutation, DeleteReportedDonationMutationVariables>(DeleteReportedDonationDocument, options);
      }
export type DeleteReportedDonationMutationHookResult = ReturnType<typeof useDeleteReportedDonationMutation>;
export type DeleteReportedDonationMutationResult = Apollo.MutationResult<DeleteReportedDonationMutation>;
export type DeleteReportedDonationMutationOptions = Apollo.BaseMutationOptions<DeleteReportedDonationMutation, DeleteReportedDonationMutationVariables>;
export const JoinPledgeClubDocument = gql`
    mutation joinPledgeClub($personId: BigInt!, $pledgeClubSlug: String!, $source: String!) {
  PledgeClubMember: joinPledgeClub(
    input: {personId: $personId, pledgeClubSlug: $pledgeClubSlug, source: $source}
  ) {
    pledgeClubMember {
      pledgeClubSlug
      personId
    }
  }
}
    `;
export type JoinPledgeClubMutationFn = Apollo.MutationFunction<JoinPledgeClubMutation, JoinPledgeClubMutationVariables>;

/**
 * __useJoinPledgeClubMutation__
 *
 * To run a mutation, you first call `useJoinPledgeClubMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinPledgeClubMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinPledgeClubMutation, { data, loading, error }] = useJoinPledgeClubMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      pledgeClubSlug: // value for 'pledgeClubSlug'
 *      source: // value for 'source'
 *   },
 * });
 */
export function useJoinPledgeClubMutation(baseOptions?: Apollo.MutationHookOptions<JoinPledgeClubMutation, JoinPledgeClubMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinPledgeClubMutation, JoinPledgeClubMutationVariables>(JoinPledgeClubDocument, options);
      }
export type JoinPledgeClubMutationHookResult = ReturnType<typeof useJoinPledgeClubMutation>;
export type JoinPledgeClubMutationResult = Apollo.MutationResult<JoinPledgeClubMutation>;
export type JoinPledgeClubMutationOptions = Apollo.BaseMutationOptions<JoinPledgeClubMutation, JoinPledgeClubMutationVariables>;
export const LeavePledgeClubDocument = gql`
    mutation leavePledgeClub($personId: BigInt!, $pledgeClubSlug: String!) {
  PledgeClubMember: leavePledgeClub(
    input: {personId: $personId, pledgeClubSlug: $pledgeClubSlug}
  ) {
    pledgeClubMember {
      pledgeClubSlug
      personId
    }
  }
}
    `;
export type LeavePledgeClubMutationFn = Apollo.MutationFunction<LeavePledgeClubMutation, LeavePledgeClubMutationVariables>;

/**
 * __useLeavePledgeClubMutation__
 *
 * To run a mutation, you first call `useLeavePledgeClubMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeavePledgeClubMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leavePledgeClubMutation, { data, loading, error }] = useLeavePledgeClubMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      pledgeClubSlug: // value for 'pledgeClubSlug'
 *   },
 * });
 */
export function useLeavePledgeClubMutation(baseOptions?: Apollo.MutationHookOptions<LeavePledgeClubMutation, LeavePledgeClubMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LeavePledgeClubMutation, LeavePledgeClubMutationVariables>(LeavePledgeClubDocument, options);
      }
export type LeavePledgeClubMutationHookResult = ReturnType<typeof useLeavePledgeClubMutation>;
export type LeavePledgeClubMutationResult = Apollo.MutationResult<LeavePledgeClubMutation>;
export type LeavePledgeClubMutationOptions = Apollo.BaseMutationOptions<LeavePledgeClubMutation, LeavePledgeClubMutationVariables>;
export const SignPledgeDocument = gql`
    mutation signPledge($personId: BigInt!, $startDate: Date!, $endDate: Date!, $incomePercentage: BigFloat!, $wealthPercentage: BigFloat, $pledgeType: PledgeType!, $motivation: String, $locale: String, $metadata: JSON!, $employmentStatus: IncomeEmploymentStatus!, $firstName: String, $lastName: String) {
  signPledge(
    input: {personId: $personId, pledgePeriod: {start: {value: $startDate, inclusive: true}, end: {value: $endDate, inclusive: true}}, incomePercentage: $incomePercentage, wealthPercentage: $wealthPercentage, pledgeType: $pledgeType, motivation: $motivation, locale: $locale, metadata: $metadata, employmentStatus: $employmentStatus, personFirstName: $firstName, personLastName: $lastName}
  ) {
    pledge {
      id
      personId
      incomePercentage
      wealthPercentage
      motivation
      locale
      metadata
      pledgeType
      period {
        start {
          value
          inclusive
        }
        end {
          value
          inclusive
        }
      }
    }
  }
}
    `;
export type SignPledgeMutationFn = Apollo.MutationFunction<SignPledgeMutation, SignPledgeMutationVariables>;

/**
 * __useSignPledgeMutation__
 *
 * To run a mutation, you first call `useSignPledgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignPledgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signPledgeMutation, { data, loading, error }] = useSignPledgeMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      incomePercentage: // value for 'incomePercentage'
 *      wealthPercentage: // value for 'wealthPercentage'
 *      pledgeType: // value for 'pledgeType'
 *      motivation: // value for 'motivation'
 *      locale: // value for 'locale'
 *      metadata: // value for 'metadata'
 *      employmentStatus: // value for 'employmentStatus'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useSignPledgeMutation(baseOptions?: Apollo.MutationHookOptions<SignPledgeMutation, SignPledgeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignPledgeMutation, SignPledgeMutationVariables>(SignPledgeDocument, options);
      }
export type SignPledgeMutationHookResult = ReturnType<typeof useSignPledgeMutation>;
export type SignPledgeMutationResult = Apollo.MutationResult<SignPledgeMutation>;
export type SignPledgeMutationOptions = Apollo.BaseMutationOptions<SignPledgeMutation, SignPledgeMutationVariables>;
export const SignPledgeByTemporaryPersonDocument = gql`
    mutation signPledgeByTemporaryPerson($sessionToken: UUID!, $startDate: Date!, $endDate: Date!, $incomePercentage: BigFloat!, $wealthPercentage: BigFloat, $pledgeType: PledgeType!, $motivation: String, $locale: String, $metadata: JSON!, $employmentStatus: IncomeEmploymentStatus!, $firstName: String, $lastName: String) {
  signPledgeByTemporaryPerson(
    input: {sessionToken: $sessionToken, pledgePeriod: {start: {value: $startDate, inclusive: true}, end: {value: $endDate, inclusive: true}}, incomePercentage: $incomePercentage, wealthPercentage: $wealthPercentage, pledgeType: $pledgeType, motivation: $motivation, locale: $locale, metadata: $metadata, employmentStatus: $employmentStatus, personFirstName: $firstName, personLastName: $lastName}
  ) {
    pledge {
      id
      personId
      incomePercentage
      wealthPercentage
      motivation
      locale
      metadata
      pledgeType
      period {
        start {
          value
          inclusive
        }
        end {
          value
          inclusive
        }
      }
    }
  }
}
    `;
export type SignPledgeByTemporaryPersonMutationFn = Apollo.MutationFunction<SignPledgeByTemporaryPersonMutation, SignPledgeByTemporaryPersonMutationVariables>;

/**
 * __useSignPledgeByTemporaryPersonMutation__
 *
 * To run a mutation, you first call `useSignPledgeByTemporaryPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignPledgeByTemporaryPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signPledgeByTemporaryPersonMutation, { data, loading, error }] = useSignPledgeByTemporaryPersonMutation({
 *   variables: {
 *      sessionToken: // value for 'sessionToken'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      incomePercentage: // value for 'incomePercentage'
 *      wealthPercentage: // value for 'wealthPercentage'
 *      pledgeType: // value for 'pledgeType'
 *      motivation: // value for 'motivation'
 *      locale: // value for 'locale'
 *      metadata: // value for 'metadata'
 *      employmentStatus: // value for 'employmentStatus'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useSignPledgeByTemporaryPersonMutation(baseOptions?: Apollo.MutationHookOptions<SignPledgeByTemporaryPersonMutation, SignPledgeByTemporaryPersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignPledgeByTemporaryPersonMutation, SignPledgeByTemporaryPersonMutationVariables>(SignPledgeByTemporaryPersonDocument, options);
      }
export type SignPledgeByTemporaryPersonMutationHookResult = ReturnType<typeof useSignPledgeByTemporaryPersonMutation>;
export type SignPledgeByTemporaryPersonMutationResult = Apollo.MutationResult<SignPledgeByTemporaryPersonMutation>;
export type SignPledgeByTemporaryPersonMutationOptions = Apollo.BaseMutationOptions<SignPledgeByTemporaryPersonMutation, SignPledgeByTemporaryPersonMutationVariables>;
export const UpdateFinancialPeriodDocument = gql`
    mutation updateFinancialPeriod($id: BigInt!, $startDate: Date!, $endDate: Date!, $wealthAmount: BigFloat, $currencyCode: String, $employmentStatus: IncomeEmploymentStatus, $donationsTaxDeductible: Boolean, $incomes: [IncomeInput]) {
  updateFinancialPeriod(
    input: {id: $id, period: {start: {value: $startDate, inclusive: true}, end: {value: $endDate, inclusive: true}}, wealthAmount: $wealthAmount, currencyCode: $currencyCode, employmentStatus: $employmentStatus, donationsTaxDeductible: $donationsTaxDeductible, incomes: $incomes}
  ) {
    financialPeriod {
      id
      personId
      period {
        start {
          value
          inclusive
        }
        end {
          value
          inclusive
        }
      }
      wealthAmount
      currencyCode
    }
  }
}
    `;
export type UpdateFinancialPeriodMutationFn = Apollo.MutationFunction<UpdateFinancialPeriodMutation, UpdateFinancialPeriodMutationVariables>;

/**
 * __useUpdateFinancialPeriodMutation__
 *
 * To run a mutation, you first call `useUpdateFinancialPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFinancialPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFinancialPeriodMutation, { data, loading, error }] = useUpdateFinancialPeriodMutation({
 *   variables: {
 *      id: // value for 'id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      wealthAmount: // value for 'wealthAmount'
 *      currencyCode: // value for 'currencyCode'
 *      employmentStatus: // value for 'employmentStatus'
 *      donationsTaxDeductible: // value for 'donationsTaxDeductible'
 *      incomes: // value for 'incomes'
 *   },
 * });
 */
export function useUpdateFinancialPeriodMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFinancialPeriodMutation, UpdateFinancialPeriodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFinancialPeriodMutation, UpdateFinancialPeriodMutationVariables>(UpdateFinancialPeriodDocument, options);
      }
export type UpdateFinancialPeriodMutationHookResult = ReturnType<typeof useUpdateFinancialPeriodMutation>;
export type UpdateFinancialPeriodMutationResult = Apollo.MutationResult<UpdateFinancialPeriodMutation>;
export type UpdateFinancialPeriodMutationOptions = Apollo.BaseMutationOptions<UpdateFinancialPeriodMutation, UpdateFinancialPeriodMutationVariables>;
export const UpdateReportedPaymentDocument = gql`
    mutation updateReportedPayment($id: BigInt!, $personId: BigInt!, $pledgeId: BigInt, $financialPeriodId: BigInt) {
  updateReportedPayment(
    input: {id: $id, personId: $personId, pledgeId: $pledgeId, financialPeriodId: $financialPeriodId}
  ) {
    payment {
      id
    }
  }
}
    `;
export type UpdateReportedPaymentMutationFn = Apollo.MutationFunction<UpdateReportedPaymentMutation, UpdateReportedPaymentMutationVariables>;

/**
 * __useUpdateReportedPaymentMutation__
 *
 * To run a mutation, you first call `useUpdateReportedPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReportedPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReportedPaymentMutation, { data, loading, error }] = useUpdateReportedPaymentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      personId: // value for 'personId'
 *      pledgeId: // value for 'pledgeId'
 *      financialPeriodId: // value for 'financialPeriodId'
 *   },
 * });
 */
export function useUpdateReportedPaymentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReportedPaymentMutation, UpdateReportedPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReportedPaymentMutation, UpdateReportedPaymentMutationVariables>(UpdateReportedPaymentDocument, options);
      }
export type UpdateReportedPaymentMutationHookResult = ReturnType<typeof useUpdateReportedPaymentMutation>;
export type UpdateReportedPaymentMutationResult = Apollo.MutationResult<UpdateReportedPaymentMutation>;
export type UpdateReportedPaymentMutationOptions = Apollo.BaseMutationOptions<UpdateReportedPaymentMutation, UpdateReportedPaymentMutationVariables>;
export const UpdatePledgeByPersonOrTemporaryPersonDocument = gql`
    mutation updatePledgeByPersonOrTemporaryPerson($pledgeId: BigInt!, $metadata: JSON!, $sessionToken: UUID) {
  updatePledgeByPersonOrTemporaryPerson(
    input: {pledgeId: $pledgeId, metadata: $metadata, sessionToken: $sessionToken}
  ) {
    pledge {
      id
      metadata
    }
  }
}
    `;
export type UpdatePledgeByPersonOrTemporaryPersonMutationFn = Apollo.MutationFunction<UpdatePledgeByPersonOrTemporaryPersonMutation, UpdatePledgeByPersonOrTemporaryPersonMutationVariables>;

/**
 * __useUpdatePledgeByPersonOrTemporaryPersonMutation__
 *
 * To run a mutation, you first call `useUpdatePledgeByPersonOrTemporaryPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePledgeByPersonOrTemporaryPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePledgeByPersonOrTemporaryPersonMutation, { data, loading, error }] = useUpdatePledgeByPersonOrTemporaryPersonMutation({
 *   variables: {
 *      pledgeId: // value for 'pledgeId'
 *      metadata: // value for 'metadata'
 *      sessionToken: // value for 'sessionToken'
 *   },
 * });
 */
export function useUpdatePledgeByPersonOrTemporaryPersonMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePledgeByPersonOrTemporaryPersonMutation, UpdatePledgeByPersonOrTemporaryPersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePledgeByPersonOrTemporaryPersonMutation, UpdatePledgeByPersonOrTemporaryPersonMutationVariables>(UpdatePledgeByPersonOrTemporaryPersonDocument, options);
      }
export type UpdatePledgeByPersonOrTemporaryPersonMutationHookResult = ReturnType<typeof useUpdatePledgeByPersonOrTemporaryPersonMutation>;
export type UpdatePledgeByPersonOrTemporaryPersonMutationResult = Apollo.MutationResult<UpdatePledgeByPersonOrTemporaryPersonMutation>;
export type UpdatePledgeByPersonOrTemporaryPersonMutationOptions = Apollo.BaseMutationOptions<UpdatePledgeByPersonOrTemporaryPersonMutation, UpdatePledgeByPersonOrTemporaryPersonMutationVariables>;
export const UpdatePledgeDetailsDocument = gql`
    mutation updatePledgeDetails($personId: BigInt!, $pledgeId: BigInt!, $personBirthDate: Date, $pledgeCountryCode: String, $motivation: String, $shareMotivation: Boolean!) {
  updatePledgeDetails(
    input: {personId: $personId, pledgeId: $pledgeId, personBirthDate: $personBirthDate, pledgeCountryCode: $pledgeCountryCode, motivation: $motivation, shareMotivation: $shareMotivation}
  ) {
    clientMutationId
  }
}
    `;
export type UpdatePledgeDetailsMutationFn = Apollo.MutationFunction<UpdatePledgeDetailsMutation, UpdatePledgeDetailsMutationVariables>;

/**
 * __useUpdatePledgeDetailsMutation__
 *
 * To run a mutation, you first call `useUpdatePledgeDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePledgeDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePledgeDetailsMutation, { data, loading, error }] = useUpdatePledgeDetailsMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      pledgeId: // value for 'pledgeId'
 *      personBirthDate: // value for 'personBirthDate'
 *      pledgeCountryCode: // value for 'pledgeCountryCode'
 *      motivation: // value for 'motivation'
 *      shareMotivation: // value for 'shareMotivation'
 *   },
 * });
 */
export function useUpdatePledgeDetailsMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePledgeDetailsMutation, UpdatePledgeDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePledgeDetailsMutation, UpdatePledgeDetailsMutationVariables>(UpdatePledgeDetailsDocument, options);
      }
export type UpdatePledgeDetailsMutationHookResult = ReturnType<typeof useUpdatePledgeDetailsMutation>;
export type UpdatePledgeDetailsMutationResult = Apollo.MutationResult<UpdatePledgeDetailsMutation>;
export type UpdatePledgeDetailsMutationOptions = Apollo.BaseMutationOptions<UpdatePledgeDetailsMutation, UpdatePledgeDetailsMutationVariables>;
export const UpdatePledgeDetailsByTemporaryPersonDocument = gql`
    mutation updatePledgeDetailsByTemporaryPerson($sessionToken: UUID!, $pledgeId: BigInt!, $personBirthDate: Date, $pledgeCountryCode: String, $motivation: String, $shareMotivation: Boolean!) {
  updatePledgeDetailsByTemporaryPerson(
    input: {sessionToken: $sessionToken, pledgeId: $pledgeId, personBirthDate: $personBirthDate, pledgeCountryCode: $pledgeCountryCode, motivation: $motivation, shareMotivation: $shareMotivation}
  ) {
    clientMutationId
  }
}
    `;
export type UpdatePledgeDetailsByTemporaryPersonMutationFn = Apollo.MutationFunction<UpdatePledgeDetailsByTemporaryPersonMutation, UpdatePledgeDetailsByTemporaryPersonMutationVariables>;

/**
 * __useUpdatePledgeDetailsByTemporaryPersonMutation__
 *
 * To run a mutation, you first call `useUpdatePledgeDetailsByTemporaryPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePledgeDetailsByTemporaryPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePledgeDetailsByTemporaryPersonMutation, { data, loading, error }] = useUpdatePledgeDetailsByTemporaryPersonMutation({
 *   variables: {
 *      sessionToken: // value for 'sessionToken'
 *      pledgeId: // value for 'pledgeId'
 *      personBirthDate: // value for 'personBirthDate'
 *      pledgeCountryCode: // value for 'pledgeCountryCode'
 *      motivation: // value for 'motivation'
 *      shareMotivation: // value for 'shareMotivation'
 *   },
 * });
 */
export function useUpdatePledgeDetailsByTemporaryPersonMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePledgeDetailsByTemporaryPersonMutation, UpdatePledgeDetailsByTemporaryPersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePledgeDetailsByTemporaryPersonMutation, UpdatePledgeDetailsByTemporaryPersonMutationVariables>(UpdatePledgeDetailsByTemporaryPersonDocument, options);
      }
export type UpdatePledgeDetailsByTemporaryPersonMutationHookResult = ReturnType<typeof useUpdatePledgeDetailsByTemporaryPersonMutation>;
export type UpdatePledgeDetailsByTemporaryPersonMutationResult = Apollo.MutationResult<UpdatePledgeDetailsByTemporaryPersonMutation>;
export type UpdatePledgeDetailsByTemporaryPersonMutationOptions = Apollo.BaseMutationOptions<UpdatePledgeDetailsByTemporaryPersonMutation, UpdatePledgeDetailsByTemporaryPersonMutationVariables>;
export const UpdateReportedDonationDocument = gql`
    mutation updateReportedDonation($id: BigInt!, $personId: BigInt!, $donationDate: Date!, $amount: BigFloat!, $currencyCode: String!, $organizationId: BigInt!, $pledgeId: BigInt, $financialPeriodId: BigInt) {
  updateReportedDonation(
    input: {id: $id, personId: $personId, donationDate: $donationDate, amount: $amount, currencyCode: $currencyCode, organizationId: $organizationId, pledgeId: $pledgeId, financialPeriodId: $financialPeriodId}
  ) {
    reportedDonation {
      id
      personId
      donationDate
      amount
      currencyCode
      organizationId
    }
  }
}
    `;
export type UpdateReportedDonationMutationFn = Apollo.MutationFunction<UpdateReportedDonationMutation, UpdateReportedDonationMutationVariables>;

/**
 * __useUpdateReportedDonationMutation__
 *
 * To run a mutation, you first call `useUpdateReportedDonationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReportedDonationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReportedDonationMutation, { data, loading, error }] = useUpdateReportedDonationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      personId: // value for 'personId'
 *      donationDate: // value for 'donationDate'
 *      amount: // value for 'amount'
 *      currencyCode: // value for 'currencyCode'
 *      organizationId: // value for 'organizationId'
 *      pledgeId: // value for 'pledgeId'
 *      financialPeriodId: // value for 'financialPeriodId'
 *   },
 * });
 */
export function useUpdateReportedDonationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReportedDonationMutation, UpdateReportedDonationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReportedDonationMutation, UpdateReportedDonationMutationVariables>(UpdateReportedDonationDocument, options);
      }
export type UpdateReportedDonationMutationHookResult = ReturnType<typeof useUpdateReportedDonationMutation>;
export type UpdateReportedDonationMutationResult = Apollo.MutationResult<UpdateReportedDonationMutation>;
export type UpdateReportedDonationMutationOptions = Apollo.BaseMutationOptions<UpdateReportedDonationMutation, UpdateReportedDonationMutationVariables>;
export const VerifyEmailDocument = gql`
    mutation verifyEmail($pledgeId: BigInt!, $emailVerificationToken: UUID!) {
  verifyEmail(input: {pledgeId: $pledgeId, token: $emailVerificationToken}) {
    pledge {
      id
    }
  }
}
    `;
export type VerifyEmailMutationFn = Apollo.MutationFunction<VerifyEmailMutation, VerifyEmailMutationVariables>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      pledgeId: // value for 'pledgeId'
 *      emailVerificationToken: // value for 'emailVerificationToken'
 *   },
 * });
 */
export function useVerifyEmailMutation(baseOptions?: Apollo.MutationHookOptions<VerifyEmailMutation, VerifyEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(VerifyEmailDocument, options);
      }
export type VerifyEmailMutationHookResult = ReturnType<typeof useVerifyEmailMutation>;
export type VerifyEmailMutationResult = Apollo.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = Apollo.BaseMutationOptions<VerifyEmailMutation, VerifyEmailMutationVariables>;
export const GetCompletionPaymentsByPersonIdDocument = gql`
    query getCompletionPaymentsByPersonId($personId: BigInt!) {
  CompletionPayments: allCompletionPayments(
    condition: {personId: $personId}
    orderBy: [DONATION_DATE_DESC, AMOUNT_DESC]
  ) {
    edges {
      node {
        objectId
        objectType
        reportedDonationId
        organizationId
        paymentId
        personId
        pledgeId
        financialPeriodId
        amount
        currencyCode
        amountNormalized
        allocation
        donationDate
        externalOrganization: externalOrganizationByOrganizationId {
          id
          name
        }
        liability: liabilityByPledgeIdAndFinancialPeriodId {
          liabilityPeriod {
            start {
              value
              inclusive
            }
            end {
              value
              inclusive
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetCompletionPaymentsByPersonIdQuery__
 *
 * To run a query within a React component, call `useGetCompletionPaymentsByPersonIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompletionPaymentsByPersonIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompletionPaymentsByPersonIdQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetCompletionPaymentsByPersonIdQuery(baseOptions: Apollo.QueryHookOptions<GetCompletionPaymentsByPersonIdQuery, GetCompletionPaymentsByPersonIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompletionPaymentsByPersonIdQuery, GetCompletionPaymentsByPersonIdQueryVariables>(GetCompletionPaymentsByPersonIdDocument, options);
      }
export function useGetCompletionPaymentsByPersonIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompletionPaymentsByPersonIdQuery, GetCompletionPaymentsByPersonIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompletionPaymentsByPersonIdQuery, GetCompletionPaymentsByPersonIdQueryVariables>(GetCompletionPaymentsByPersonIdDocument, options);
        }
export type GetCompletionPaymentsByPersonIdQueryHookResult = ReturnType<typeof useGetCompletionPaymentsByPersonIdQuery>;
export type GetCompletionPaymentsByPersonIdLazyQueryHookResult = ReturnType<typeof useGetCompletionPaymentsByPersonIdLazyQuery>;
export type GetCompletionPaymentsByPersonIdQueryResult = Apollo.QueryResult<GetCompletionPaymentsByPersonIdQuery, GetCompletionPaymentsByPersonIdQueryVariables>;
export const GetFinancialPeriodsByPersonIdDocument = gql`
    query getFinancialPeriodsByPersonId($personId: BigInt!) {
  FinancialPeriods: getFinancialPeriodsByPersonId(personId: $personId) {
    edges {
      node {
        id
        personId
        period {
          start {
            value
            inclusive
          }
          end {
            value
            inclusive
          }
        }
        employmentStatus
        donationsTaxDeductible
        wealthAmount
        currencyCode
        incomes: incomesByFinancialPeriodId {
          edges {
            node {
              nodeId
              id
              personId
              amount
              financialPeriodId
            }
          }
        }
        liabilities: liabilitiesByFinancialPeriodId {
          edges {
            node {
              nodeId
              pledgeId
              pledge: pledgeByPledgeId {
                id
                pledgeType
                incomePercentage
                wealthPercentage
              }
              financialPeriodId
              personId
              liabilityPeriod {
                start {
                  value
                  inclusive
                }
                end {
                  value
                  inclusive
                }
              }
              currencyCode
              liabilityAmount
              liabilityNormalized
              incomeLiabilityAmount
              wealthLiabilityAmount
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetFinancialPeriodsByPersonIdQuery__
 *
 * To run a query within a React component, call `useGetFinancialPeriodsByPersonIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinancialPeriodsByPersonIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinancialPeriodsByPersonIdQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetFinancialPeriodsByPersonIdQuery(baseOptions: Apollo.QueryHookOptions<GetFinancialPeriodsByPersonIdQuery, GetFinancialPeriodsByPersonIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFinancialPeriodsByPersonIdQuery, GetFinancialPeriodsByPersonIdQueryVariables>(GetFinancialPeriodsByPersonIdDocument, options);
      }
export function useGetFinancialPeriodsByPersonIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFinancialPeriodsByPersonIdQuery, GetFinancialPeriodsByPersonIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFinancialPeriodsByPersonIdQuery, GetFinancialPeriodsByPersonIdQueryVariables>(GetFinancialPeriodsByPersonIdDocument, options);
        }
export type GetFinancialPeriodsByPersonIdQueryHookResult = ReturnType<typeof useGetFinancialPeriodsByPersonIdQuery>;
export type GetFinancialPeriodsByPersonIdLazyQueryHookResult = ReturnType<typeof useGetFinancialPeriodsByPersonIdLazyQuery>;
export type GetFinancialPeriodsByPersonIdQueryResult = Apollo.QueryResult<GetFinancialPeriodsByPersonIdQuery, GetFinancialPeriodsByPersonIdQueryVariables>;
export const GetIncomesByPersonIdDocument = gql`
    query getIncomesByPersonId($personId: BigInt!) {
  Incomes: getIncomesByPersonId(personId: $personId) {
    edges {
      node {
        id
        personId
        amount
        financialPeriod: financialPeriodByFinancialPeriodId {
          id
          currencyCode
          period {
            start {
              value
              inclusive
            }
            end {
              value
              inclusive
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetIncomesByPersonIdQuery__
 *
 * To run a query within a React component, call `useGetIncomesByPersonIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIncomesByPersonIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIncomesByPersonIdQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetIncomesByPersonIdQuery(baseOptions: Apollo.QueryHookOptions<GetIncomesByPersonIdQuery, GetIncomesByPersonIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIncomesByPersonIdQuery, GetIncomesByPersonIdQueryVariables>(GetIncomesByPersonIdDocument, options);
      }
export function useGetIncomesByPersonIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIncomesByPersonIdQuery, GetIncomesByPersonIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIncomesByPersonIdQuery, GetIncomesByPersonIdQueryVariables>(GetIncomesByPersonIdDocument, options);
        }
export type GetIncomesByPersonIdQueryHookResult = ReturnType<typeof useGetIncomesByPersonIdQuery>;
export type GetIncomesByPersonIdLazyQueryHookResult = ReturnType<typeof useGetIncomesByPersonIdLazyQuery>;
export type GetIncomesByPersonIdQueryResult = Apollo.QueryResult<GetIncomesByPersonIdQuery, GetIncomesByPersonIdQueryVariables>;
export const GetLiabilitiesByPersonIdDocument = gql`
    query getLiabilitiesByPersonId($personId: BigInt!) {
  Liabilities: allLiabilities(condition: {personId: $personId}) {
    edges {
      node {
        personId
        pledgeId
        financialPeriodId
        liabilityPeriod {
          start {
            value
            inclusive
          }
          end {
            value
            inclusive
          }
        }
        currencyCode
        liabilityAmount
        liabilityNormalized
      }
    }
  }
}
    `;

/**
 * __useGetLiabilitiesByPersonIdQuery__
 *
 * To run a query within a React component, call `useGetLiabilitiesByPersonIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLiabilitiesByPersonIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLiabilitiesByPersonIdQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetLiabilitiesByPersonIdQuery(baseOptions: Apollo.QueryHookOptions<GetLiabilitiesByPersonIdQuery, GetLiabilitiesByPersonIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLiabilitiesByPersonIdQuery, GetLiabilitiesByPersonIdQueryVariables>(GetLiabilitiesByPersonIdDocument, options);
      }
export function useGetLiabilitiesByPersonIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLiabilitiesByPersonIdQuery, GetLiabilitiesByPersonIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLiabilitiesByPersonIdQuery, GetLiabilitiesByPersonIdQueryVariables>(GetLiabilitiesByPersonIdDocument, options);
        }
export type GetLiabilitiesByPersonIdQueryHookResult = ReturnType<typeof useGetLiabilitiesByPersonIdQuery>;
export type GetLiabilitiesByPersonIdLazyQueryHookResult = ReturnType<typeof useGetLiabilitiesByPersonIdLazyQuery>;
export type GetLiabilitiesByPersonIdQueryResult = Apollo.QueryResult<GetLiabilitiesByPersonIdQuery, GetLiabilitiesByPersonIdQueryVariables>;
export const GetLiabilityCompletionsDocument = gql`
    query getLiabilityCompletions($pledgeId: BigInt!) {
  LiabilityCompletions: getLiabilityCompletionByPledge(pledgeId: $pledgeId) {
    edges {
      node {
        personId
        pledgeId
        financialPeriodId
        currencyCode
        period {
          start {
            value
            inclusive
          }
          end {
            value
            inclusive
          }
        }
        liabilityAmount
        incomeLiabilityAmount
        wealthLiabilityAmount
        financialPeriodWealth
        completionAmount
        completionAmountNormalized
        completionPercentage
      }
    }
  }
}
    `;

/**
 * __useGetLiabilityCompletionsQuery__
 *
 * To run a query within a React component, call `useGetLiabilityCompletionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLiabilityCompletionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLiabilityCompletionsQuery({
 *   variables: {
 *      pledgeId: // value for 'pledgeId'
 *   },
 * });
 */
export function useGetLiabilityCompletionsQuery(baseOptions: Apollo.QueryHookOptions<GetLiabilityCompletionsQuery, GetLiabilityCompletionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLiabilityCompletionsQuery, GetLiabilityCompletionsQueryVariables>(GetLiabilityCompletionsDocument, options);
      }
export function useGetLiabilityCompletionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLiabilityCompletionsQuery, GetLiabilityCompletionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLiabilityCompletionsQuery, GetLiabilityCompletionsQueryVariables>(GetLiabilityCompletionsDocument, options);
        }
export type GetLiabilityCompletionsQueryHookResult = ReturnType<typeof useGetLiabilityCompletionsQuery>;
export type GetLiabilityCompletionsLazyQueryHookResult = ReturnType<typeof useGetLiabilityCompletionsLazyQuery>;
export type GetLiabilityCompletionsQueryResult = Apollo.QueryResult<GetLiabilityCompletionsQuery, GetLiabilityCompletionsQueryVariables>;
export const GetLiabilityTotalCompletionsDocument = gql`
    query getLiabilityTotalCompletions($pledgeId: BigInt!) {
  LiabilityTotalCompletions: getLiabilityTotalCompletion(pledgeId: $pledgeId) {
    edges {
      node {
        pledgeId
        currencyCode
        currencySortOrder
        liabilityAmount
        liabilityAmountNormalized
        completionAmount
        completionAmountNormalized
        completionPercentage
      }
    }
  }
}
    `;

/**
 * __useGetLiabilityTotalCompletionsQuery__
 *
 * To run a query within a React component, call `useGetLiabilityTotalCompletionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLiabilityTotalCompletionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLiabilityTotalCompletionsQuery({
 *   variables: {
 *      pledgeId: // value for 'pledgeId'
 *   },
 * });
 */
export function useGetLiabilityTotalCompletionsQuery(baseOptions: Apollo.QueryHookOptions<GetLiabilityTotalCompletionsQuery, GetLiabilityTotalCompletionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLiabilityTotalCompletionsQuery, GetLiabilityTotalCompletionsQueryVariables>(GetLiabilityTotalCompletionsDocument, options);
      }
export function useGetLiabilityTotalCompletionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLiabilityTotalCompletionsQuery, GetLiabilityTotalCompletionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLiabilityTotalCompletionsQuery, GetLiabilityTotalCompletionsQueryVariables>(GetLiabilityTotalCompletionsDocument, options);
        }
export type GetLiabilityTotalCompletionsQueryHookResult = ReturnType<typeof useGetLiabilityTotalCompletionsQuery>;
export type GetLiabilityTotalCompletionsLazyQueryHookResult = ReturnType<typeof useGetLiabilityTotalCompletionsLazyQuery>;
export type GetLiabilityTotalCompletionsQueryResult = Apollo.QueryResult<GetLiabilityTotalCompletionsQuery, GetLiabilityTotalCompletionsQueryVariables>;
export const GetPledgeByPersonOrTemporaryPersonDocument = gql`
    query getPledgeByPersonOrTemporaryPerson($pledgeId: BigInt!, $sessionToken: UUID) {
  Pledge: getPledgeByPersonOrTemporaryPerson(
    pledgeId: $pledgeId
    sessionToken: $sessionToken
  ) {
    id
    personId
    period {
      start {
        value
        inclusive
      }
      end {
        value
        inclusive
      }
    }
    incomePercentage
    wealthPercentage
    pledgeType
    motivation
    locale
    metadata
    emailVerifiedAt
    email
  }
}
    `;

/**
 * __useGetPledgeByPersonOrTemporaryPersonQuery__
 *
 * To run a query within a React component, call `useGetPledgeByPersonOrTemporaryPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPledgeByPersonOrTemporaryPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPledgeByPersonOrTemporaryPersonQuery({
 *   variables: {
 *      pledgeId: // value for 'pledgeId'
 *      sessionToken: // value for 'sessionToken'
 *   },
 * });
 */
export function useGetPledgeByPersonOrTemporaryPersonQuery(baseOptions: Apollo.QueryHookOptions<GetPledgeByPersonOrTemporaryPersonQuery, GetPledgeByPersonOrTemporaryPersonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPledgeByPersonOrTemporaryPersonQuery, GetPledgeByPersonOrTemporaryPersonQueryVariables>(GetPledgeByPersonOrTemporaryPersonDocument, options);
      }
export function useGetPledgeByPersonOrTemporaryPersonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPledgeByPersonOrTemporaryPersonQuery, GetPledgeByPersonOrTemporaryPersonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPledgeByPersonOrTemporaryPersonQuery, GetPledgeByPersonOrTemporaryPersonQueryVariables>(GetPledgeByPersonOrTemporaryPersonDocument, options);
        }
export type GetPledgeByPersonOrTemporaryPersonQueryHookResult = ReturnType<typeof useGetPledgeByPersonOrTemporaryPersonQuery>;
export type GetPledgeByPersonOrTemporaryPersonLazyQueryHookResult = ReturnType<typeof useGetPledgeByPersonOrTemporaryPersonLazyQuery>;
export type GetPledgeByPersonOrTemporaryPersonQueryResult = Apollo.QueryResult<GetPledgeByPersonOrTemporaryPersonQuery, GetPledgeByPersonOrTemporaryPersonQueryVariables>;
export const GetPledgeClubMembersByPersonIdDocument = gql`
    query getPledgeClubMembersByPersonId($personId: BigInt!) {
  PledgeClubMembers: allPledgeClubMembers(condition: {personId: $personId}) {
    edges {
      node {
        pledgeClubSlug
        personId
      }
    }
  }
}
    `;

/**
 * __useGetPledgeClubMembersByPersonIdQuery__
 *
 * To run a query within a React component, call `useGetPledgeClubMembersByPersonIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPledgeClubMembersByPersonIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPledgeClubMembersByPersonIdQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetPledgeClubMembersByPersonIdQuery(baseOptions: Apollo.QueryHookOptions<GetPledgeClubMembersByPersonIdQuery, GetPledgeClubMembersByPersonIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPledgeClubMembersByPersonIdQuery, GetPledgeClubMembersByPersonIdQueryVariables>(GetPledgeClubMembersByPersonIdDocument, options);
      }
export function useGetPledgeClubMembersByPersonIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPledgeClubMembersByPersonIdQuery, GetPledgeClubMembersByPersonIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPledgeClubMembersByPersonIdQuery, GetPledgeClubMembersByPersonIdQueryVariables>(GetPledgeClubMembersByPersonIdDocument, options);
        }
export type GetPledgeClubMembersByPersonIdQueryHookResult = ReturnType<typeof useGetPledgeClubMembersByPersonIdQuery>;
export type GetPledgeClubMembersByPersonIdLazyQueryHookResult = ReturnType<typeof useGetPledgeClubMembersByPersonIdLazyQuery>;
export type GetPledgeClubMembersByPersonIdQueryResult = Apollo.QueryResult<GetPledgeClubMembersByPersonIdQuery, GetPledgeClubMembersByPersonIdQueryVariables>;
export const GetPledgeClubsDocument = gql`
    query getPledgeClubs($active: Boolean = true) {
  PledgeClubs: allPledgeClubsList(
    condition: {active: $active}
    orderBy: [SLUG_ASC]
  ) {
    id
    slug
  }
}
    `;

/**
 * __useGetPledgeClubsQuery__
 *
 * To run a query within a React component, call `useGetPledgeClubsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPledgeClubsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPledgeClubsQuery({
 *   variables: {
 *      active: // value for 'active'
 *   },
 * });
 */
export function useGetPledgeClubsQuery(baseOptions?: Apollo.QueryHookOptions<GetPledgeClubsQuery, GetPledgeClubsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPledgeClubsQuery, GetPledgeClubsQueryVariables>(GetPledgeClubsDocument, options);
      }
export function useGetPledgeClubsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPledgeClubsQuery, GetPledgeClubsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPledgeClubsQuery, GetPledgeClubsQueryVariables>(GetPledgeClubsDocument, options);
        }
export type GetPledgeClubsQueryHookResult = ReturnType<typeof useGetPledgeClubsQuery>;
export type GetPledgeClubsLazyQueryHookResult = ReturnType<typeof useGetPledgeClubsLazyQuery>;
export type GetPledgeClubsQueryResult = Apollo.QueryResult<GetPledgeClubsQuery, GetPledgeClubsQueryVariables>;
export const GetPledgeRanksByPersonIdDocument = gql`
    query getPledgeRanksByPersonId($personId: BigInt!) {
  PledgeRanks: allPledgeRanks(
    orderBy: RANK_BY_PLEDGE_TYPE_DESC
    condition: {personId: $personId}
  ) {
    edges {
      node {
        createdAt
        pledgeType
        rankByPledgeType
        commencementDate
        personByPersonId {
          id
          fullName
        }
      }
    }
  }
}
    `;

/**
 * __useGetPledgeRanksByPersonIdQuery__
 *
 * To run a query within a React component, call `useGetPledgeRanksByPersonIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPledgeRanksByPersonIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPledgeRanksByPersonIdQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetPledgeRanksByPersonIdQuery(baseOptions: Apollo.QueryHookOptions<GetPledgeRanksByPersonIdQuery, GetPledgeRanksByPersonIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPledgeRanksByPersonIdQuery, GetPledgeRanksByPersonIdQueryVariables>(GetPledgeRanksByPersonIdDocument, options);
      }
export function useGetPledgeRanksByPersonIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPledgeRanksByPersonIdQuery, GetPledgeRanksByPersonIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPledgeRanksByPersonIdQuery, GetPledgeRanksByPersonIdQueryVariables>(GetPledgeRanksByPersonIdDocument, options);
        }
export type GetPledgeRanksByPersonIdQueryHookResult = ReturnType<typeof useGetPledgeRanksByPersonIdQuery>;
export type GetPledgeRanksByPersonIdLazyQueryHookResult = ReturnType<typeof useGetPledgeRanksByPersonIdLazyQuery>;
export type GetPledgeRanksByPersonIdQueryResult = Apollo.QueryResult<GetPledgeRanksByPersonIdQuery, GetPledgeRanksByPersonIdQueryVariables>;
export const GetPledgedMemberPublicsLatestRankDocument = gql`
    query getPledgedMemberPublicsLatestRank($pledgeType: PledgeType = GIVING_WHAT_WE_CAN) {
  PledgedMembers: allPledgedMembersPublicsList(
    orderBy: RANK_BY_PLEDGE_TYPE_DESC
    first: 1
    condition: {pledgeType: $pledgeType}
  ) {
    rankByPledgeType
  }
}
    `;

/**
 * __useGetPledgedMemberPublicsLatestRankQuery__
 *
 * To run a query within a React component, call `useGetPledgedMemberPublicsLatestRankQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPledgedMemberPublicsLatestRankQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPledgedMemberPublicsLatestRankQuery({
 *   variables: {
 *      pledgeType: // value for 'pledgeType'
 *   },
 * });
 */
export function useGetPledgedMemberPublicsLatestRankQuery(baseOptions?: Apollo.QueryHookOptions<GetPledgedMemberPublicsLatestRankQuery, GetPledgedMemberPublicsLatestRankQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPledgedMemberPublicsLatestRankQuery, GetPledgedMemberPublicsLatestRankQueryVariables>(GetPledgedMemberPublicsLatestRankDocument, options);
      }
export function useGetPledgedMemberPublicsLatestRankLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPledgedMemberPublicsLatestRankQuery, GetPledgedMemberPublicsLatestRankQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPledgedMemberPublicsLatestRankQuery, GetPledgedMemberPublicsLatestRankQueryVariables>(GetPledgedMemberPublicsLatestRankDocument, options);
        }
export type GetPledgedMemberPublicsLatestRankQueryHookResult = ReturnType<typeof useGetPledgedMemberPublicsLatestRankQuery>;
export type GetPledgedMemberPublicsLatestRankLazyQueryHookResult = ReturnType<typeof useGetPledgedMemberPublicsLatestRankLazyQuery>;
export type GetPledgedMemberPublicsLatestRankQueryResult = Apollo.QueryResult<GetPledgedMemberPublicsLatestRankQuery, GetPledgedMemberPublicsLatestRankQueryVariables>;
export const GetPledgesByApprovedMotivationDocument = gql`
    query getPledgesByApprovedMotivation {
  Pledges: getPledgesByApprovedMotivation(resultLimit: 10) {
    edges {
      node {
        motivation
        countryOfResidence
        createdAt
        personFullName
      }
    }
  }
}
    `;

/**
 * __useGetPledgesByApprovedMotivationQuery__
 *
 * To run a query within a React component, call `useGetPledgesByApprovedMotivationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPledgesByApprovedMotivationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPledgesByApprovedMotivationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPledgesByApprovedMotivationQuery(baseOptions?: Apollo.QueryHookOptions<GetPledgesByApprovedMotivationQuery, GetPledgesByApprovedMotivationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPledgesByApprovedMotivationQuery, GetPledgesByApprovedMotivationQueryVariables>(GetPledgesByApprovedMotivationDocument, options);
      }
export function useGetPledgesByApprovedMotivationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPledgesByApprovedMotivationQuery, GetPledgesByApprovedMotivationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPledgesByApprovedMotivationQuery, GetPledgesByApprovedMotivationQueryVariables>(GetPledgesByApprovedMotivationDocument, options);
        }
export type GetPledgesByApprovedMotivationQueryHookResult = ReturnType<typeof useGetPledgesByApprovedMotivationQuery>;
export type GetPledgesByApprovedMotivationLazyQueryHookResult = ReturnType<typeof useGetPledgesByApprovedMotivationLazyQuery>;
export type GetPledgesByApprovedMotivationQueryResult = Apollo.QueryResult<GetPledgesByApprovedMotivationQuery, GetPledgesByApprovedMotivationQueryVariables>;
export const GetPledgesByPersonIdDocument = gql`
    query getPledgesByPersonId($personId: BigInt!) {
  Pledges: getPledgesByPersonId(personId: $personId) {
    edges {
      node {
        id
        personId
        period {
          start {
            value
            inclusive
          }
          end {
            value
            inclusive
          }
        }
        incomePercentage
        wealthPercentage
        pledgeType
        motivation
        liabilities: liabilitiesByPledgeId {
          edges {
            node {
              nodeId
              pledgeId
              financialPeriodId
              personId
              currencyCode
              liabilityAmount
              liabilityNormalized
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetPledgesByPersonIdQuery__
 *
 * To run a query within a React component, call `useGetPledgesByPersonIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPledgesByPersonIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPledgesByPersonIdQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetPledgesByPersonIdQuery(baseOptions: Apollo.QueryHookOptions<GetPledgesByPersonIdQuery, GetPledgesByPersonIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPledgesByPersonIdQuery, GetPledgesByPersonIdQueryVariables>(GetPledgesByPersonIdDocument, options);
      }
export function useGetPledgesByPersonIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPledgesByPersonIdQuery, GetPledgesByPersonIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPledgesByPersonIdQuery, GetPledgesByPersonIdQueryVariables>(GetPledgesByPersonIdDocument, options);
        }
export type GetPledgesByPersonIdQueryHookResult = ReturnType<typeof useGetPledgesByPersonIdQuery>;
export type GetPledgesByPersonIdLazyQueryHookResult = ReturnType<typeof useGetPledgesByPersonIdLazyQuery>;
export type GetPledgesByPersonIdQueryResult = Apollo.QueryResult<GetPledgesByPersonIdQuery, GetPledgesByPersonIdQueryVariables>;
export const GetRecurringReportedDonationsByPersonIdDocument = gql`
    query getRecurringReportedDonationsByPersonId($personId: BigInt!) {
  RecurringReportedDonations: getRecurringReportedDonationsByPersonId(
    personId: $personId
  ) {
    edges {
      node {
        id
        personId
        organizationId
        amount
        currencyCode
        startDate
        nextDate
        status
        createdAt
        updatedAt
        externalOrganization: externalOrganizationByOrganizationId {
          id
          name
          organizationSlug
          url
        }
        recurrenceInterval {
          years
          months
          days
          hours
          minutes
          seconds
        }
      }
    }
  }
}
    `;

/**
 * __useGetRecurringReportedDonationsByPersonIdQuery__
 *
 * To run a query within a React component, call `useGetRecurringReportedDonationsByPersonIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecurringReportedDonationsByPersonIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecurringReportedDonationsByPersonIdQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetRecurringReportedDonationsByPersonIdQuery(baseOptions: Apollo.QueryHookOptions<GetRecurringReportedDonationsByPersonIdQuery, GetRecurringReportedDonationsByPersonIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecurringReportedDonationsByPersonIdQuery, GetRecurringReportedDonationsByPersonIdQueryVariables>(GetRecurringReportedDonationsByPersonIdDocument, options);
      }
export function useGetRecurringReportedDonationsByPersonIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecurringReportedDonationsByPersonIdQuery, GetRecurringReportedDonationsByPersonIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecurringReportedDonationsByPersonIdQuery, GetRecurringReportedDonationsByPersonIdQueryVariables>(GetRecurringReportedDonationsByPersonIdDocument, options);
        }
export type GetRecurringReportedDonationsByPersonIdQueryHookResult = ReturnType<typeof useGetRecurringReportedDonationsByPersonIdQuery>;
export type GetRecurringReportedDonationsByPersonIdLazyQueryHookResult = ReturnType<typeof useGetRecurringReportedDonationsByPersonIdLazyQuery>;
export type GetRecurringReportedDonationsByPersonIdQueryResult = Apollo.QueryResult<GetRecurringReportedDonationsByPersonIdQuery, GetRecurringReportedDonationsByPersonIdQueryVariables>;
export const CreatePollOptionVoteByPersonOrTemporaryPersonDocument = gql`
    mutation createPollOptionVoteByPersonOrTemporaryPerson($pledgeId: BigInt!, $pollOptionId: BigInt!, $context: String!, $sessionToken: UUID) {
  createPollOptionVoteByPersonOrTemporaryPerson(
    input: {sessionToken: $sessionToken, pledgeId: $pledgeId, pollOptionId: $pollOptionId, context: $context}
  ) {
    pollOptionVote {
      pledgeId
      pollOptionId
      context
    }
  }
}
    `;
export type CreatePollOptionVoteByPersonOrTemporaryPersonMutationFn = Apollo.MutationFunction<CreatePollOptionVoteByPersonOrTemporaryPersonMutation, CreatePollOptionVoteByPersonOrTemporaryPersonMutationVariables>;

/**
 * __useCreatePollOptionVoteByPersonOrTemporaryPersonMutation__
 *
 * To run a mutation, you first call `useCreatePollOptionVoteByPersonOrTemporaryPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePollOptionVoteByPersonOrTemporaryPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPollOptionVoteByPersonOrTemporaryPersonMutation, { data, loading, error }] = useCreatePollOptionVoteByPersonOrTemporaryPersonMutation({
 *   variables: {
 *      pledgeId: // value for 'pledgeId'
 *      pollOptionId: // value for 'pollOptionId'
 *      context: // value for 'context'
 *      sessionToken: // value for 'sessionToken'
 *   },
 * });
 */
export function useCreatePollOptionVoteByPersonOrTemporaryPersonMutation(baseOptions?: Apollo.MutationHookOptions<CreatePollOptionVoteByPersonOrTemporaryPersonMutation, CreatePollOptionVoteByPersonOrTemporaryPersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePollOptionVoteByPersonOrTemporaryPersonMutation, CreatePollOptionVoteByPersonOrTemporaryPersonMutationVariables>(CreatePollOptionVoteByPersonOrTemporaryPersonDocument, options);
      }
export type CreatePollOptionVoteByPersonOrTemporaryPersonMutationHookResult = ReturnType<typeof useCreatePollOptionVoteByPersonOrTemporaryPersonMutation>;
export type CreatePollOptionVoteByPersonOrTemporaryPersonMutationResult = Apollo.MutationResult<CreatePollOptionVoteByPersonOrTemporaryPersonMutation>;
export type CreatePollOptionVoteByPersonOrTemporaryPersonMutationOptions = Apollo.BaseMutationOptions<CreatePollOptionVoteByPersonOrTemporaryPersonMutation, CreatePollOptionVoteByPersonOrTemporaryPersonMutationVariables>;
export const RecordSurveyResponsesDocument = gql`
    mutation recordSurveyResponses($personId: BigInt!, $questionKey: [String]!, $answerText: [String]!, $itemId: BigInt!, $metadata: JSON) {
  recordSurveyResponses(
    input: {personId: $personId, questionKey: $questionKey, answerText: $answerText, itemId: $itemId, metadata: $metadata}
  ) {
    clientMutationId
  }
}
    `;
export type RecordSurveyResponsesMutationFn = Apollo.MutationFunction<RecordSurveyResponsesMutation, RecordSurveyResponsesMutationVariables>;

/**
 * __useRecordSurveyResponsesMutation__
 *
 * To run a mutation, you first call `useRecordSurveyResponsesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecordSurveyResponsesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recordSurveyResponsesMutation, { data, loading, error }] = useRecordSurveyResponsesMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      questionKey: // value for 'questionKey'
 *      answerText: // value for 'answerText'
 *      itemId: // value for 'itemId'
 *      metadata: // value for 'metadata'
 *   },
 * });
 */
export function useRecordSurveyResponsesMutation(baseOptions?: Apollo.MutationHookOptions<RecordSurveyResponsesMutation, RecordSurveyResponsesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecordSurveyResponsesMutation, RecordSurveyResponsesMutationVariables>(RecordSurveyResponsesDocument, options);
      }
export type RecordSurveyResponsesMutationHookResult = ReturnType<typeof useRecordSurveyResponsesMutation>;
export type RecordSurveyResponsesMutationResult = Apollo.MutationResult<RecordSurveyResponsesMutation>;
export type RecordSurveyResponsesMutationOptions = Apollo.BaseMutationOptions<RecordSurveyResponsesMutation, RecordSurveyResponsesMutationVariables>;
export const RecordSurveyResponsesByTemporaryPersonDocument = gql`
    mutation recordSurveyResponsesByTemporaryPerson($sessionToken: UUID!, $questionKey: [String]!, $answerText: [String]!, $itemId: BigInt!, $metadata: JSON) {
  recordSurveyResponsesByTemporaryPerson(
    input: {sessionToken: $sessionToken, questionKey: $questionKey, answerText: $answerText, itemId: $itemId, metadata: $metadata}
  ) {
    clientMutationId
  }
}
    `;
export type RecordSurveyResponsesByTemporaryPersonMutationFn = Apollo.MutationFunction<RecordSurveyResponsesByTemporaryPersonMutation, RecordSurveyResponsesByTemporaryPersonMutationVariables>;

/**
 * __useRecordSurveyResponsesByTemporaryPersonMutation__
 *
 * To run a mutation, you first call `useRecordSurveyResponsesByTemporaryPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecordSurveyResponsesByTemporaryPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recordSurveyResponsesByTemporaryPersonMutation, { data, loading, error }] = useRecordSurveyResponsesByTemporaryPersonMutation({
 *   variables: {
 *      sessionToken: // value for 'sessionToken'
 *      questionKey: // value for 'questionKey'
 *      answerText: // value for 'answerText'
 *      itemId: // value for 'itemId'
 *      metadata: // value for 'metadata'
 *   },
 * });
 */
export function useRecordSurveyResponsesByTemporaryPersonMutation(baseOptions?: Apollo.MutationHookOptions<RecordSurveyResponsesByTemporaryPersonMutation, RecordSurveyResponsesByTemporaryPersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecordSurveyResponsesByTemporaryPersonMutation, RecordSurveyResponsesByTemporaryPersonMutationVariables>(RecordSurveyResponsesByTemporaryPersonDocument, options);
      }
export type RecordSurveyResponsesByTemporaryPersonMutationHookResult = ReturnType<typeof useRecordSurveyResponsesByTemporaryPersonMutation>;
export type RecordSurveyResponsesByTemporaryPersonMutationResult = Apollo.MutationResult<RecordSurveyResponsesByTemporaryPersonMutation>;
export type RecordSurveyResponsesByTemporaryPersonMutationOptions = Apollo.BaseMutationOptions<RecordSurveyResponsesByTemporaryPersonMutation, RecordSurveyResponsesByTemporaryPersonMutationVariables>;
export const GetPollByPersonOrTemporaryPersonDocument = gql`
    query getPollByPersonOrTemporaryPerson($pollId: BigInt!, $sessionToken: UUID) {
  Poll: pollPublicById(id: $pollId) {
    id
    voteMechanism
    questionOrdering
    question
    Options: pollOptionPublicsByPollIdList {
      id
      pollId
      label
      voteCount
      Votes: votesByPersonOrTemporaryPersonList(sessionToken: $sessionToken) {
        pledgeId
        pollOptionId
        context
      }
    }
  }
}
    `;

/**
 * __useGetPollByPersonOrTemporaryPersonQuery__
 *
 * To run a query within a React component, call `useGetPollByPersonOrTemporaryPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPollByPersonOrTemporaryPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPollByPersonOrTemporaryPersonQuery({
 *   variables: {
 *      pollId: // value for 'pollId'
 *      sessionToken: // value for 'sessionToken'
 *   },
 * });
 */
export function useGetPollByPersonOrTemporaryPersonQuery(baseOptions: Apollo.QueryHookOptions<GetPollByPersonOrTemporaryPersonQuery, GetPollByPersonOrTemporaryPersonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPollByPersonOrTemporaryPersonQuery, GetPollByPersonOrTemporaryPersonQueryVariables>(GetPollByPersonOrTemporaryPersonDocument, options);
      }
export function useGetPollByPersonOrTemporaryPersonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPollByPersonOrTemporaryPersonQuery, GetPollByPersonOrTemporaryPersonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPollByPersonOrTemporaryPersonQuery, GetPollByPersonOrTemporaryPersonQueryVariables>(GetPollByPersonOrTemporaryPersonDocument, options);
        }
export type GetPollByPersonOrTemporaryPersonQueryHookResult = ReturnType<typeof useGetPollByPersonOrTemporaryPersonQuery>;
export type GetPollByPersonOrTemporaryPersonLazyQueryHookResult = ReturnType<typeof useGetPollByPersonOrTemporaryPersonLazyQuery>;
export type GetPollByPersonOrTemporaryPersonQueryResult = Apollo.QueryResult<GetPollByPersonOrTemporaryPersonQuery, GetPollByPersonOrTemporaryPersonQueryVariables>;
export const AllCountriesDocument = gql`
    query allCountries {
  Countries: allCountries(orderBy: NAME_ASC) {
    edges {
      node {
        name
        isoAlpha2
        isoAlpha3
      }
    }
  }
}
    `;

/**
 * __useAllCountriesQuery__
 *
 * To run a query within a React component, call `useAllCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllCountriesQuery(baseOptions?: Apollo.QueryHookOptions<AllCountriesQuery, AllCountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllCountriesQuery, AllCountriesQueryVariables>(AllCountriesDocument, options);
      }
export function useAllCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllCountriesQuery, AllCountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllCountriesQuery, AllCountriesQueryVariables>(AllCountriesDocument, options);
        }
export type AllCountriesQueryHookResult = ReturnType<typeof useAllCountriesQuery>;
export type AllCountriesLazyQueryHookResult = ReturnType<typeof useAllCountriesLazyQuery>;
export type AllCountriesQueryResult = Apollo.QueryResult<AllCountriesQuery, AllCountriesQueryVariables>;
export const AllCountriesWithCurrenciesDocument = gql`
    query allCountriesWithCurrencies {
  Countries: allCountries(orderBy: NAME_ASC) {
    edges {
      node {
        name
        isoAlpha2
        isoAlpha3
        currencies: currencyByCountriesByCountryCodeList(orderBy: PRECEDENCE_ASC) {
          currencyCode
        }
      }
    }
  }
}
    `;

/**
 * __useAllCountriesWithCurrenciesQuery__
 *
 * To run a query within a React component, call `useAllCountriesWithCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCountriesWithCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCountriesWithCurrenciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllCountriesWithCurrenciesQuery(baseOptions?: Apollo.QueryHookOptions<AllCountriesWithCurrenciesQuery, AllCountriesWithCurrenciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllCountriesWithCurrenciesQuery, AllCountriesWithCurrenciesQueryVariables>(AllCountriesWithCurrenciesDocument, options);
      }
export function useAllCountriesWithCurrenciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllCountriesWithCurrenciesQuery, AllCountriesWithCurrenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllCountriesWithCurrenciesQuery, AllCountriesWithCurrenciesQueryVariables>(AllCountriesWithCurrenciesDocument, options);
        }
export type AllCountriesWithCurrenciesQueryHookResult = ReturnType<typeof useAllCountriesWithCurrenciesQuery>;
export type AllCountriesWithCurrenciesLazyQueryHookResult = ReturnType<typeof useAllCountriesWithCurrenciesLazyQuery>;
export type AllCountriesWithCurrenciesQueryResult = Apollo.QueryResult<AllCountriesWithCurrenciesQuery, AllCountriesWithCurrenciesQueryVariables>;
export const AllCurrenciesDocument = gql`
    query allCurrencies($currencyType: CurrencyType) {
  Currencies: allCurrencies(condition: {currencyType: $currencyType}) {
    edges {
      node {
        name
        code
        decimals
        symbol
        currencyType
      }
    }
  }
}
    `;

/**
 * __useAllCurrenciesQuery__
 *
 * To run a query within a React component, call `useAllCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCurrenciesQuery({
 *   variables: {
 *      currencyType: // value for 'currencyType'
 *   },
 * });
 */
export function useAllCurrenciesQuery(baseOptions?: Apollo.QueryHookOptions<AllCurrenciesQuery, AllCurrenciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllCurrenciesQuery, AllCurrenciesQueryVariables>(AllCurrenciesDocument, options);
      }
export function useAllCurrenciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllCurrenciesQuery, AllCurrenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllCurrenciesQuery, AllCurrenciesQueryVariables>(AllCurrenciesDocument, options);
        }
export type AllCurrenciesQueryHookResult = ReturnType<typeof useAllCurrenciesQuery>;
export type AllCurrenciesLazyQueryHookResult = ReturnType<typeof useAllCurrenciesLazyQuery>;
export type AllCurrenciesQueryResult = Apollo.QueryResult<AllCurrenciesQuery, AllCurrenciesQueryVariables>;
export const AllFiatCurrenciesDocument = gql`
    query allFiatCurrencies {
  FiatCurrencies: allCurrencies(
    condition: {currencyType: FIAT}
    orderBy: CODE_ASC
  ) {
    edges {
      node {
        name
        code
        symbol
      }
    }
  }
}
    `;

/**
 * __useAllFiatCurrenciesQuery__
 *
 * To run a query within a React component, call `useAllFiatCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllFiatCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllFiatCurrenciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllFiatCurrenciesQuery(baseOptions?: Apollo.QueryHookOptions<AllFiatCurrenciesQuery, AllFiatCurrenciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllFiatCurrenciesQuery, AllFiatCurrenciesQueryVariables>(AllFiatCurrenciesDocument, options);
      }
export function useAllFiatCurrenciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllFiatCurrenciesQuery, AllFiatCurrenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllFiatCurrenciesQuery, AllFiatCurrenciesQueryVariables>(AllFiatCurrenciesDocument, options);
        }
export type AllFiatCurrenciesQueryHookResult = ReturnType<typeof useAllFiatCurrenciesQuery>;
export type AllFiatCurrenciesLazyQueryHookResult = ReturnType<typeof useAllFiatCurrenciesLazyQuery>;
export type AllFiatCurrenciesQueryResult = Apollo.QueryResult<AllFiatCurrenciesQuery, AllFiatCurrenciesQueryVariables>;
export const AllMembersDocument = gql`
    query allMembers {
  Pledges: allPledgedMembersPublicsList(orderBy: RANK_BY_PLEDGE_TYPE_DESC) {
    pledgeType
    active
    rankByPledgeType
    fullName
    commencementDate
  }
}
    `;

/**
 * __useAllMembersQuery__
 *
 * To run a query within a React component, call `useAllMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMembersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllMembersQuery(baseOptions?: Apollo.QueryHookOptions<AllMembersQuery, AllMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllMembersQuery, AllMembersQueryVariables>(AllMembersDocument, options);
      }
export function useAllMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllMembersQuery, AllMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllMembersQuery, AllMembersQueryVariables>(AllMembersDocument, options);
        }
export type AllMembersQueryHookResult = ReturnType<typeof useAllMembersQuery>;
export type AllMembersLazyQueryHookResult = ReturnType<typeof useAllMembersLazyQuery>;
export type AllMembersQueryResult = Apollo.QueryResult<AllMembersQuery, AllMembersQueryVariables>;
export const ClosestExchangeRateDocument = gql`
    query closestExchangeRate($currencyCode: String!, $date: Date!) {
  ExchangeRate: closestExchangeRate(currencyCode: $currencyCode, date: $date) {
    currencyCode
    date
    rate
  }
}
    `;

/**
 * __useClosestExchangeRateQuery__
 *
 * To run a query within a React component, call `useClosestExchangeRateQuery` and pass it any options that fit your needs.
 * When your component renders, `useClosestExchangeRateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClosestExchangeRateQuery({
 *   variables: {
 *      currencyCode: // value for 'currencyCode'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useClosestExchangeRateQuery(baseOptions: Apollo.QueryHookOptions<ClosestExchangeRateQuery, ClosestExchangeRateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClosestExchangeRateQuery, ClosestExchangeRateQueryVariables>(ClosestExchangeRateDocument, options);
      }
export function useClosestExchangeRateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClosestExchangeRateQuery, ClosestExchangeRateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClosestExchangeRateQuery, ClosestExchangeRateQueryVariables>(ClosestExchangeRateDocument, options);
        }
export type ClosestExchangeRateQueryHookResult = ReturnType<typeof useClosestExchangeRateQuery>;
export type ClosestExchangeRateLazyQueryHookResult = ReturnType<typeof useClosestExchangeRateLazyQuery>;
export type ClosestExchangeRateQueryResult = Apollo.QueryResult<ClosestExchangeRateQuery, ClosestExchangeRateQueryVariables>;
export const GetCurrencyDocument = gql`
    query getCurrency($code: String!) {
  Currency: currencyByCode(code: $code) {
    name
    code
    decimals
    symbol
    currencyType
  }
}
    `;

/**
 * __useGetCurrencyQuery__
 *
 * To run a query within a React component, call `useGetCurrencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrencyQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetCurrencyQuery(baseOptions: Apollo.QueryHookOptions<GetCurrencyQuery, GetCurrencyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrencyQuery, GetCurrencyQueryVariables>(GetCurrencyDocument, options);
      }
export function useGetCurrencyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrencyQuery, GetCurrencyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrencyQuery, GetCurrencyQueryVariables>(GetCurrencyDocument, options);
        }
export type GetCurrencyQueryHookResult = ReturnType<typeof useGetCurrencyQuery>;
export type GetCurrencyLazyQueryHookResult = ReturnType<typeof useGetCurrencyLazyQuery>;
export type GetCurrencyQueryResult = Apollo.QueryResult<GetCurrencyQuery, GetCurrencyQueryVariables>;
export const GetStatsDocument = gql`
    query getStats {
  allGlobalStatsList {
    gwwcPledgesTotal
    activeTryGivingPledgesTotal
    tryGivingCompletedTotal
    pledgersCountriesTotal
    pledgesDonationsTotal
    gwwcDonationsTotal
  }
}
    `;

/**
 * __useGetStatsQuery__
 *
 * To run a query within a React component, call `useGetStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStatsQuery(baseOptions?: Apollo.QueryHookOptions<GetStatsQuery, GetStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStatsQuery, GetStatsQueryVariables>(GetStatsDocument, options);
      }
export function useGetStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStatsQuery, GetStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStatsQuery, GetStatsQueryVariables>(GetStatsDocument, options);
        }
export type GetStatsQueryHookResult = ReturnType<typeof useGetStatsQuery>;
export type GetStatsLazyQueryHookResult = ReturnType<typeof useGetStatsLazyQuery>;
export type GetStatsQueryResult = Apollo.QueryResult<GetStatsQuery, GetStatsQueryVariables>;
export const UpdateSettingsContactPreferenceDocument = gql`
    mutation updateSettingsContactPreference($personId: BigInt!, $currentAddressId: BigInt) {
  updateContactPreferenceByPersonId(
    input: {personId: $personId, currentAddressId: $currentAddressId}
  ) {
    contactPreference {
      personId
      currentAddressId
    }
  }
}
    `;
export type UpdateSettingsContactPreferenceMutationFn = Apollo.MutationFunction<UpdateSettingsContactPreferenceMutation, UpdateSettingsContactPreferenceMutationVariables>;

/**
 * __useUpdateSettingsContactPreferenceMutation__
 *
 * To run a mutation, you first call `useUpdateSettingsContactPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSettingsContactPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSettingsContactPreferenceMutation, { data, loading, error }] = useUpdateSettingsContactPreferenceMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      currentAddressId: // value for 'currentAddressId'
 *   },
 * });
 */
export function useUpdateSettingsContactPreferenceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSettingsContactPreferenceMutation, UpdateSettingsContactPreferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSettingsContactPreferenceMutation, UpdateSettingsContactPreferenceMutationVariables>(UpdateSettingsContactPreferenceDocument, options);
      }
export type UpdateSettingsContactPreferenceMutationHookResult = ReturnType<typeof useUpdateSettingsContactPreferenceMutation>;
export type UpdateSettingsContactPreferenceMutationResult = Apollo.MutationResult<UpdateSettingsContactPreferenceMutation>;
export type UpdateSettingsContactPreferenceMutationOptions = Apollo.BaseMutationOptions<UpdateSettingsContactPreferenceMutation, UpdateSettingsContactPreferenceMutationVariables>;
export const GetSettingsDocument = gql`
    query getSettings($personId: BigInt!) {
  ContactPreference: contactPreferenceByPersonId(personId: $personId) {
    currentAddressId
  }
}
    `;

/**
 * __useGetSettingsQuery__
 *
 * To run a query within a React component, call `useGetSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSettingsQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetSettingsQuery(baseOptions: Apollo.QueryHookOptions<GetSettingsQuery, GetSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSettingsQuery, GetSettingsQueryVariables>(GetSettingsDocument, options);
      }
export function useGetSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSettingsQuery, GetSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSettingsQuery, GetSettingsQueryVariables>(GetSettingsDocument, options);
        }
export type GetSettingsQueryHookResult = ReturnType<typeof useGetSettingsQuery>;
export type GetSettingsLazyQueryHookResult = ReturnType<typeof useGetSettingsLazyQuery>;
export type GetSettingsQueryResult = Apollo.QueryResult<GetSettingsQuery, GetSettingsQueryVariables>;