import { Draft, produce } from 'immer'
import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from 'react'

import {
  TPerson,
  useGetPersonLazyQuery,
  useSyncAuth0UserMutation,
} from '@aletheia/graphql'

import { ErrorDialog, ErrorDialogProps } from '../Auth/ErrorDialog'
import { setAccessToken } from '../../lib/apollo'

export type TAuth0User = {
  email: string
  sub: string
}

const PersonContext = createContext<PersonProviderValue>({
  Person: undefined,
  refreshPerson: async () => undefined,
  setPicture: () => {},
  loading: false,
  picture: undefined,
})
PersonContext.displayName = 'PersonContext'
const { Provider } = PersonContext

export type PersonProviderProps = {
  readonly Person: TPerson | undefined
  readonly loading: boolean
  readonly picture: string | null | undefined
}

export type PersonProviderMethods = {
  refreshPerson: () => Promise<TPerson | undefined | null>
  setPicture: (picture: string) => void
}

export type PersonProviderValue = PersonProviderProps & PersonProviderMethods

export type PersonProviderState = PersonProviderProps & {
  readonly error?: Error
}

export const initialState: PersonProviderState = {
  Person: undefined,
  loading: true,
  picture: undefined,
}

/** Update the `Person` property of an `PersonProviderState` object */
export function setPerson(
  state: PersonProviderState,
  Person: TPerson,
): PersonProviderState {
  return produce(state, (draft: Draft<PersonProviderState>) => {
    draft.Person = Person
  })
}

/** Update the `Loading` property of an `PersonProviderState` object */
export function setLoading(
  state: PersonProviderState,
  loading: boolean,
): PersonProviderState {
  return produce(state, (draft: Draft<PersonProviderState>) => {
    draft.loading = loading
  })
}

/** Update the `Person` property of an `PersonProviderState` object */
export function setError(
  state: PersonProviderState,
  error: Error | undefined,
): PersonProviderState {
  return produce(state, (draft: Draft<PersonProviderState>) => {
    draft.error = error
  })
}

/**
 * Provides the current logged-in user's Person object for use in descendant components.
 * Contains methods for getting the Person from the database, and for
 * refreshing the data if it changes.
 */
export const PersonProvider: React.FC<{
  errorDialog?: React.FC<ErrorDialogProps>
}> = ({ children, errorDialog = ErrorDialog }) => {
  const [state, setState] = useState<PersonProviderState>(initialState)

  /**
   * This query is for performing the initial sync of the Person object.
   * Typically it'll be the only query that gets the Person
   */
  const [syncAuth0UserMutation] = useSyncAuth0UserMutation()
  /**
   * This query is for refetching the person after initial sync (e.g. if the
   * Person's details are updated). The fetchPolicy is network-only, meaning it
   * will bypass the client Apollo cache and always get a fresh version of the
   * person from the server
   */
  const [getPersonQuery, { data: PersonQueryData }] = useGetPersonLazyQuery({
    fetchPolicy: 'network-only',
  })

  /** Handler to set loading state */
  const handleSetLoading = (loading: boolean) => {
    setState((state) => setLoading(state, loading))
  }

  /**
   * Sync the Auth0 user with the Parfit API. This will create a new Person
   * object if the email doesn't exist yet, or will attach the Auth0 user ID to
   * an existing Person with a matching email.
   */
  const syncUser = useCallback(
    async (auth0User: TAuth0User) => {
      handleSetLoading(true)
      try {
        const res = await syncAuth0UserMutation({ variables: { auth0User } })
        const Person: TPerson | undefined | null =
          res.data?.syncAuth0User?.Person
        if (Person) {
          setState((state) => setPerson(state, Person))
        }
      } catch (err: any) {
        setState((state) => setError(state, err))
      } finally {
        handleSetLoading(false)
      }
    },
    [syncAuth0UserMutation],
  )

  const setPicture = useCallback(
    (picture: string) => {
      setState((state) => ({ ...state, picture }))
    },
    [setState],
  )

  /** Refresh the Person by getting current data from the server */
  const refreshPerson = useCallback(async () => {
    const person = await getPersonQuery()
    return person.data?.Person
  }, [getPersonQuery])

  /** Identify the person after login, assuming there's no personId on props */
  useEffect(() => {
    if (!state.Person?.id || !window.analytics) return
    window.analytics.identify(state.Person.id, {
      name: state.Person.fullName,
      email: state.Person.email,
    })
  }, [state.Person?.email, state.Person?.fullName, state.Person?.id])

  /** If we've run getPersonLazyQuery, update Person */
  useEffect(() => {
    const NewPerson = PersonQueryData?.Person
    if (NewPerson && NewPerson !== state.Person) {
      setState((state) => setPerson(state, NewPerson))
    }
  }, [PersonQueryData?.Person, state.Person])

  /** Check if we're logged in with auth0. If so get the token, the picture, and get the person from parfit */
  useEffect(() => {
    const fetchSession = async () => {
      const res = await fetch('/api/auth/session')
      if (res.status == 200) {
        const session = await res.json()
        if (session.accessToken) setAccessToken(session.accessToken)
        if (session.user?.picture) setPicture(session.user?.picture)
        await syncUser(session.user)
      }
      handleSetLoading(false)
    }
    fetchSession()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /** Log errors out when they get set */
  useEffect(() => {
    if (state.error) {
      console.error(state.error.message)
    }
  }, [state.error])

  const { error, ...rest } = state
  const value = {
    ...rest,
    refreshPerson,
    setPicture,
  }
  const Dialog = errorDialog
  const onClose = () => {
    setState((state) => setError(state, undefined))
    window.location.href = `/api/auth/logout`
  }
  return (
    <Provider value={value}>
      <Dialog error={error} onClose={onClose} />

      {children}
    </Provider>
  )
}

export const usePerson = (): PersonProviderValue => useContext(PersonContext)
